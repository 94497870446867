import { motion } from "framer-motion";
import services from "../data/service-data";

function ServiceDetail({ active }: { active: number }) {
  return (
    <div className="relative overflow-hidden rounded-3xl shadow-2xl w-full">
      <motion.img
        key={active}
        src={services[active]?.image}
        alt={services[active]?.title}
        className=" h-[400px] lg:h-[600px] w-full object-cover"
        initial={{ opacity: 0, scale: 1.1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8 }}
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/50 to-transparent" />
      <div className="absolute bottom-0 left-0 p-8">
        <motion.h3
          key={`title-${active}`}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-semibold text-white mb-2"
        >
          {services[active]?.title}
        </motion.h3>
        <motion.p
          key={`desc-${active}`}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-lg lg:line-clamp-none line-clamp-2 text-gray-200 "
        >
          {services[active]?.description}
        </motion.p>
      </div>
    </div>
  );
}

export default ServiceDetail;
