import {
  Search,
  PenTool,
  LayoutGrid,
  Eye,
  Rocket,
  LifeBuoy,
} from "lucide-react";

const uiUxDesignProcessData = [
  {
    step: 1,
    title: "Research & Discovery",
    description:
      "We start by understanding your business goals, target audience, and industry trends to ensure a user-centric design approach.",
    icon: <Search />,
  },
  {
    step: 2,
    title: "Wireframing & Prototyping",
    description:
      "Our designers create wireframes and interactive prototypes to visualize the user journey, ensuring intuitive navigation and seamless interactions.",
    icon: <PenTool />,
  },
  {
    step: 3,
    title: "UI Design & Branding",
    description:
      "We craft visually appealing, brand-aligned interfaces that enhance user engagement while maintaining consistency across all touchpoints.",
    icon: <LayoutGrid />,
  },
  {
    step: 4,
    title: "User Testing & Refinements",
    description:
      "Through usability testing and feedback analysis, we refine the design to improve user experience and eliminate friction points.",
    icon: <Eye />,
  },
  {
    step: 5,
    title: "Final Implementation & Handoff",
    description:
      "Once the design is finalized, we provide developers with structured design assets, style guides, and prototypes for seamless implementation.",
    icon: <Rocket />,
  },
  {
    step: 6,
    title: "Post-Launch Support & Optimization",
    description:
      "We continuously monitor user behavior, analyze feedback, and optimize the UI/UX for better engagement and conversions.",
    icon: <LifeBuoy />,
  },
];

export default uiUxDesignProcessData;
