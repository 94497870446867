class CustomImageTool {
  wrapper: HTMLDivElement;
  data: any;
  readOnly: boolean;

  static get toolbox() {
    return {
      title: "Image",
      icon: `<svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="3" width="18" height="18" rx="2" stroke="currentColor" stroke-width="2"/>
        <circle cx="8" cy="8" r="2" fill="currentColor"/>
        <path d="M21 17L15 11L5 21" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, readOnly }: { data: any; readOnly: boolean }) {
    this.data = data || { url: "" };
    this.readOnly = readOnly;
    this.wrapper = document.createElement("div");
  }

  render() {
    this.wrapper.innerHTML = "";

    if (this.readOnly) {
      if (this.data.url) {
        const image = document.createElement("img");
        image.src = this.data.url;
        image.style.maxWidth = "100%";
        image.style.borderRadius = "8px";
        this.wrapper.appendChild(image);
      }
    } else {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.style.marginBottom = "10px";

      input.addEventListener("change", (event: any) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = () => {
            this.data.url = reader.result as string;
            this._updateView();
          };
          reader.readAsDataURL(file);
        }
      });

      this.wrapper.appendChild(input);
      if (this.data.url) {
        this._updateView();
      }
    }

    return this.wrapper;
  }

  _updateView() {
    this.wrapper.innerHTML = "";

    const image = document.createElement("img");
    image.src = this.data.url;
    image.style.maxWidth = "100%";
    image.style.borderRadius = "8px";

    this.wrapper.appendChild(image);
  }

  save() {
    return this.data;
  }
}

export default CustomImageTool;
