import { motion } from "framer-motion";
import Title from "../title";
import Grid from "../Grid/grid";

function Qualities({ careersData }: { careersData: any }) {
  return (
    <section className="w-full min-h-screen overflow-hidden relative flex flex-col px-4 sm:px-6 md:px-10 lg:px-16 py-12 lg:py-16">
      <Grid direction="top-right" size={100} />

      <div className="z-10 flex flex-col justify-center items-start mb-8 sm:mb-12">
        <Title title="Qualities We Value" />
      </div>

      <div className="w-full p-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 md:gap-12 mt-5">
        {careersData.qualities.map((data: any, index: number) => (
          <motion.div
            key={index}
            className="flex flex-col gap-4 items-center justify-center p-6 transition-all duration-300"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <span className="bg-primary/10 text-primary p-4 rounded-full flex items-center justify-center text-3xl shadow-md">
              {data.icon}
            </span>
            <h2 className="text-lg sm:text-xl font-semibold text-center text-gray-800 mt-3">
              {data.title}
            </h2>
          </motion.div>
        ))}
      </div>
    </section>
  );
}

export default Qualities;
