import React from "react";
import IconButton from "../input/icon-button";
import { Plus } from "lucide-react";

function Header({
  setView,
}: {
  setView: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div className="w-full border-b p-4 flex flex-wrap items-center justify-between gap-3 md:gap-0">
      <div className="flex flex-row gap-2 items-center justify-center">
        <h2 className="text-xl tracking-wide font-medium capitalize">
          Client Logos
        </h2>
      </div>
      <IconButton
        icon={<Plus />}
        title="Add Logos"
        onClick={() => setView(true)}
      />
    </div>
  );
}

export default Header;
