import { useParams, useNavigate } from "react-router-dom";
import MainLayout from "../layout/main-layout";
import { useEffect, useState } from "react";
import product_data from "../Components/detail-product/data/product-data";
import HeadSection from "../Components/detail-product/head-section";
import ImgSection from "../Components/detail-product/img-section";
import KeyFeatures from "../Components/detail-product/key-features";
import ProductBrief from "../Components/detail-product/product-brief";
import { ChevronLeft, ChevronRight } from "lucide-react";
import HappyClient from "../Components/common-section/happy-client";
import CTASection from "../Components/common-section/cta-section";
import Title from "../Components/title";
import Grid from "../Components/Grid/grid";
import { motion } from "framer-motion";

function DetailProduct() {
  const [product, setProduct] = useState<object | any>({});
  const [prevProduct, setPrevProduct] = useState<object | any>(null);
  const [nextProduct, setNextProduct] = useState<object | any>(null);
  const { product_name, id } = useParams<{
    product_name: string;
    id: any;
  }>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductData = () => {
      const currentIndex = product_data.findIndex(
        (item) => item.id === parseInt(id)
      );
      if (currentIndex !== -1) {
        setProduct(product_data[currentIndex]);
        setPrevProduct(product_data[currentIndex - 1] || null);
        setNextProduct(product_data[currentIndex + 1] || null);
      }
    };

    fetchProductData();
  }, [id]);

  return (
    <div>
      <MainLayout>
        <HeadSection product={product} />
        <ImgSection product={product} />
        <KeyFeatures product={product} />
        <ProductBrief product={product} />
        <HappyClient />
        <div className="relative flex flex-col h-auto p-2 lg:p-16">
          <Grid direction="top-center" size={100} />
          <div className="z-10 flex justify-start items-start mb-12 w-full">
            <Title title={"Other Products"} />
          </div>
          <div className="w-full flex flex-col sm:flex-row justify-between items-center gap-3 sm:gap-6">
            {prevProduct && (
              <motion.button
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.95 }}
                className="group flex flex-col md:flex-row w-full sm:w-auto items-center gap-3 p-3 sm:p-4 bg-white border border-gray-200 rounded-xl shadow hover:shadow-md transition duration-300 cursor-pointer"
                onClick={() =>
                  navigate(`/product/${prevProduct.title}/${prevProduct.id}`)
                }
                aria-label={`Previous Product: ${prevProduct.title}`}
              >
                {prevProduct.poster_img && (
                  <div className="relative overflow-hidden rounded-xl">
                    <motion.div className="absolute inset-0 bg-gradient-to-tr from-primary/20 to-blue-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10" />
                    <motion.img
                      src={prevProduct.poster_img || "/placeholder.svg"}
                      alt={prevProduct.title}
                      className="w-80 h-44 object-cover border border-gray-100 group-hover:scale-105 transition-transform duration-700 ease-out"
                    />
                  </div>
                )}
                <div className="text-left">
                  <h3 className="text-sm sm:text-base font-semibold text-primary group-hover:text-primary/80 transition-colors duration-300">
                    Previous: {prevProduct.title}
                  </h3>
                  <p className="text-xs sm:text-sm text-gray-600 group-hover:text-gray-800 transition-colors duration-300 line-clamp-2">
                    {prevProduct.brief}
                  </p>
                </div>
              </motion.button>
            )}

            {nextProduct && (
              <motion.button
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.95 }}
                className="group flex w-full flex-col-reverse md:flex-row sm:w-auto items-center gap-3 p-3 sm:p-4 bg-white border border-gray-200 rounded-xl shadow hover:shadow-md transition duration-300 cursor-pointer"
                onClick={() =>
                  navigate(`/product/${nextProduct.title}/${nextProduct.id}`)
                }
                aria-label={`Next Product: ${nextProduct.title}`}
              >
                <div className="text-left sm:text-right">
                  <h3 className="text-sm sm:text-base font-semibold text-primary group-hover:text-primary/80 transition-colors duration-300">
                    Next: {nextProduct.title}
                  </h3>
                  <p className="text-xs sm:text-sm text-gray-600 group-hover:text-gray-800 transition-colors duration-300 line-clamp-2">
                    {nextProduct.brief}
                  </p>
                </div>
                {nextProduct.poster_img && (
                  <div className="relative overflow-hidden rounded-xl">
                    <motion.div className="absolute inset-0 bg-gradient-to-tr from-primary/20 to-blue-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10" />
                    <motion.img
                      src={nextProduct.poster_img || "/placeholder.svg"}
                      alt={nextProduct.title}
                      className="w-80 h-44 object-cover border border-gray-100 group-hover:scale-105 transition-transform duration-700 ease-out"
                    />
                  </div>
                )}
              </motion.button>
            )}
          </div>
        </div>
        <CTASection />
      </MainLayout>
    </div>
  );
}

export default DetailProduct;
