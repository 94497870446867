import {
  CheckCircle,
  Code,
  LifeBuoy,
  Rocket,
  Shield,
  TrendingUp,
  Users,
} from "lucide-react";

const whyChooseUsForROR = [
  {
    id: 1,
    title: "Expert Developers",
    description:
      "Our Ruby on Rails developers have years of experience building custom web applications and handling projects of any complexity.",
    icon: <Code />,
  },
  {
    id: 2,
    title: "Customer-Centric Approach",
    description:
      "We focus on understanding your business goals and delivering solutions that exceed expectations.",
    icon: <Users />,
  },
  {
    id: 3,
    title: "Proven Track Record",
    description:
      "With numerous successful Ruby on Rails projects across industries, we bring expertise from startups to enterprise applications.",
    icon: <CheckCircle />,
  },
  {
    id: 4,
    title: "Commitment to Quality",
    description:
      "We take pride in delivering clean, maintainable, and well-documented code to ensure long-term success.",
    icon: <Shield />,
  },
  {
    id: 5,
    title: "Scalable & High-Performance Solutions",
    description:
      "Our Ruby on Rails applications are built for scalability, ensuring seamless performance even as your business grows.",
    icon: <TrendingUp />,
  },
  {
    id: 6,
    title: "Ongoing Support & Maintenance",
    description:
      "We provide continuous support, security updates, and performance optimizations to keep your Ruby on Rails application running smoothly.",
    icon: <LifeBuoy />,
  },
  {
    id: 7,
    title: "Rapid Development & Deployment",
    description:
      "Ruby on Rails enables fast development cycles, allowing us to build, test, and deploy your application efficiently without compromising quality.",
    icon: <Rocket />,
  },
];
export default whyChooseUsForROR;
