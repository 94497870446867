import {
  ChevronLeft,
  ChevronRight,
  Database,
  Layout,
  Pencil,
  Server,
  Trash,
} from "lucide-react";
import React from "react";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useNavigate } from "react-router-dom";

function DetailView({
  work,
  setView,
  setSelectedWork,
  onDeleteView,
}: {
  work: any;
  setView: React.Dispatch<React.SetStateAction<"list" | "detailed">>;
  setSelectedWork: React.Dispatch<React.SetStateAction<any>>;
  onDeleteView: (data: any) => void;
}) {
  const navigate = useNavigate();
  const Features = Object.entries(work.key_features).map(
    ([key, value]: [string, any]) => ({
      id: Number(key),
      ...value,
    })
  );

  const Images = [work.main_image_url, ...work.other_images_urls];

  return (
    <div className="w-full h-auto md:rounded-2xl shadow-md flex flex-col md:border overflow-y-auto">
      <div className="w-full border-b p-3 flex flex-wrap justify-between items-center gap-3">
        <div className="flex gap-2 items-center justify-center">
          <button
            onClick={() => {
              setSelectedWork({});
              setView("list");
            }}
            className="outline-none border-2 p-2 rounded-full"
          >
            <ChevronLeft />
          </button>
          <h2 className="text-base md:text-lg font-bold">{work?.title}</h2>
        </div>
        <div className="flex gap-2">
          <button
            onClick={() =>
              navigate(`/admin/our-work/update-work/${work.id}/${work.title}`)
            }
            className="border-2 border-green-200 text-green-400 p-2 rounded-xl outline-none"
          >
            <Pencil />
          </button>
          <button
            onClick={() => onDeleteView(work)}
            className="border-2 border-red-200 text-red-400 p-2 rounded-xl outline-none"
          >
            <Trash />
          </button>
        </div>
      </div>
      <div className="p-5 flex flex-col w-full gap-3">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div className="flex flex-col items-start justify-start gap-3 border p-3 rounded-2xl">
            <h2 className="font-medium text-gray-600">Description</h2>
            <p className="text-sm text-pretty leading-relaxed">
              {work?.description}
            </p>
          </div>
          <div className="flex flex-col items-start justify-start gap-3 border p-3 rounded-2xl">
            <h2 className="font-medium text-gray-600">Problem Statement</h2>
            <p className="text-sm text-pretty leading-relaxed">
              {work?.problem_statement}
            </p>
          </div>
        </div>
        <div className="w-full p-3 border rounded-2xl gap-3 flex flex-col">
          <h2 className="font-medium text-gray-600">Tech Stack</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 w-full">
            <div className="flex gap-3 items-center md:justify-center border p-2 rounded-xl">
              <Layout className="text-gray-600" />
              <h2 className="font-medium">{work?.frontend_tech}</h2>
            </div>
            <div className="flex gap-3 items-center md:justify-center border p-2 rounded-xl">
              <Server className="text-gray-600" />
              <h2 className="font-medium">{work?.backend_tech}</h2>
            </div>
            <div className="flex gap-3 items-center md:justify-center border p-2 rounded-xl">
              <Database className="text-gray-600" />
              <h2 className="font-medium">{work?.database_tech}</h2>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div className="w-full p-3 border rounded-2xl gap-3 flex flex-col">
            <h2 className="font-medium text-gray-600">Challenges</h2>
            <div className="flex flex-col gap-3">
              {work?.challenges?.map((data: string, index: number) => (
                <div
                  key={index}
                  className="flex flex-row items-center gap-3 text-sm"
                >
                  <ChevronRight className="size-4" />
                  {data}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-3 border rounded-2xl gap-3 flex flex-col">
            <h2 className="font-medium text-gray-600">Approach</h2>
            <div className="flex flex-col gap-3">
              {work?.approach?.map((data: string, index: number) => (
                <div
                  key={index}
                  className="flex flex-row items-center gap-3 text-sm"
                >
                  <ChevronRight className="size-4" />
                  {data}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-3 border rounded-2xl gap-3 flex flex-col md:col-span-2">
            <h2 className="font-medium text-gray-600">Solutions</h2>
            <div className="flex flex-col gap-3">
              {work?.solutions?.map((data: string, index: number) => (
                <div
                  key={index}
                  className="flex flex-row items-center gap-3 text-sm"
                >
                  <ChevronRight className="size-4" />
                  {data}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full p-3 border rounded-2xl gap-3 flex flex-col md:col-span-2">
            <h2 className="font-medium text-gray-600">Key Features</h2>
            <div className="flex flex-col gap-3">
              {Features?.map((data: any, index: number) => (
                <div
                  key={index}
                  className="flex flex-col items-start gap-3 text-sm"
                >
                  <div className="flex items-center gap-3">
                    <ChevronRight className="size-4" />
                    <h3 className="font-medium text-gray-600">
                      {data.feature}
                    </h3>
                  </div>
                  <p>{data.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div>
          <Swiper
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
          >
            {Images.map((data, index) => (
              <SwiperSlide key={index}>
                <img
                  className="h-[400px] md:h-[600px] object-contain"
                  src={`${data}`}
                  alt="banner"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default DetailView;
