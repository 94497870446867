import {
  Search,
  Brush,
  Code,
  Link,
  ShieldCheck,
  Rocket,
  LifeBuoy,
} from "lucide-react";

const reactDevelopmentProcess = [
  {
    step: 1,
    title: "Consultation & Discovery",
    description:
      "We discuss your project’s goals, target audience, and technical requirements to craft a tailored development approach.",
    icon: <Search size={24} />,
  },
  {
    step: 2,
    title: "UI/UX Design & Prototyping",
    description:
      "Our designers create intuitive, user-friendly interfaces and prototypes to ensure alignment with your brand and user needs.",
    icon: <Brush size={24} />,
  },
  {
    step: 3,
    title: "Development with React.js",
    description:
      "We build a scalable, maintainable React.js application using a component-based structure optimized for performance.",
    icon: <Code size={24} />,
  },
  {
    step: 4,
    title: "Integration & APIs",
    description:
      "We integrate third-party services, APIs, and backend systems, ensuring smooth data flow and a seamless user experience.",
    icon: <Link size={24} />,
  },
  {
    step: 5,
    title: "Testing & Quality Assurance",
    description:
      "Comprehensive testing, including unit, integration, and end-to-end tests, ensures performance, security, and reliability.",
    icon: <ShieldCheck size={24} />,
  },
  {
    step: 6,
    title: "Deployment & Launch",
    description:
      "We deploy your React.js application smoothly, handling every aspect of the launch for a hassle-free release.",
    icon: <Rocket size={24} />,
  },
  {
    step: 7,
    title: "Post-Launch Support & Maintenance",
    description:
      "We provide ongoing support, updates, and performance optimization to keep your application running at its best.",
    icon: <LifeBuoy size={24} />,
  },
];

export default reactDevelopmentProcess;
