import { motion } from "framer-motion";
import MainLayout from "../layout/main-layout";
import {
  ShieldCheck,
  Users,
  Flame,
  Lightbulb,
  Handshake,
  Target,
  Clock,
  Briefcase,
  Calendar,
  Star,
  MessageCircle,
  Smile,
  BookOpen,
  Rocket,
} from "lucide-react";
import CareerHeadSection from "../Components/careers/career-head-section";
import OurValue from "../Components/careers/our-value";
import LifeAtScs from "../Components/careers/life-at-scs";
import Qualities from "../Components/careers/Qualities";
import JobCta from "../Components/careers/job-cta";
import CTASection from "../Components/common-section/cta-section";


function Career() {

  const careersData = {
    title: "Join Our Dynamic Team",
    intro:
      "Advance your career with exciting opportunities in a thriving, innovative workplace.",
    values: [
      {
        title: "Trust & Accountability",
        description:
          "We build strong relationships through transparency, integrity, and responsibility.",
        icon: <ShieldCheck size={24} />,
      },
      {
        title: "Collective Excellence",
        description:
          "Success is a team effort. We thrive on collaboration, embracing diverse perspectives to achieve shared goals and drive innovation.",
        icon: <Users size={24} />,
      },
      {
        title: "Unrelenting Passion",
        description:
          "We are driven by a deep passion for what we do. Our enthusiasm fuels creativity and excellence.",
        icon: <Flame size={24} />,
      },
      {
        title: "Innovation & Growth",
        description:
          "We embrace change and challenge the status quo, continuously evolving for success.",
        icon: <Lightbulb size={24} />,
      },
      {
        title: "Inclusivity & Respect",
        description:
          "We celebrate diversity and ensure a workplace where everyone feels valued and heard.",
        icon: <Handshake size={24} />,
      },
      {
        title: "Customer-Centric Approach",
        description:
          "Our customers are at the core of everything we do, delivering impactful solutions.",
        icon: <Target size={24} />,
      },
    ],
    benefits: [
      { icon: <Clock size={32} />, text: "Flexible Timing" },
      { icon: <Briefcase size={32} />, text: "Competitive Salary" },
      {
        icon: <Lightbulb size={32} />,
        text: "Freedom to Innovate, Power to Lead",
      },
      { icon: <Calendar size={32} />, text: "5 Days Working" },
      { icon: <Star size={32} />, text: "Productivity Bonus" },
      {
        icon: <MessageCircle size={32} />,
        text: "Transparent & Open Communication",
      },
    ],
    qualities: [
      {
        title: "Strong Leadership Mindset",
        icon: <Briefcase size={24} />,
      },
      {
        title: "Proactive & Self-Driven",
        icon: <Rocket size={24} />,
      },
      {
        title: "Continuous Learning & Growth",
        icon: <BookOpen size={24} />,
      },
      {
        title: "Ambition & Goal-Oriented",
        icon: <Target size={24} />,
      },
      {
        title: "Positive & Solution-Focused",
        icon: <Smile size={24} />,
      },
      {
        title: "Collaborative Team Player",
        icon: <Users size={24} />,
      },
    ],
  };

  return (
    <MainLayout>
      <CareerHeadSection careersData={careersData} />
      <OurValue careersData={careersData} />
      <LifeAtScs careersData={careersData} />
      <CTASection />
      <Qualities careersData={careersData} />
      <JobCta />
    </MainLayout>
  );
}

export default Career;
