import { Building, Pencil, Trash } from "lucide-react";

function TestimonialCard({
  data,
  onUpdateView,
  onDeleteView,
}: {
  data: any;
  onUpdateView: (data: any) => void;
  onDeleteView: (data: any) => void;
}) {
  return (
    <div className="w-full rounded-xl p-3 border flex flex-col gap-3">
      <div className="flex items-center justify-between">
        <div className="flex flex-col items-start gap-2">
          <h2 className="text-lg font-medium">{data.person_name}</h2>
          <h3 className="text-sm font-normal text-gray-600">{data.position}</h3>
          <h3 className="text-sm font-normal text-gray-600 flex gap-1 items-center">
            <span>
              <Building className="size-5" />
            </span>
            {data.company_name}
          </h3>
        </div>
        <div className="flex flex-row items-start gap-2">
          <button
            onClick={onUpdateView}
            className="border-2 border-green-200 text-green-400 p-2 rounded-full outline-none"
          >
            <Pencil />
          </button>
          <button
            onClick={onDeleteView}
            className="border-2 border-red-200 text-red-400 p-2 rounded-full outline-none"
          >
            <Trash />
          </button>
        </div>
      </div>
      <div className="flex items-start justify-start">
        <p className="text-sm leading-normal text-gray-600">{data.message}</p>
      </div>
    </div>
  );
}

export default TestimonialCard;
