import Grid from "../Grid/grid";
import { motion } from "framer-motion";

function HeadSection() {
  return (
    <section className="w-full min-h-screen overflow-hidden relative flex items-center justify-center flex-col lg:p-16 p-8">
      <Grid size={100} direction="top-center" />
      <div className="absolute -top-52 -left-52 rounded-full size-[600px] bg-gradient-to-tl from-white/80 to-secondary/60 blur-xl -z-10" />
      <div className="absolute -bottom-52 -right-52 rounded-full size-[600px] bg-gradient-to-tr from-white/80 to-primary/60 blur-xl -z-10" />
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="text-center"
      >
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="text-6xl md:text-9xl font-semibold"
        >
          Job Openings
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="mt-4 text-lg text-gray-600 max-w-4xl"
        >
          Your Next Opportunity Starts Here.
        </motion.p>
      </motion.div>
    </section>
  );
}

export default HeadSection;
