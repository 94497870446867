import { Code, Layout, Layers, PenTool, Users, Grid } from "lucide-react";

const services = [
  {
    id: 1,
    title: "Web App Development",
    path: "/web-development-company",
    description:
      "We build highly responsive and scalable web applications using the latest technologies. Our focus is on performance, reliability, and user experience.",
    icon: <Code />,
    image:
      "https://images.unsplash.com/photo-1547658719-da2b51169166?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80",
  },
  {
    id: 2,
    title: "Custom Software Development",
    path: "/custom-software-development-company",
    description:
      "Our team develops software solutions tailored to your business requirements. We prioritize functionality, scalability, and seamless integration.",
    icon: <Layers />,
    image:
      "https://images.unsplash.com/photo-1555066931-4365d14bab8c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80",
  },
  {
    id: 3,
    title: "ROR Development",
    path: "/ruby-on-rails-development-company",
    description:
      "Accelerate your projects with Ruby on Rails development. We deliver fast, secure, and scalable solutions for your web and mobile apps.",
    icon: <Grid />,
    image:
      "https://images.unsplash.com/photo-1627398242454-45a1465c2479?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80",
  },
  {
    id: 4,
    title: "Mobile App Development",
    path: "/mobile-app-development-company",
    description:
      "Turn your app idea into a reality with high-performance, feature-rich mobile applications for iOS and Android. We focus on user-centric design, scalability, and advanced functionality to ensure a seamless mobile experience.",
    icon: <Layout />,
    image:
      "https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80",
  },

  {
    id: 5,
    title: "UI/UX Development",
    path: "/ui-ux-design-company",
    description:
      "Our team creates intuitive and visually appealing interfaces to enhance user engagement. We focus on user-centric designs for an exceptional experience.",
    icon: <PenTool />,
    image:
      "https://images.unsplash.com/photo-1558655146-605d86ed31b3?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 6,
    title: "Staff Augmentation",
    path: "/hire-a-developer",
    description:
      "We provide skilled professionals to extend your team and meet your project goals. Our experts are ready to integrate seamlessly with your workflows.",
    icon: <Users />,
    image:
      "https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80",
  },
];

export default services;
