import { Activity, Cloud, Code, Cpu, Monitor, Server, Zap } from "lucide-react";

const whyChooseWebDevelopment = [
  {
    id: 1,
    title: "Bespoke Web Solutions",
    description:
      "Every business is unique, and so should be your website. We craft custom web solutions designed specifically to meet your business needs, ensuring seamless scalability, high performance, and a future-ready digital presence.",
    icon: <Code />,
  },
  {
    id: 2,
    title: "Progressive Web Applications (PWA)",
    description:
      "Get the best of web and mobile combined! Our high-performance PWAs offer lightning-fast speeds, offline access, and an app-like user experience—without the need for downloads from an app store.",
    icon: <Zap />,
  },
  {
    id: 3,
    title: "UI/UX Prototyping & Design",
    description:
      "First impressions matter! We create interactive prototypes and high-fidelity UI/UX designs that ensure your website is intuitive, visually stunning, and optimized for user engagement.",
    icon: <Monitor />,
  },
  {
    id: 4,
    title: "Headless CMS & Static Site Generators",
    description:
      "Speed and flexibility at their best! We implement headless CMS solutions like Strapi and Contentful, or static site generators like Next.js and Gatsby, to create blazing-fast, scalable, and easy-to-manage websites.",
    icon: <Server />,
  },
  {
    id: 5,
    title: "Microservices & Serverless Architecture",
    description:
      "Future-proof your web application with microservices and serverless architecture. We build highly scalable, cost-efficient solutions that enhance performance and streamline business operations.",
    icon: <Cloud />,
  },
  {
    id: 6,
    title: "Real-Time Web Applications",
    description:
      "Engage users like never before! We build real-time applications using WebSockets, Firebase, and GraphQL subscriptions, enabling instant updates, live chats, and interactive experiences.",
    icon: <Activity />,
  },
  {
    id: 7,
    title: "AI & Automation Integration",
    description:
      "Leverage AI to elevate your website! From intelligent chatbots and automation tools to predictive analytics, we integrate AI solutions that enhance efficiency, personalization, and user engagement.",
    icon: <Cpu />,
  },
];
export default whyChooseWebDevelopment;
