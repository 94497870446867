import TestimonialCard from "./card/testimonial-card";

function ViewTestimonialSection({
  testimonials,
  onUpdateView,
  onDeleteView,
}: {
  testimonials: any;
  onUpdateView: (data: any) => void;
  onDeleteView: (data: any) => void;
}) {
  return (
    <div className="border rounded-2xl m-3 p-5">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {testimonials.map((data: any, index: number) => (
          <TestimonialCard
            data={data}
            key={index}
            onDeleteView={() => onDeleteView(data)}
            onUpdateView={() => onUpdateView(data)}
          />
        ))}
      </div>
    </div>
  );
}

export default ViewTestimonialSection;
