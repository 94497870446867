import React, { useRef, useState } from "react";
import Grid from "../Grid/grid";
import services from "./data/service-data";
import ServiceList from "./service-section/service-list";
import ServiceDetail from "./service-section/service-detail";
import Title from "../title";

const WhatWeDo: React.FC = () => {
  const [active, setActive] = useState(0);
  const sectionRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="relative flex flex-col min-h-screen px-4 sm:px-6 md:px-10 lg:px-16 py-10">
      <Grid direction="top-center" size={100} />
      <div className="z-10 flex flex-col justify-center items-start mb-12">
        <Title title="What We're Good At" />
      </div>
      <div className="flex lg:flex-row flex-col-reverse items-center justify-center gap-16">
        <div className="hidden md:flex items-start justify-center lg:w-1/2 flex-col gap-12 z-10">
          <ServiceDetail active={active} />
        </div>
        <div className="flex items-start justify-center lg:w-1/2 flex-col gap-12 z-10">
          {services.map((service, index) => (
            <ServiceList
              active={active}
              index={index}
              service={service}
              setActive={setActive}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
