import React, { useEffect, useState } from "react";
import AdminLayout from "../../../layout/admin-layout";
import { useUser } from "../../../Components/provider/user-id-provider";
import axios from "axios";
import Loader from "../../../Components/loader/loader";
import VisiorQueryTable from "../../../Components/visitor-query/visitor-query-table";

function VisitorQuery() {
  const [loading, setLoading] = useState(true);

  const [queries, setQueries] = useState([]);
  const { UserId }: any = useUser();

  const fetchData = () => {
    axios
      .get(`contact_us`)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setQueries(res.data.contact_us);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <AdminLayout>
      <div className="w-full border-b p-4 flex items-center justify-between">
        <div className="flex flex-row gap-2 items-center justify-center">
          <h2 className="text-xl tracking-wide font-medium capitalize">
            Visitor Queries
          </h2>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="w-full flex flex-row h-full">
          <div className={`w-full px-3 h-full`}>
            <VisiorQueryTable data={queries} />
          </div>
        </div>
      )}
    </AdminLayout>
  );
}

export default VisitorQuery;
