import React from "../../icons/react-svgrepo-com (1).svg";
import Angular from "../../icons/angular-svgrepo-com.svg";
import Next from "../../icons/next-js-svgrepo-com.svg";
import Rails from "../../icons/rails-svgrepo-com.svg";
import Node from "../../icons/nodejs-icon-svgrepo-com.svg";
import Flutter from "../../icons/flutter-svgrepo-com.svg";
import Postgres from "../../icons/postgresql-svgrepo-com.svg";
import Vue from "../../icons/vue-svgrepo-com.svg";
import Mongo from "../../icons/mongodb-svgrepo-com.svg";
import JS from "../../icons/js-svgrepo-com.svg";
import Vite from "../../icons/vite-svgrepo-com.svg";
import TS from "../../icons/typescript-official-svgrepo-com.svg";
import Express from "../../icons/express-svgrepo-com.svg";
import Sinatra from "../../icons/sinatra-svgrepo-com.svg";
import Nestjs from "../../icons/nest-middleware-js-svgrepo-com.svg";
import dotNet from "../../icons/dotnet-svgrepo-com.svg";
import ruby from "../../icons/ruby-svgrepo-com.svg";
import Android from "../../icons/android-color-svgrepo-com.svg";
import swift from "../../icons/swift-svgrepo-com (1).svg";
import MySql from "../../icons/mysql-svgrepo-com.svg";
import Prisma from "../../icons/light-prisma-svgrepo-com.svg";
import Cassandra from "../../icons/cassandra-svgrepo-com.svg";
import SQLite from "../../icons/sqlite-svgrepo-com.svg";
import dynamoDb from "../../icons/aws-dynamodb-svgrepo-com.svg"
import firebase from "../../icons/firebase-svgrepo-com.svg"
import Aws from "../../icons/aws-svgrepo-com.svg"
import GoogleCloud from "../../icons/google-cloud-svgrepo-com.svg"
import Azure from "../../icons/azure-svgrepo-com.svg"
import Heroku from "../../icons/heroku-svgrepo-com.svg"

const techStack = [
  {
    id: 1,
    name: "Frontend",
    techs: [
      { id: 1, name: "React JS", logo: React },
      {
        id: 2,
        name: "Angular JS",
        logo: Angular,
      },
      {
        id: 3,
        name: "Next JS",
        logo: Next,
      },
      {
        id: 4,
        name: "Vue JS",
        logo: Vue,
      },
      {
        id: 5,
        name: "JavaScript",
        logo: JS,
      },
      {
        id: 6,
        name: "Vite",
        logo: Vite,
      },
      {
        id: 7,
        name: "TypeScript",
        logo: TS,
      },
    ],
  },
  {
    id: 2,
    name: "Backend",
    techs: [
      { id: 1, name: "Ruby", logo: ruby },
      { id: 2, name: "Ruby on Rails", logo: Rails },
      {
        id: 3,
        name: "Sinatra",
        logo: Sinatra,
      },
      { id: 4, name: "Node JS", logo: Node },
      {
        id: 5,
        name: "Express JS",
        logo: Express,
      },
      {
        id: 6,
        name: "Nest JS",
        logo: Nestjs,
      },
      {
        id: 7,
        name: ".NET",
        logo: dotNet,
      },
    ],
  },
  {
    id: 3,
    name: "Mobile",
    techs: [
      { id: 1, name: "Android", logo: Android },
      {
        id: 2,
        name: "SwiftUI",
        logo: swift,
      },
      { id: 3, name: "React Native", logo: React },
      {
        id: 4,
        name: "Flutter",
        logo: Flutter,
      },
    ],
  },
  {
    id: 4,
    name: "Database",
    techs: [
      { id: 1, name: "MySQL", logo: MySql },
      { id: 2, name: "PostgreSQL", logo: Postgres },
      {
        id: 3,
        name: "MongoDB",
        logo: Mongo,
      },{
        id: 4,
        name: "Firebase",
        logo: firebase,
      },
      {
        id: 5,
        name: "Prisma",
        logo: Prisma,
      },
      {
        id: 6,
        name: "Cassandra",
        logo: Cassandra,
      },
      {
        id: 7,
        name: "SQLite",
        logo: SQLite,
      },{
        id: 8,
        name: "DynamoDB",
        logo: dynamoDb,
      }
    ],
  },{
    id: 5,
    name: "Cloud Services",
    techs: [
      { id: 1, name: "AWS", logo: Aws },
      { id: 2, name: "Google Cloud", logo: GoogleCloud },
      { id: 3, name: "Microsoft Azure", logo: Azure },
      { id: 4, name: "Heroku", logo: Heroku },
    ]}
];

export default techStack;
