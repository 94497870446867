import { motion } from "framer-motion";
import Grid from "../Grid/grid";
import Title from "../title";

function BriefSection() {
  const companyValues = [
    {
      title: "WHO WE ARE",
      description:
        " Softcoding Solutions specializes in transforming businesses with innovative technology and exceptional service. With over a decade of experience, they offer customized software development, cloud solutions, and digital transformation across various industries.Their expert team embraces emerging technologies to deliver valuable, cutting-edge solutions and build long-term partnerships.They focus on understanding client needs, providing tailored strategies, and ensuring timely delivery to achieve transformation goals.",
    },
    {
      title: "OUR MISSION",
      description:
        "Our mission is to deliver innovative, reliable IT solutions that empower businesses and drive digital transformation. We prioritize quality, integrity, and customer satisfaction, crafting tailored solutions to enhance productivity and efficiency. Through cutting-edge technology and strong partnerships, we aim to create lasting value and inspire growth in a dynamic digital world.",
    },
    {
      title: "OUR VISION",
      description:
        "Our vision is to be a leading IT solutions provider, recognized for innovation, excellence, and customer-centricity. We aim to empower businesses worldwide by delivering transformative technologies, fostering digital growth, and building sustainable partnerships. Through continuous innovation, we strive to shape a smarter, more connected, and efficient digital future.",
    },
  ];

  return (
    <section className="relative w-full min-h-screen flex flex-col overflow-hidden lg:p-16 p-8">
      <Grid size={100} direction="top-left" />
      <div className="z-10 flex justify-start items-start mb-12">
        <Title title="About Us" />
      </div>
      <div className="flex flex-col-reverse md:flex-row-reverse gap-5 p-6">
        <motion.div
          className="flex flex-col md:w-1/2 items-center justify-center gap-5"
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <img
            alt="team"
            src="https://images.unsplash.com/photo-1568992687947-868a62a9f521?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            className="w-full rounded-2xl md:h-[550px] h-full"
          />
        </motion.div>
        <motion.div
          className="flex flex-col md:w-1/2 items-start md:p-6 justify-start gap-5"
          initial={{ opacity: 0, x: 50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
        >
          {companyValues.map((data, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-start justify-center gap-5"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.3 * index, duration: 0.6 }}
            >
              <h2 className="font-bold text-4xl">{data.title}</h2>
              <p>{data.description}</p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
}

export default BriefSection;
