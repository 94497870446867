import * as Yup from "yup";

const testimonialValidationSchema = Yup.object().shape({
  person_name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name cannot exceed 50 characters"),

  message: Yup.string()
    .required("Message is required")
    .min(10, "Message must be at least 10 characters")
    .max(500, "Message cannot exceed 500 characters"),

  company_name: Yup.string()
    .required("Company name is required")
    .min(2, "Company name must be at least 2 characters")
    .max(100, "Company name cannot exceed 100 characters"),

  position: Yup.string()
    .required("Position is required")
    .min(2, "Position must be at least 2 characters")
    .max(50, "Position cannot exceed 50 characters"),
});

export default testimonialValidationSchema;
