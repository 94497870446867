import Grid from "../Grid/grid";
import Process from "../process";
import Title from "../title";
import rubyOnRailsDevelopmentProcess from "./data/ror-development-data";

function RORProcess() {
  return (
    <section className="w-full min-h-screen h-full relative flex flex-col overflow-hidden p-2 lg:p-16">
      <Grid size={100} direction="top-left" />
      <div className="z-10 flex justify-start items-start mb-12 w-full">
        <Title title="our process" />
      </div>
      <Process
        data={rubyOnRailsDevelopmentProcess}
        img="https://images.unsplash.com/photo-1494891848038-7bd202a2afeb?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        title="Ruby On Rails Development"
        description="Building Scalable & High-Performance Web Apps – Ruby on Rails Solutions Tailored for Your Success."
      />
    </section>
  );
}

export default RORProcess;
