import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Building, ChevronLeft, ChevronRight, Pause, Play } from "lucide-react";
import Grid from "../Grid/grid";
import Title from "../title";
import axios from "axios";
import Loader from "../loader/loader";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-device-frameset/styles/marvel-devices.min.css";

function Testimonial() {
  const [testimonials, setTestimonials] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchTestimonials = () => {
    axios
      .get(`testimonials`)
      .then((res) => {
        setLoading(false);
        setTestimonials(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  return (
    <section className="w-full h-auto relative flex flex-col lg:p-16 p-8 overflow-hidden">
      <Grid size={100} direction="top-left" />
      <div className="z-10 flex justify-start items-start mb-12">
        <Title title="What our customers say" />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col justify-center items-center w-full h-full relative">
          <div className="flex gap-2 sm:gap-3 items-center justify-end w-full mb-4 sm:mb-6">
            <button
              className="custom-swiper-prev h-10 w-10 sm:h-12 sm:w-12 rounded-full bg-gray-100 flex items-center justify-center group shadow-md hover:shadow-lg transition-all duration-300"
              aria-label="Previous testimonial"
            >
              <ChevronLeft className="h-5 w-5 sm:h-6 sm:w-6 text-black group-hover:scale-110 transition-transform duration-300" />
            </button>
            <button
              className="custom-swiper-next h-10 w-10 sm:h-12 sm:w-12 rounded-full bg-gray-100 flex items-center justify-center group shadow-md hover:shadow-lg transition-all duration-300"
              aria-label="Next testimonial"
            >
              <ChevronRight className="h-5 w-5 sm:h-6 sm:w-6 text-black group-hover:scale-110 transition-transform duration-300" />
            </button>
          </div>

          <div className="w-full overflow-hidden">
            <Swiper
              pagination={{ clickable: true }}
              navigation={{
                nextEl: ".custom-swiper-next",
                prevEl: ".custom-swiper-prev",
              }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              loop={true}
              modules={[Pagination, Navigation, Autoplay]}
              className="relative w-full max-w-7xl p-5"
            >
              {testimonials.map((data, index) => (
                <SwiperSlide
                  key={index}
                  className="bg-gray-100 rounded-3xl border border-gray-300 
                         hover:bg-secondary/70 group p-4 sm:p-6 ease-in duration-300 flex-shrink-0
                         flex flex-col gap-4 sm:gap-6  
                         transform transition-all"
                >
                  <div className="flex-1 flex items-start justify-start">
                    <p className="text-xs sm:text-sm leading-relaxed text-gray-600 group-hover:text-white transition-colors duration-300">
                      <span className="text-xl sm:text-2xl font-bold  group-hover:text-white font-sans">
                        "
                      </span>
                      <span className="line-clamp-[12] text-xl">
                        {data.message}
                      </span>
                      <span className="text-xl sm:text-2xl font-bold  group-hover:text-white font-sans">
                        "
                      </span>
                    </p>
                  </div>
                  <div className="flex items-start justify-start gap-2 sm:gap-3 flex-col">
                    <h2 className="text-lg sm:text-xl font-semibold group-hover:text-white transition-colors duration-300">
                      {data.person_name}
                    </h2>
                    <h3 className="text-xs sm:text-sm font-normal text-gray-500 group-hover:text-white flex gap-2 items-center">
                      <Building className="w-4 h-4 text-gray-600 group-hover:text-white" />
                      <span className="line-clamp-1">
                        {data.position} at {data.company_name}
                      </span>
                    </h3>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </section>
  );
}

export default Testimonial;
