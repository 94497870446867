const traits = [
  {
    trait: "Self-motivated",
    description:
      "Capable of driving outcomes with minimal guidance and taking ownership of projects.",
  },
  {
    trait: "Self-aware",
    description:
      "Comfortable being challenged by peers and open to sharing knowledge and feedback.",
  },
  {
    trait: "Collaborative",
    description:
      "Experienced in working closely with Product teams and cross-functional groups to deliver impactful solutions.",
  },
  {
    trait: "Driven",
    description:
      "Proven track record of taking the initiative on engineering projects and pushing them to completion.",
  },
  {
    trait: "Problem solver",
    description:
      "Passionate about solving complex challenges and providing innovative solutions to difficult problems.",
  },
  {
    trait: "Adaptable",
    description:
      "Able to thrive in a fast-paced environment, adjusting quickly to changing priorities and new challenges.",
  },
  {
    trait: "Continuous Learner",
    description:
      "Eager to stay up to date with the latest technologies and best practices, and always seeking opportunities to grow.",
  },
  {
    trait: "Quality-focused",
    description:
      "Committed to delivering high-quality code and continuously improving development processes.",
  },
  {
    trait: "Customer-Centric",
    description:
      "Understands the importance of building solutions that create real value for users, whether internal or external.",
  },
  {
    trait: "Effective Communicator",
    description:
      "Able to clearly articulate technical concepts to both technical and non-technical stakeholders.",
  },
];

export { traits };
