import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/home";
import AboutUs from "./Pages/about-us";
import ContentManagementSystem from "./Pages/content-management-system";
import RubyOnRails from "./Pages/ruby-on-rails";
import ReactJs from "./Pages/react-js";
import WebDevelopment from "./Pages/web-development";
import ScrollToTop from "./Components/scroll-to-top";
import CustomSoftwareDevelopment from "./Pages/custom-software-development";
import MobileAppDevelopment from "./Pages/mobile-app-development";
import UiUxDesign from "./Pages/ui-ux-design";
import DetailProduct from "./Pages/detail-product";
import Admin from "./Pages/admin/admin";
import OurWork from "./Pages/admin/our-work/our-work";
import PrivateRoute from "./Components/PrivateRoute/private-route";
import AddWork from "./Pages/admin/our-work/add-work";
import UpdateWork from "./Pages/admin/our-work/update-work";
import Testimonial from "./Pages/admin/testimonial/testimonial";
import Blog from "./Pages/admin/blog/blog-admin";
import AddBlog from "./Pages/admin/blog/add-blog";
import UpdateBlog from "./Pages/admin/blog/update-blog";
import AdminClient from "./Pages/admin/client/admin-client";
import OurWorkUser from "./Pages/our-work";
import OurWorkDetail from "./Pages/our-work-detail";
import CaseStudy from "./Pages/case-study";
import UserBlog from "./Pages/user-blog";
import ContactUs from "./Pages/contact-us";
import Career from "./Pages/career";
import HireADeveloper from "./Pages/hire-a-developer";
import JobOpening from "./Pages/admin/job-opening/job-opening";
import AddJobOpening from "./Pages/admin/job-opening/add-job-opening";
import UpdateJobOpening from "./Pages/admin/job-opening/update-job-opening";
import JobOpeningUser from "./Pages/job-opening";
import VisitorQuery from "./Pages/admin/visitor-query/visitor-query";

function App() {
  return (
    <div className="font-montserrat">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route
            path="/admin/visitor-queries"
            element={
              <PrivateRoute allowedRole={["admin"]}>
                <VisitorQuery />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/job-openings/update-job-opening/:id"
            element={
              <PrivateRoute allowedRole={["admin"]}>
                <UpdateJobOpening />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/job-openings/add-job-opening"
            element={
              <PrivateRoute allowedRole={["admin"]}>
                <AddJobOpening />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/job-openings"
            element={
              <PrivateRoute allowedRole={["admin"]}>
                <JobOpening />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/client-logos"
            element={
              <PrivateRoute allowedRole={["admin"]}>
                <AdminClient />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/blog/update-blog/:id/:title"
            element={
              <PrivateRoute allowedRole={["admin"]}>
                <UpdateBlog />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/blog/add-blog"
            element={
              <PrivateRoute allowedRole={["admin"]}>
                <AddBlog />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/blog"
            element={
              <PrivateRoute allowedRole={["admin"]}>
                <Blog />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/testimonial"
            element={
              <PrivateRoute allowedRole={["admin"]}>
                <Testimonial />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/our-work/update-work/:id/:title"
            element={
              <PrivateRoute allowedRole={["admin"]}>
                <UpdateWork />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/our-work/add-work"
            element={
              <PrivateRoute allowedRole={["admin"]}>
                <AddWork />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/our-work"
            element={
              <PrivateRoute allowedRole={["admin"]}>
                <OurWork />
              </PrivateRoute>
            }
          />
          <Route path="/admin" element={<Admin />} />
          <Route path="/job-openings" element={<JobOpeningUser />} />
          <Route path="/hire-a-developer" element={<HireADeveloper />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/blog" element={<UserBlog />} />
          <Route path="/case-study/:id/:title" element={<CaseStudy />} />
          <Route path="/our-work/:id/:title" element={<OurWorkDetail />} />
          <Route path="/our-work" element={<OurWorkUser />} />
          <Route
            path="/product/:product_name/:id"
            element={<DetailProduct />}
          />
          <Route path="/ui-ux-design-company" element={<UiUxDesign />} />
          <Route
            path="/mobile-app-development-company"
            element={<MobileAppDevelopment />}
          />
          <Route
            path="/custom-software-development-company"
            element={<CustomSoftwareDevelopment />}
          />
          <Route path="/web-development-company" element={<WebDevelopment />} />
          <Route path="/react-js-development-company" element={<ReactJs />} />
          <Route
            path="/ruby-on-rails-development-company"
            element={<RubyOnRails />}
          />
          <Route
            path="/content-management-system"
            element={<ContentManagementSystem />}
          />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
