import logo from "../../Assets/logo company.png";

function Loader() {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="loader rounded-full bg-transperent animate-spin aspect-square size-20 flex justify-center items-center">
        <img loading="lazy" alt="logo" src={logo} />
      </div>
    </div>
  );
}

export default Loader;
