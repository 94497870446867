import React from "react";
import { Link } from "react-router-dom";

function CTASection() {
  return (
    <section className="w-full h-auto overflow-hidden relative flex flex-col lg:p-16 p-8">
      <div className="bg-gradient-to-r from-primary via-primary/90 to-primary/70 rounded-3xl relative overflow-hidden">
        <div className="absolute -right-32 top-1/2 size-[700px] -translate-y-1/2 rounded-full bg-gradient-to-l from-white/50 to-white/40 shadow-lg"></div>
        <div className="absolute -right-32 top-1/2 size-[600px] -translate-y-1/2 rounded-full bg-gradient-to-l from-white/50 to-white/40 shadow-lg"></div>
        <div className="absolute -right-32 top-1/2 size-[500px] -translate-y-1/2 rounded-full bg-gradient-to-l from-white/50 to-white/40 shadow-lg"></div>
        <div className="absolute -right-32 top-1/2 size-[400px] -translate-y-1/2 rounded-full bg-gradient-to-l from-white/50 to-white/40 shadow-lg"></div>
        <div className="absolute -right-32 top-1/2 size-[300px] -translate-y-1/2 rounded-full bg-gradient-to-l from-white/50 to-white/40 shadow-lg"></div>
        <div className="absolute  bg-black/60 flex lg:hidden w-full h-full" />
        <div className="container relative z-10 mx-auto px-8 py-16 md:py-24">
          <div className="max-w-2xl">
            <h2 className="mb-4 text-4xl font-bold text-white md:text-5xl">
              Let's Get In Touch.
            </h2>
            <p className="mb-8 text-lg text-white/90">
              Empower your business with technology that works for you, not the
              other way around. We provide seamless IT solutions and expert
              support to keep you ahead.
            </p>
            <Link
              to={"/contact-us"}
              className="px-4 py-3  bg-white rounded-full text-primary font-semibold text-lg outline-none"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CTASection;
