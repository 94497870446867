import { motion } from "framer-motion";
import Grid from "../Grid/grid";
import products from "./data/product-data";
import { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Title from "../title";

function OurProducts() {
  const [active, setActive] = useState(0);
  return (
    <section className="w-full min-h-screen h-full overflow-hidden relative flex flex-col lg:p-16 p-8">
      <Grid direction="top-center" size={100} />
      <div className="z-10 flex flex-wrap justify-between items-start gap-4">
        <Title title="Our Products" />
        <div className="flex-row gap-2 flex items-end justify-end md:w-auto w-full">
          <button
            disabled={active === 0}
            onClick={() => setActive(active - 1)}
            className={`${products[active].color} hover:${products[active].color}/60 outline-none text-white rounded-full p-2 disabled:bg-gray-400 disabled:cursor-not-allowed transition-colors duration-300`}
            aria-label="Previous product"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <button
            disabled={active === products.length - 1}
            onClick={() => setActive(active + 1)}
            className={`${products[active].color} hover:${products[active].color}/60 outline-none text-white rounded-full p-2 disabled:bg-gray-400 disabled:cursor-not-allowed transition-colors duration-300`}
            aria-label="Next product"
          >
            <ChevronRight className="w-6 h-6" />
          </button>
        </div>
      </div>
      <div className="flex h-full md:p-10 py-10">
        <motion.div
          className="flex flex-col w-full h-full gap-2"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex md:flex-row flex-col items-center justify-between my-5 gap-3">
            <motion.div
              className="flex flex-col items-start justify-center gap-4 "
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <motion.h2
                className="text-3xl sm:text-4xl lg:text-5xl font-bold text-gray-800 transition-all duration-500 ease-in-out"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                {products[active].name}
              </motion.h2>
              <motion.p
                className="text-base sm:text-lg font-light text-gray-600 transition-all duration-500 ease-in-out"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                {products[active].description}
              </motion.p>
            </motion.div>
          </div>
          <motion.div
            className="flex flex-col"
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div
              className={`${products[active].color} p-4 w-full md:min-h-[600px]  md:h-full h-auto  flex flex-col rounded-2xl shadow-xl transition-colors duration-500 ease-in-out relative`}
            >
              <div className="w-full relative md:min-h-[600px] md:h-full h-auto">
                <img
                  src={products[active].img}
                  alt="product"
                  loading="lazy"
                  className="w-full md:min-h-[600px] md:h-full h-auto rounded-xl bg-white"
                />
                {/* <iframe
                  className="w-full min-h-[600px] h-full rounded-xl bg-white"
                  src={products[active].url}
                  title={products[active].name}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                /> */}
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
}

export default OurProducts;
