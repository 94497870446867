import HeadSection from "../Components/AboutUs/head-section";
import BriefSection from "../Components/AboutUs/brief-section";
import TeamSection from "../Components/AboutUs/team-section";
import Testimonial from "../Components/common-section/testimonial";
import PartnerWithUs from "../Components/AboutUs/partner-with-us";
import EngineerCulture from "../Components/AboutUs/engineer-culture";
import OurProducts from "../Components/common-section/our-product";
import HappyClient from "../Components/common-section/happy-client";
import { lazy, Suspense } from "react";
import MainLayout from "../layout/main-layout";
import CTASection from "../Components/common-section/cta-section";
const AccrossGlobe = lazy(() => import("../Components/AboutUs/across-globe"));

function AboutUs() {
  return (
    <>
      <MainLayout>
        <HeadSection />
        <BriefSection />
        <EngineerCulture />
        <TeamSection />
        <PartnerWithUs />
        <CTASection />
        <HappyClient />
        <Testimonial />
        <OurProducts />
        <Suspense
          fallback={<div className="text-center py-10">Loading...</div>}
          children={<AccrossGlobe />}
        />
      </MainLayout>
    </>
  );
}

export default AboutUs;
