import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SecureLS from "secure-ls";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { useUser } from "../../Components/provider/user-id-provider";

function Admin() {
  const [clientId, setClientId] = useState("");
  const ls = new SecureLS();
  const navigate = useNavigate();
  const { setUserId } = useUser();

  const fetchId = () => {
    axios
      .get(`get_client_and_secret`)
      .then((res) => {
        setClientId(res.data.client_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchId();
  }, []);

  const handleLogin = (response: any) => {
    axios
      .post(`users/google_sign_in`, {
        token: response.credential,
        client_id: clientId,
      })
      .then((res) => {
        if (res.status === 200) {
          ls.set("isAuthenticated", true);
          ls.set("role", res?.data?.user?.role);
          ls.set("access_token", res?.data?.user?.access_token);
          // ls.set("user_id", res?.data?.user?.id);
          setUserId(res?.data?.user?.id);
          navigate("/admin/our-work");
          toast.success("Logged In!!");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message || err.message);
      });
  };

  return (
    <GoogleOAuthProvider clientId="673635907738-bttm97du8abepgrh80cil8juibnftpp2.apps.googleusercontent.com">
      <div className="h-screen flex flex-col md:flex-row-reverse bg-gray-100 overflow-hidden">
        <motion.div
          className="md:w-3/5 relative"
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <img
            src="https://images.unsplash.com/photo-1554110397-9bac083977c6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fHx8fA%3D%3D"
            loading="lazy"
            alt="banner"
            className="w-full h-full object-cover rounded-bl-full rounded-br-full md:rounded-br-none shadow-lg"
          />
          <div className="absolute top-0 left-0 rounded-bl-full rounded-br-full md:rounded-br-none p-3 flex items-start justify-center md:justify-start bg-black/50 h-full w-full">
            <h2 className="text-white capitalize text-3xl md:text-4xl tracking-wide">
              Softcoding Solutions
            </h2>
          </div>
        </motion.div>
        <motion.div
          className="md:w-2/5 md:h-full flex flex-col items-center justify-center gap-8 p-8"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <div className="p-10 rounded-2xl flex flex-col items-center w-full max-w-md">
            <h2 className="text-2xl md:text-4xl font-bold tracking-wide text-gray-900 mb-6">
              Welcome Back
            </h2>
            <p className="text-gray-600 text-lg text-center mb-4">
              Log in to continue to your dashboard
            </p>
            <GoogleLogin onSuccess={handleLogin} useOneTap />
          </div>
        </motion.div>
      </div>
    </GoogleOAuthProvider>
  );
}

export default Admin;
