import * as Yup from "yup";

export const validationSchema = Yup.object({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  mobile_no: Yup.string()
    .matches(/^\d{10}$/, "Must be a valid 10-digit number")
    .required("Mobile number is required"),
  email: Yup.string()
    .required("Email is required.")
    .email("Invalid Email Format."),
    linkedin_url: Yup.string()
    .matches(
      /^https?:\/\/([a-z]{2,3}\.)?linkedin\.com\/(in|pub|company|school)\/[a-zA-Z0-9-_%]+\/?$/,
      "Must be a valid LinkedIn URL"
    )
    .required("LinkedIn URL is required"),
  

  current_expected_salary: Yup.string().required(
    "Salary expectation is required"
  ),
  gender: Yup.string().required("Gender selection is required"),
  open_for_relocation: Yup.string().required(
    "Please select if you can relocate or not."
  ),
  notice_period: Yup.string().required("Notice period is required"),
  upload_cv: Yup.mixed().required("CV is required"),
  cover_letter: Yup.string().required("Cover letter is required"),
});
