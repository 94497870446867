import * as Yup from "yup";
import Input from "../input/Input";
import { Form, Formik } from "formik";
import TextArea from "../input/text-area";
import Button from "../input/Button";
import axios from "axios";
import { toast } from "sonner";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Name must be at least 2 characters")
    .required("Name is required"),
  contact_number: Yup.string()
    .matches(/^\+?\d{7,15}$/, "Enter a valid phone number")
    .required("Contact number is required"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  message: Yup.string()
    .min(10, "Message must be at least 10 characters")
    .required("Message is required"),
});

function ContactForm({ title, purpose }: { title: string; purpose: string }) {
  const sendQuery = (values: any, { resetForm }: any) => {
    axios
      .post("contact_us", {
        contact_us: {
          name: values.name,
          email: values.email,
          contact_no: values.contact_number,
          company_name: "-",
          purpose: purpose,
          message: values.message,
        },
      })
      .then((res) => {
        toast.success("Query sent successfully");
        resetForm();
      })
      .catch((err) => {
        toast.error(err.response.data.message || err.message);
        console.log(err);
      });
  };
  return (
    <div className="bg-white p-5 border-2 border-black flex flex-col items-center justify-center gap-4 shadow-[6px_6px_0_#000]">
      <div className="flex items-start justify-start w-full">
        <h2 className="text-xl tracking-wide">{title}</h2>
      </div>
      <div className="flex items-center justify-center mt-2">
        <Formik
          initialValues={{
            name: "",
            contact_number: "",
            email: "",
            message: "",
          }}
          validationSchema={validationSchema}
          onSubmit={sendQuery}
        >
          {() => (
            <Form>
              <div className="gap-4">
                <Input
                  name="name"
                  type="text"
                  placeholder="Your Name"
                  label={"Name"}
                />
                <div className="grid md:grid-cols-2 gap-4">
                  <Input
                    name="contact_number"
                    type="number"
                    placeholder="Your Contact Number"
                    label={"Contact number"}
                  />
                  <Input
                    name="email"
                    type="email"
                    placeholder="Your Email"
                    label={"Email"}
                  />
                </div>
                <TextArea
                  name="message"
                  placeholder="Your Message"
                  label={"Message"}
                />
              </div>
              <Button text="submit" />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ContactForm;
