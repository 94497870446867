import React from "react";
import Grid from "../Grid/grid";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";

function JobCta() {
  return (
    <section className="w-full h-auto overflow-hidden relative flex flex-col px-4 sm:px-6 md:px-10 lg:px-16 py-12 lg:py-16">
      <Grid direction="full" size={100} />
      <div className="w-full flex flex-col gap-3">
        <h2 className="text-4xl sm:text-5xl md:text-7xl font-light leading-tight">
          DON'T JUST VIEW.
          <span className="font-semibold"> ACT.</span>
        </h2>

        <p className="text-lg sm:text-xl text-gray-600 mt-4">
          Join Our Team at Softcoding Solutions – Exciting Career Opportunities
          Await!
        </p>

        <div className="flex">
          <Link
            to={"/job-openings"}
            className="bg-white shadow-[4px_4px_0_#000] hover:shadow-[6px_6px_0_#000] p-3 border-2 flex gap-2 border-black font-semibold outline-none my-8 sm:my-10 transition-all duration-300 hover:scale-105"
          >
            <ArrowRight /> Join Us
          </Link>
        </div>
      </div>
    </section>
  );
}

export default JobCta;
