import { motion } from "framer-motion";

function HeadSection() {
  return (
    <section className="w-full min-h-screen h-full relative flex flex-col">
      <video
        src={require("../../Assets/videos/CMS.webm")}
        autoPlay
        loop
        muted
        playsInline
        className="absolute top-0 left-0 w-full h-full object-cover"
      />
      <div className="absolute inset-0 flex flex-col gap-5 items-start p-10 justify-center bg-black/50">
        <motion.h1
          className="text-white text-4xl font-light"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          Content Management System
        </motion.h1>
        <motion.p
          className="text-base text-white"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut", delay: 0.3 }}
        >
          Streamline content creation, editing, and publishing with a
          user-friendly CMS, ensuring seamless workflow management.
        </motion.p>
      </div>
    </section>
  );
}

export default HeadSection;
