import React from "react";
import Grid from "../Grid/grid";
import Title from "../title";
import Process from "../process";
import webProcess from "./data/web-process";

function WebDevelopmentProcess() {
  return (
    <section className="w-full min-h-screen h-full relative flex flex-col overflow-hidden p-2 lg:p-16">
      <Grid size={100} direction="top-left" />
      <div className="z-10 flex justify-start items-start mb-12 w-full">
        <Title title="our process" />
      </div>
      <Process
        data={webProcess}
        img="https://images.unsplash.com/photo-1597848480696-e9e4059687cf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        title="Web Development"
        description="Crafting Scalable & High-Performance Websites – Custom Web Development Solutions Designed for Your Growth."
      />
    </section>
  );
}

export default WebDevelopmentProcess;
