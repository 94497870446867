import AdminLayout from "../../../layout/admin-layout";
import TestimonialAdminHeader from "../../../Components/testimonial-admin/testimonial-admin-header";
import { useEffect, useState } from "react";
import AddTestimonialForm from "../../../Components/testimonial-admin/add-form/add-testimonial-form";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../../../Components/loader/loader";
import ViewTestimonialSection from "../../../Components/testimonial-admin/view-testimonial-section/view-testimonial-section";
import UpdateTestimonialForm from "../../../Components/testimonial-admin/update-form/update-testimonial-form";
import DeleteModal from "../../../Components/delete-modal/delete-modal";
import { useUser } from "../../../Components/provider/user-id-provider";

function Testimonial() {
  const [view, setView] = useState(false);
  const [update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [testimonials, setTestimonials] = useState([]);
  const [selectedTestimonial, setSelectedTestimonial] = useState<any>({});
   const {userId} = useUser();

  const fetchTestimonials = () => {
    axios
      .get(`users/${userId}/testimonials`)
      .then((res) => {
        setLoading(false);
        setTestimonials(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const addTestimonial = (values: any) => {
    const loading = toast.loading("Adding testimonial...");
    axios
      .post(`users/${userId}/testimonials`, {
        testimonial: {
          person_name: values.person_name,
          position: values.position,
          company_name: values.company_name,
          message: values.message,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          toast.dismiss(loading);
          toast.success("Testimonial added successfully");
          setView(false);
          fetchTestimonials();
        }
      })
      .catch((err) => {
        toast.dismiss(loading);
        toast.error(err.response.data.message || err.message);
      });
  };

  const updateTestimonial = (values: any) => {
    const loading = toast.loading("Updating testimonial...");
    axios
      .put(`users/${userId}/testimonials/${selectedTestimonial.id}`, {
        testimonial: {
          person_name: values.person_name,
          position: values.position,
          company_name: values.company_name,
          message: values.message,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loading);
          toast.success("Testimonial Updated successfully");
          setUpdate(false);
          setSelectedTestimonial({});
          fetchTestimonials();
        }
      })
      .catch((err) => {
        toast.dismiss(loading);
        toast.error(err.response.data.message || err.message);
      });
  };

  const deleteTestimonial = () => {
    const loading = toast.loading("Deleting testimonial...");
    axios
      .delete(`users/${userId}/testimonials/${selectedTestimonial.id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loading);
          toast.success("Testimonial Deleted successfully");
          setDelete(false);
          setSelectedTestimonial({});
          fetchTestimonials();
        }
      })
      .catch((err) => {
        toast.dismiss(loading);
        toast.error(err.response.data.message || err.message);
      });
  };

  const handleUpdateView = (data: any) => {
    setSelectedTestimonial(data);
    setUpdate(true);
  };
  const handleDeleteView = (data: any) => {
    setSelectedTestimonial(data);
    setDelete(true);
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  useEffect(() => {
    if (view || update || Delete) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [view, update, Delete]);

  return (
    <AdminLayout>
      <TestimonialAdminHeader setView={setView} />
      {loading ? (
        <Loader />
      ) : (
        <div className="w-full">
          <ViewTestimonialSection
            testimonials={testimonials}
            onDeleteView={handleDeleteView}
            onUpdateView={handleUpdateView}
          />
        </div>
      )}
      {view && (
        <AddTestimonialForm
          onClose={() => setView(false)}
          onSubmit={addTestimonial}
        />
      )}
      {update && (
        <UpdateTestimonialForm
          onClose={() => {
            setUpdate(false);
            setSelectedTestimonial({});
          }}
          selectedTestimonial={selectedTestimonial}
          onSubmit={updateTestimonial}
        />
      )}
      {Delete && (
        <DeleteModal
          customMessage={`Do you want to remove testimonial of ${selectedTestimonial.person_name} ?`}
          onCancel={() => {
            setDelete(false);
            setSelectedTestimonial({});
          }}
          onDelete={deleteTestimonial}
        />
      )}
    </AdminLayout>
  );
}

export default Testimonial;
