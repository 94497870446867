import {
  Search,
  Layers,
  Code,
  CheckCircle,
  Rocket,
  LifeBuoy,
} from "lucide-react";

const rubyOnRailsDevelopmentProcess = [
  {
    step: 1,
    title: "Discovery & Requirements Gathering",
    description:
      "We start by understanding your goals, target audience, and specific requirements. Through detailed research, we align the project scope with your vision.",
    icon: <Search />,
  },
  {
    step: 2,
    title: "System Architecture & Planning",
    description:
      "Our expert architects design the application's structure, ensuring scalability, performance, and maintainability from day one. Timelines and milestones are mapped to keep the project on track.",
    icon: <Layers />,
  },
  {
    step: 3,
    title: "Design & Development",
    description:
      "Our designers create an intuitive, user-friendly interface while developers leverage Ruby on Rails to build a dynamic, responsive, and feature-rich web application.",
    icon: <Code />,
  },
  {
    step: 4,
    title: "Testing & Quality Assurance",
    description:
      "We conduct rigorous QA testing to ensure the application is bug-free, functions smoothly, and performs well under different conditions.",
    icon: <CheckCircle />,
  },
  {
    step: 5,
    title: "Deployment & Launch",
    description:
      "Once ready, we deploy your application to production, ensuring a smooth and successful launch. Our team handles server configuration, security hardening, and deployment details.",
    icon: <Rocket />,
  },
  {
    step: 6,
    title: "Post-Launch Support & Maintenance",
    description:
      "We provide ongoing support and maintenance to ensure your application stays secure, updated, and performs optimally.",
    icon: <LifeBuoy />,
  },
];

export default rubyOnRailsDevelopmentProcess;
