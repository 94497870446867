import { motion } from "framer-motion";
import MainLayout from "../layout/main-layout";
import Grid from "../Components/Grid/grid";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../Components/loader/loader";
import { useNavigate } from "react-router-dom";

function OurWorkUser() {
  const navigate = useNavigate();
  const [works, setWorks] = useState([]);
  const [visibleWorks, setVisibleWorks] = useState(4);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    axios
      .get(`case_studies`)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setWorks(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MainLayout>
      {loading ? (
        <section className="w-full min-h-screen h-full flex flex-col items-start justify-center lg:p-16 p-8 mt-16">
          <Loader />
        </section>
      ) : (
        <>
          <Grid size={100} direction="top-center" />
          <section className="w-full min-h-screen h-full flex flex-col items-center justify-center lg:p-16 p-8 mt-32">
            <div className="relative z-10 flex flex-col items-center text-center w-full max-w-4xl">
              <motion.h2
                className="text-4xl md:text-6xl font-bold text-black capitalize"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
              >
                Projects we worked on
              </motion.h2>
              <motion.p
                className="mt-4 text-lg text-gray-600 max-w-2xl"
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.3, ease: "easeOut" }}
              >
                Explore some of the amazing projects we’ve built, helping
                businesses achieve their goals with innovative solutions.
              </motion.p>
            </div>

            <div className="relative flex justify-center items-center w-full max-w-6xl mt-32">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                {works.slice(0, visibleWorks).map((data: any, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{
                      duration: 0.6,
                      ease: "easeOut",
                      delay: 0.15 * index,
                    }}
                    className="relative group overflow-hidden rounded-2xl shadow-lg bg-white/10 backdrop-blur-lg border border-primary/40 hover:shadow-xl transition-all"
                  >
                    <div className="relative overflow-hidden rounded-t-2xl">
                      <motion.img
                        className="w-full h-[260px] object-cover rounded-t-2xl transition-transform transform group-hover:scale-110 duration-500 border-b border-primary/40"
                        src={`${data.main_image_url}`}
                        alt={data?.title}
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                    </div>

                    <div className="p-6 flex flex-col gap-4">
                      <h3 className="text-xl font-bold text-primary break-words max-w-sm transition-colors group-hover:text-secondary">
                        {data?.title}
                      </h3>

                      <p className="text-primary/70 line-clamp-2 text-sm">
                        {data?.brief}
                      </p>

                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                      >
                        <button
                          onClick={() =>
                            navigate(`/our-work/${data?.id}/${data?.title}`)
                          }
                          className="px-5 py-2 text-sm font-semibold text-white bg-secondary rounded-full shadow-md group-hover:bg-primary/90 focus:ring-2 focus:ring-primary focus:outline-none transition"
                        >
                          View More
                        </button>
                      </motion.div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>

            {visibleWorks < works.length && (
              <motion.button
                onClick={() => setVisibleWorks((prev) => prev + 2)}
                className="mt-8 px-6 py-3 text-lg font-semibold text-white bg-primary rounded-full shadow-md hover:bg-primary/90 focus:ring-2 focus:ring-primary focus:outline-none transition"
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                View More
              </motion.button>
            )}
          </section>
        </>
      )}
    </MainLayout>
  );
}

export default OurWorkUser;
