import { useNavigate } from "react-router-dom";
import Grid from "../Grid/grid";
import { motion } from "framer-motion";
import IconButton from "../input/icon-button";
import { ArrowRight } from "lucide-react";

function CareerHeadSection({ careersData }: { careersData: any }) {
  const navigate = useNavigate();
  return (
    <section className="relative flex flex-col items-center justify-center min-h-screen px-4 sm:px-6 md:px-10 lg:px-16 py-10 overflow-hidden">
      <Grid size={100} direction="top-center" />
      <div className="absolute -top-52 -left-52 rounded-full size-[450px] md:size-[600px] bg-gradient-to-tl from-white/80 to-secondary/60 blur-xl -z-10" />
      <div className="absolute -bottom-52 -right-52 rounded-full size-[450px] md:size-[600px] bg-gradient-to-tr from-white/80 to-primary/60 blur-xl -z-10" />
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="text-center"
      >
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="text-4xl sm:text-5xl md:text-6xl lg:text-8xl font-semibold"
        >
          {careersData.title}
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="mt-4 text-base sm:text-lg text-gray-600 max-w-2xl sm:max-w-3xl md:max-w-4xl mx-auto"
        >
          {careersData.intro}
        </motion.p>
      </motion.div>
      <div className="mt-12">
        <IconButton
          icon={<ArrowRight />}
          title="View Job Openings"
          onClick={() => navigate(`/job-openings`)}
        />
      </div>
    </section>
  );
}

export default CareerHeadSection;
