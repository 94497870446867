import { motion } from "framer-motion";
import Grid from "../Grid/grid";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

function HeadSection({ work }: { work: any }) {
  const navigate = useNavigate();
  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen px-4 sm:px-6 md:px-10 lg:px-16 py-10">
      <Grid size={100} direction="full" />

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        className="w-full flex flex-col items-start gap-6 px-6 md:px-20 py-12 mt-16"
      >
        {" "}
        <div className="relative z-10 flex flex-col items-center justify-center text-center w-full my-20">
          <motion.h2
            className="text-4xl md:text-6xl font-bold text-black capitalize"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            Case Study
          </motion.h2>
          <motion.p
            className="mt-4 text-lg text-gray-600 max-w-2xl"
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.3, ease: "easeOut" }}
          >
            Explore our in-depth case study on successful business
            transformations, innovative solutions, and real-world
            problem-solving strategies.
          </motion.p>
        </div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.6, ease: "easeOut" }}
          animate={{ y: [0, -5, 0] }}
          className="flex flex-wrap gap-4"
        >
          <button
            onClick={() => navigate(-1)}
            className="outline-none border-2 border-black bg-white flex items-center justify-center gap-3 p-2 font-bold hover:bg-black hover:text-white ease-in duration-300 rounded-full"
          >
            <ChevronLeft /> Back
          </button>
          <h2 className="text-4xl md:text-5xl font-semibold text-gray-900 leading-tight">
            {work?.title}
          </h2>
        </motion.div>
        <motion.div
          className="flex flex-col gap-4"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.6, ease: "easeOut" }}
          animate={{ y: [0, -5, 0] }}
        >
          <h3 className="font-semibold text-gray-700">Overview</h3>
          <p className="text-sm sm:text-base md:text-lg lg:text-xl text-gray-800 leading-relaxed">
            {work?.description}
          </p>
        </motion.div>
        <motion.div
          className="flex flex-col gap-4"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.6, ease: "easeOut" }}
          animate={{ y: [0, -5, 0] }}
        >
          <h3 className="font-semibold text-gray-700">Problem Statement</h3>
          <p className="text-sm sm:text-base md:text-lg lg:text-xl text-gray-800 leading-relaxed">
            {work?.problem_statement}
          </p>
        </motion.div>
      </motion.div>

      <div className="mt-8 w-full px-4 sm:px-6 md:px-12 lg:px-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col p-4 sm:p-6">
            <motion.h2
              className="text-xl font-medium"
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              Challenges
            </motion.h2>
            <div className="flex flex-col gap-3 mt-5">
              {work?.challenges?.map((work: string, index: number) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{
                    delay: index * 0.1,
                    duration: 0.4,
                    ease: "easeOut",
                  }}
                  className="text-sm sm:text-base md:text-lg flex flex-row items-start gap-2"
                >
                 <ChevronRight className="size-4 min-w-4 mt-1" />
                  {work}
                </motion.div>
              ))}
            </div>
          </div>

          <div className="gap-5 flex flex-col items-start justify-start p-6">
            <motion.h2
              className="text-xl font-medium"
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              Solutions
            </motion.h2>
            <div className="flex flex-col gap-3 mt-5">
              {work?.solutions?.map((work: string, index: number) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{
                    delay: index * 0.1,
                    duration: 0.4,
                    ease: "easeOut",
                  }}
                  className="text-sm sm:text-base md:text-lg flex flex-row items-start gap-2 text-balance"
                >
                 <ChevronRight className="size-4 min-w-4 mt-1" />
                  {work}
                </motion.div>
              ))}
            </div>
          </div>
        </div>
        <div className="gap-5 flex flex-col items-start justify-start p-6 w-full">
          <motion.h2
            className="text-xl font-medium"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, ease: "easeOut" }}
          >
            Approach
          </motion.h2>
          <div className="flex flex-col gap-3 mt-5">
            {work?.approach?.map((work: string, index: number) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  delay: index * 0.1,
                  duration: 0.4,
                  ease: "easeOut",
                }}
                className="text-sm sm:text-base md:text-lg flex flex-row items-start gap-2"
              >
                <ChevronRight className="size-4 min-w-4 mt-1" />
                <span className="leading-tight">{work}</span>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeadSection;
