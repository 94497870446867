import Grid from "../Grid/grid";
import Title from "../title";
import whyChooseUsForUIUX from "./data/ui-ux-why-choose-us-data";

function WhyChooseUiUx() {
  return (
    <section className="w-full h-auto relative flex flex-col overflow-hidden p-2 lg:p-16">
      <Grid size={100} direction="top-center" />
      <div className="z-10 flex justify-start items-start mb-12 w-full">
        <Title title="Why Softcoding Solutions for UI/UX Design" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 z-10">
        {whyChooseUsForUIUX.map(({ id, title, description, icon }) => (
          <div
            key={id}
            className="flex items-start gap-4 p-6 bg-white rounded-2xl shadow-lg transition-all duration-300 hover:shadow-xl border last:md:col-span-2"
          >
            <div className="p-3 bg-primary/10 rounded-full text-primary">
              {icon}
            </div>
            <div>
              <h3 className="text-lg font-semibold">{title}</h3>
              <p className="text-gray-600 text-sm">{description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default WhyChooseUiUx;
