import React from "react";

function Button({
  text,
  type,
  onClick,
  disabled,
}: {
  text: string;
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
  disabled?: boolean;
}) {
  return (
    <button
      onClick={onClick}
      type={type}
      className="p-3 capitalize text-base border-2 bg-white border-black shadow-[4px_4px_0_#000] hover:shadow-[6px_6px_0_#000] outline-none transition-all duration-250"
    >
      {text}
    </button>
  );
}

export default Button;
