import logo1 from "../../../Assets/client-logo/logo1.png";
import logo2 from "../../../Assets/client-logo/logo2.png";
import logo3 from "../../../Assets/client-logo/logo3.png";
import logo4 from "../../../Assets/client-logo/logo4.png";
import logo5 from "../../../Assets/client-logo/logo5.png";
import logo6 from "../../../Assets/client-logo/logo6.png";
import logo7 from "../../../Assets/client-logo/logo7.png";
import logo8 from "../../../Assets/client-logo/logo8.png";
import logo9 from "../../../Assets/client-logo/logo9.png";
import logo10 from "../../../Assets/client-logo/logo10.png";

const clientLogos = [
  { id: 1, img: logo1 },
  { id: 2, img: logo2 },
  { id: 3, img: logo3 },
  {
    id: 4,
    img: logo4,
  },
  {
    id: 5,
    img: logo5,
  },
  {
    id: 6,
    img: logo6,
  },
  {
    id: 7,
    img: logo7,
  },
  {
    id: 8,
    img: logo8,
  },
  {
    id: 9,
    img: logo9,
  },
  {
    id: 10,
    img: logo10,
  },
];

export default clientLogos;
