import { Form, Formik } from "formik";
import { Plus, X } from "lucide-react";
import File from "../../input/File";
import IconButton from "../../input/icon-button";

function AddClientLogoForm({
  onClose,
  onSubmit,
}: {
  onClose: () => void;
  onSubmit: (values: any) => void;
}) {
  return (
    <div className="inset-0 bg-black bg-opacity-50 h-screen fixed flex items-center justify-center overflow-y-auto z-50">
      <div className="flex items-center justify-center my-auto w-full overflow-y-auto py-5 px-5">
        <div className="bg-white rounded-3xl shadow-md w-full max-w-2xl my-auto mx-auto md:h-auto h-full overflow-y-auto">
          <div className="flex flex-col items-center justify-center w-full">
            <div className="flex items-center justify-between w-full border-b p-4">
              <h2 className="text-xl font-normal text-gray-800">
                Add Client Logos
              </h2>
              <button
                onClick={onClose}
                className="outline-none bg-red-100 border border-red-400 text-red-400 p-2 rounded-full duration-300 ease-in transition-all hover:bg-red-400 hover:text-white"
              >
                <X />
              </button>
            </div>
          </div>
          <div className="p-4 w-full">
            <Formik
              initialValues={{
                logos: [],
              }}
              onSubmit={onSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form className="space-y-4">
                  <File
                    name="logos"
                    multiple={true}
                    onChange={(event: any) => {
                      const files = Array.from(event.currentTarget.files);
                      setFieldValue("logos", files);
                    }}
                    accept="image/*"
                    label={"Logos"}
                  />
                  <div className="flex items-start justify-start">
                    <IconButton icon={<Plus />} title="Add" type="submit" />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddClientLogoForm;
