import React, { useEffect, useState } from "react";
import AdminLayout from "../../../layout/admin-layout";
import Header from "../../../Components/client-admin/admin-client-header";
import AddClientLogoForm from "../../../Components/client-admin/add-form/add-client-logo-form";
import { toast } from "sonner";
import axios from "axios";
import IconButton from "../../../Components/input/icon-button";
import { Pencil, Trash } from "lucide-react";
import usePagination from "../../../Components/Pagination/use-pagination";
import Pagination from "../../../Components/Pagination/pagination";
import { useUser } from "../../../Components/provider/user-id-provider";

function AdminClient() {
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [logos, setLogos] = useState([]);
  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(logos, 16);
   const {userId} = useUser();

  const fetchLogos = () => {
    axios
      .get(`users/${userId}/client_logos`)
      .then((res) => {
        const mappedLogos = res.data.client_logos.flatMap(
          (item: { client_logos: any[]; id: any }) =>
            item.client_logos.map((logo) => ({
              id: item.id,
              img_id: logo.id,
              img_url: logo.img_url,
            }))
        );

        setLogos(mappedLogos);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteLogos = (id: number, img_id: number) => {
    const loader = toast.loading("Deleting Logo ....");
    axios
      .delete(`users/${userId}/client_logos/${id}/delete_image/${img_id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loader);
          toast.success("Logo Deleted Successfully");
          fetchLogos();
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.response.data.message || err.message);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchLogos();
  }, []);

  const addClientLogo = (values: any) => {
    const loader = toast.loading("Adding Logos ....");
    const formData = new FormData();

    values.logos.forEach((data: any, index: any) => {
      formData.append("client_logo[client_logos][]", data);
    });

    axios
      .post(`users/${userId}/client_logos`, formData)
      .then((res) => {
        if (res.status === 201) {
          toast.dismiss(loader);
          toast.success("Client logos added successfully!");
          setView(!view);
          fetchLogos();
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.response.data.message || err.message);
        console.log(err);
      });
  };

  return (
    <AdminLayout>
      <Header setView={setView} />
      <div className="border rounded-2xl m-3 p-5 space-y-5">
        <div className="flex justify-end items-end">
          <IconButton
            icon={<Trash size={20} />}
            title="Remove Logos"
            onClick={() => setEdit(!edit)}
            bgColor="bg-red-400"
          />
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 gap-4">
          {currentItems.map((data: any, index) => (
            <div
              className="w-full aspect-square rounded-xl border p-2 cursor-pointer relative"
              key={index}
            >
              <img
                src={`${data.img_url}`}
                className="w-full h-full object-contain rounded-lg"
                alt={`Logo ${index}`}
              />

              {edit && (
                <button
                  onClick={() => deleteLogos(data.id, data.img_id)}
                  className="text-white bg-red-400 p-2 rounded-full top-2 right-2 absolute outline-none"
                >
                  <Trash size={16} />
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="flex items-start justify-start">
          <Pagination
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages}
          />
        </div>
      </div>

      {view && (
        <AddClientLogoForm
          onClose={() => setView(false)}
          onSubmit={addClientLogo}
        />
      )}
    </AdminLayout>
  );
}

export default AdminClient;
