import React from "react";
import { Navigate } from "react-router-dom";
import SecureLS from "secure-ls";

function PrivateRoute({
  children,
  allowedRole,
}: {
  children: any;
  allowedRole: string[];
}) {
  const ls = new SecureLS();
  const isAuthenticated = ls.get("isAuthenticated");
  const role = ls.get("role");

  if (!isAuthenticated) {
    return <Navigate to="/" replace={true} />;
  }
  if(allowedRole.includes(role)){
    return children;
  }else{
    return <Navigate to="/" replace={true} />;
  }
}

export default PrivateRoute;
