import { createContext, useContext, useEffect, useState } from "react";
import SecureLS from "secure-ls";

const ls = new SecureLS();

const UserContext = createContext<{ userId: string | null; setUserId: (id: string | null) => void } | null>(null);

export const UserProvider = ({ children }: { children: any }) => {
  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    const storedUserId = ls.get("user_id", true);
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);

  const updateUser = (id: string | null) => {
    if (id) {
      ls.set("user_id", id);
    } else {
      ls.remove("user_id");
    }
    setUserId(id);
  };

  return (
    <UserContext.Provider value={{ userId, setUserId: updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
