import CsdBenefits from "../Components/CSD/csd-benefits";
import CsdContact from "../Components/CSD/csd-contact";
import CsdHeadSection from "../Components/CSD/csd-head-section";
import CsdProcess from "../Components/CSD/csd-process";
import WhyChooseCsd from "../Components/CSD/why-choose-csd";
import MainLayout from "../layout/main-layout";

function CustomSoftwareDevelopment() {
  return (
    <div>
      <MainLayout>
        <CsdHeadSection />
        <CsdBenefits />
        <CsdProcess />
        <WhyChooseCsd />
        <CsdContact />
      </MainLayout>
    </div>
  );
}

export default CustomSoftwareDevelopment;
