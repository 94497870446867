import { ErrorMessage, FieldArray, Form, Formik } from "formik";

import Input from "../../input/Input";
import Select from "../../input/Select";
import IconButton from "../../input/icon-button";
import { ArrowRight, Plus, Trash } from "lucide-react";
import { validationSchema } from "../validation/validation-schema";
import { jobType } from "../add-form/add-job-opening-form";

function UpdateJobForm({
  onSubmit,
  job,
}: {
  onSubmit: (values: any) => void;
  job: any;
}) {
  return (
    <div className="w-full p-5">
      <Formik
        initialValues={{
          job_title: job.job_title || "",
          job_position: job.job_position || "",
          no_of_opening: job.no_of_opening || "",
          location: job.location || "",
          job_type: job.job_type || "",
          qualification: job.qualification || "",
          what_we_offer: job.what_we_offer || [],
          key_responsibilities: job.key_responsibilities || [],
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, errors }) => (
          <Form className="space-y-6">
            <div className="grid md:grid-cols-2 gap-4">
              <Input
                label={"Job Title"}
                name="job_title"
                placeholder="Job Title"
                type="text"
              />
              <Input
                label={"Job Position"}
                name="job_position"
                placeholder="Job Position"
                type="text"
              />
            </div>
            <div className="grid md:grid-cols-3 gap-4">
              <Input
                name="no_of_opening"
                min={1}
                type="number"
                label={"No of Opening"}
                placeholder="3"
              />
              <Input
                name="location"
                type="text"
                placeholder="Location"
                label={"Location"}
              />
              <Select
                name="job_type"
                data={jobType}
                value="name"
                labelProperty="name"
                selectedOption="Job Type"
                label={"Job Type"}
              />
            </div>

            <Input
              label={"Qualification"}
              name="qualification"
              placeholder="Qualification"
              type="text"
            />
            <div className="grid lg:grid-cols-2 gap-4">
              <div>
                <FieldArray name="what_we_offer">
                  {({ push, remove }) => (
                    <div className="w-full border p-3 rounded-xl shadow-sm">
                      <div className="flex items-center justify-between">
                        <h2 className="text-xl font-semibold">What We Offer</h2>
                        <IconButton
                          type="button"
                          icon={<Plus />}
                          rounded="rounded-xl"
                          onClick={() => push("")}
                        />
                      </div>
                      <div className="flex flex-col items-center justify-center mt-5 w-full">
                        {values.what_we_offer.map((_: any, index: any) => (
                          <div
                            key={index}
                            className="flex items-center gap-2 w-full"
                          >
                            <Input
                              name={`what_we_offer.${index}`}
                              type="text"
                              className="w-full"
                              placeholder="what we offer"
                              label={"What We Offer"}
                            />
                            <IconButton
                              icon={<Trash />}
                              bgColor="bg-red-600"
                              rounded="rounded-xl"
                              onClick={() => remove(index)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </FieldArray>
                <ErrorMessage
                  component={"div"}
                  name={"what_we_offer"}
                  className="text-sm text-red-600 mt-1"
                />
              </div>
              <div>
                <FieldArray name="key_responsibilities">
                  {({ push, remove }) => (
                    <div className="w-full border p-3 rounded-xl shadow-sm">
                      <div className="flex items-center justify-between">
                        <h2 className="text-xl font-semibold">
                          Key Responsiblities
                        </h2>
                        <IconButton
                          type="button"
                          icon={<Plus />}
                          rounded="rounded-xl"
                          onClick={() => push("")}
                        />
                      </div>
                      <div className="flex flex-col items-center justify-center mt-5 w-full">
                        {values.key_responsibilities.map(
                          (_: any, index: number) => (
                            <div
                              key={index}
                              className="flex items-center gap-2 w-full"
                            >
                              <Input
                                name={`key_responsibilities.${index}`}
                                type="text"
                                className="w-full"
                                placeholder="Key responsiblities"
                                label={"Key Responsiblities"}
                              />
                              <IconButton
                                icon={<Trash />}
                                bgColor="bg-red-600"
                                rounded="rounded-xl"
                                onClick={() => remove(index)}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </FieldArray>
                <ErrorMessage
                  component={"div"}
                  name={"key_responsibilities"}
                  className="text-sm text-red-600 mt-1"
                />
              </div>
            </div>
            <div className="flex items-start justify-start">
              <IconButton icon={<ArrowRight />} title="Update Job" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default UpdateJobForm;
