import { motion } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";

function ServiceList({
  service,
  index,
  active,
  setActive,
}: {
  service: Object | any;
  index: number;
  active: number;
  setActive: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const { currentTarget, clientX, clientY } = event;
    const { left, top } = currentTarget.getBoundingClientRect();
    setMousePosition({ x: clientX - left, y: clientY - top });
  };

  return (
    <Link to={service.path}>
      <motion.div
        key={service.id}
        initial={{ opacity: 0, x: 50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: index * 0.2 }}
        className={`flex flex-row gap-6 group justify-start items-center cursor-pointer relative ease-in duration-300 `}
        onMouseEnter={() => {
          setHoveredIndex(index);
          setActive(index);
        }}
        onMouseLeave={() => setHoveredIndex(null)}
        onMouseMove={handleMouseMove}
      >
        {hoveredIndex === index && (
          <motion.div
            className="absolute w-32 h-32 bg-gradient-to-b from-primary to-secondary backdrop-invert rounded-full pointer-events-none"
            animate={{
              x: mousePosition.x - 48,
              y: mousePosition.y - 48,
            }}
            transition={{
              type: "spring",
              damping: 25,
              stiffness: 700,
              duration: 0.6,
            }}
          />
        )}
        <span
          className={`${
            active === index ? "text-primary/60" : "text-gray-300"
          } text-5xl font-bold group-hover:text-primary/60 transition-colors duration-300 relative z-10`}
        >
          0{service.id}
        </span>
        <div className="relative z-10">
          <h3 className="text-3xl md:text-4xl font-normal text-gray-900 mb-2">
            {service.title}
          </h3>
        </div>
      </motion.div>
    </Link>
  );
}

export default ServiceList;
