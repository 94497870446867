import { Eye, Pencil, Trash } from "lucide-react";
import { useNavigate } from "react-router-dom";
import usePagination from "../../Pagination/use-pagination";
import Pagination from "../../Pagination/pagination";

function WorkTable({
  data,
  view,
  onView,
  onDeleteView,
  selectedWork,
}: {
  data: any[];
  view: "list" | "detailed";
  onView: (data: any) => void;
  onDeleteView: (data: any) => void;
  selectedWork: any;
}) {
  const navigate = useNavigate();
  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(data, 10);
  return (
    <div className="rounded-2xl shadow-md border mt-3 w-full">
      <div className="w-full overflow-x-auto">
        <table className="w-full rounded-2xl">
          <thead className="border-b uppercase">
            <tr>
              <th className="py-3 px-2 font-medium text-xs md:text-sm text-center">
                Sr.no
              </th>
              <th className="py-3 px-2 font-medium text-xs md:text-sm text-left">
                Title
              </th>
              {view === "list" && (
                <>
                  <th className="py-3 px-2 font-medium text-xs md:text-sm text-left">
                    Brief
                  </th>
                  <th className="py-3 px-2 font-medium text-xs md:text-sm text-left">
                    Frontend Tech
                  </th>
                  <th className="py-3 px-2 font-medium text-xs md:text-sm text-left">
                    Backend Tech
                  </th>
                  <th className="py-3 px-2 font-medium text-xs md:text-sm text-left">
                    Database Tech
                  </th>
                  <th className="py-3 px-2 font-medium text-xs md:text-sm text-left">
                    Action
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {currentItems.map((work, index) => (
              <tr key={index} className={`cursor-pointer bg-gray-50`}>
                <td className="py-3 px-2 text-sm text-center">{index + 1}</td>
                <td className="py-3 px-2 text-sm">{work.title}</td>
                {view === "list" && (
                  <>
                    <td className="py-3 px-2 text-sm">
                      <p className="max-w-sm line-clamp-3">{work.brief}</p>
                    </td>
                    <td className="py-3 px-2 text-sm">{work.frontend_tech}</td>
                    <td className="py-3 px-2 text-sm">{work.backend_tech}</td>
                    <td className="py-3 px-2 text-sm">{work.database_tech}</td>
                    <td className="py-3 px-2 text-sm">
                      <div className="flex gap-2">
                        <button
                          onClick={() => onView(work)}
                          className="border-2 border-blue-200 text-blue-400 p-2 rounded-full outline-none"
                        >
                          <Eye />
                        </button>
                        <button
                          onClick={() =>
                            navigate(
                              `/admin/our-work/update-work/${work.id}/${work.title}`
                            )
                          }
                          className="border-2 border-green-200 text-green-400 p-2 rounded-full outline-none"
                        >
                          <Pencil />
                        </button>
                        <button
                          onClick={() => onDeleteView(work)}
                          className="border-2 border-red-200 text-red-400 p-2 rounded-full outline-none"
                        >
                          <Trash />
                        </button>
                      </div>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className=" p-3">
        <Pagination
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
}

export default WorkTable;
