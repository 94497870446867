import { motion } from "framer-motion";
import Grid from "../Grid/grid";
import Title from "../title";

function KeyFeatures({ product }: { product: any }) {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10,
      },
    },
  };
  return (
    <div className="relative flex flex-col min-h-screen px-4 sm:px-6 md:px-10 lg:px-16 py-10">
      <Grid direction="top-center" size={100} />
      <div className="z-10 flex justify-start items-start mb-8 sm:mb-10 md:mb-12 w-full">
        <Title title="Key Features" />
      </div>
      <motion.div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
      >
        {product?.key_features?.map((item: any, index: number) => (
          <motion.div
            key={index}
            variants={itemVariants}
            whileHover={{ scale: 1.03 }}
            className="bg-white p-4 sm:p-6 rounded-lg shadow-sm border border-gray-200 transition-all duration-300 hover:shadow-md"
          >
            <h3 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-3">
              {item.feature}
            </h3>
            <p className="text-sm sm:text-base text-gray-600">
              {item.description}
            </p>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}

export default KeyFeatures;
