import {
  Users,
  Settings,
  Layers,
  Smile,
  RefreshCw,
  ShieldCheck,
  TrendingUp,
} from "lucide-react";

const whyChooseUsMad = [
  {
    id: 1,
    title: "Expert Developers",
    icon: <Users />,
    description:
      "Our team of skilled mobile developers has experience with both Flutter and React Native, ensuring your app is built with the latest technologies and best practices.",
  },
  {
    id: 2,
    title: "Tailored Solutions",
    icon: <Settings />,
    description:
      "We deliver custom mobile app solutions that are perfectly aligned with your business needs, offering a personalized approach to development.",
  },
  {
    id: 3,
    title: "End-to-End Services",
    icon: <Layers />,
    description:
      "From ideation and design to development and maintenance, we provide comprehensive mobile app development services to meet all your requirements.",
  },
  {
    id: 4,
    title: "Focus on User Experience",
    icon: <Smile />,
    description:
      "We create apps that are not only functional but also engaging and easy to use, ensuring a seamless experience for your users.",
  },
  {
    id: 5,
    title: "Agile Development",
    icon: <RefreshCw />,
    description:
      "We follow an agile methodology, ensuring flexibility and iterative improvements throughout the project.",
  },
  {
    id: 6,
    title: "Robust Security",
    icon: <ShieldCheck />,
    description:
      "Security is a top priority. We implement strong encryption, authentication, and compliance measures to protect your app and user data.",
  },
  {
    id: 7,
    title: "Scalability & Performance",
    icon: <TrendingUp />,
    description:
      "Our apps are built to scale, ensuring high performance and smooth operation even as your user base grows.",
  },
];

export default whyChooseUsMad;
