import Grid from "../Grid/grid";
import Title from "../title";
import { motion } from "framer-motion";

function OurValue({ careersData }: { careersData: any }) {
  return (
    <section className="w-full min-h-screen overflow-hidden relative flex flex-col px-4 sm:px-6 md:px-10 lg:px-16 py-12 lg:py-16">
      <Grid direction="top-right" size={100} />

      <div className="z-10 flex flex-col justify-center items-start mb-8 sm:mb-12">
        <Title title="Our Core Values" />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 md:gap-8">
        {careersData.values.map((value: any, index: number) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className="w-full p-6 md:p-8 flex flex-col gap-4"
          >
            <div className="flex gap-4 items-center">
              <span className="bg-primary/10 text-primary p-3 rounded-full text-xl">
                {value.icon}
              </span>
              <h2 className="text-2xl sm:text-3xl font-semibold">
                {value.title}
              </h2>
            </div>

            <p className="text-base sm:text-lg text-gray-700 font-medium leading-relaxed">
              {value.description}
            </p>
          </motion.div>
        ))}
      </div>
    </section>
  );
}

export default OurValue;
