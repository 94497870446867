import Grid from "../Grid/grid";
import Title from "../title";
import crossDevelopmentData from "./data/mad-cross-development-data";

function MadCrossDevelopment() {
  return (
    <section className="w-full min-h-screen h-full relative flex flex-col overflow-hidden p-2 lg:p-16">
      <Grid size={100} direction="top-center" />
      <div className="z-10 flex justify-start items-start mb-12 w-full">
        <Title title="Why Choose Cross-Platform Development" />
      </div>
      <div className="flex items-center justify-between flex-col gap-5">
        <h2 className="md:text-4xl text-xl font-medium md:font-light max-w-4xl text-center">
          We build cross-platform apps for iOS and Android with a single
          codebase.
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 z-10 my-auto md:py-32">
          {crossDevelopmentData.map(({ id, title, description, icon }) => (
            <div
              key={id}
              className="flex items-start gap-4 p-6 bg-white rounded-2xl shadow-lg transition-all duration-300 hover:shadow-xl border"
            >
              <div className="p-3 bg-primary/10 rounded-full text-primary">
                {icon}
              </div>
              <div>
                <h3 className="text-lg font-semibold">{title}</h3>
                <p className="text-gray-600 text-sm">{description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default MadCrossDevelopment;
