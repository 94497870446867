import { motion } from "framer-motion";
import domainData from "./data/domain-data";
import Grid from "../Grid/grid";
import Title from "../title";

function DomainSection() {
  const imgWidth = 400;
  const totalWidth = domainData.length * imgWidth;
  return (
    <div className="w-full h-full overflow-hidden relative flex flex-col lg:p-16 p-8">
      <div className="z-10 flex flex-col justify-center items-start">
        <Title title="Industries we serve" />
      </div>
      <div>
        <motion.div
          className="flex gap-10 p-10 px-20 w-max h-full"
          animate={{
            x: [-totalWidth,0],
          }}
          transition={{
            x: {
              repeat: Number.POSITIVE_INFINITY,
              repeatType: "loop",
              duration: 20,
              ease: "linear",
            },
          }}
        >
          {[...domainData, ...domainData].map((project) => (
            <motion.div
              key={project.id}
              className={`relative overflow-hidden rounded-3xl bg-black/40 h-96 w-96 group cursor-pointer`}
              whileHover={{ scale: 0.98 }}
              transition={{ duration: 0.3 }}
            >
              <div
                className="absolute inset-0 bg-cover bg-center group-hover:scale-110 ease-in duration-300"
                style={{
                  backgroundImage: `url(${project.image})`,
                  width: `${imgWidth}px`,
                  flexShrink: 0,
                }}
              />
              <div
                className={`absolute inset-0 bg-gradient-to-t ${project.gradient}`}
              />
              <div className="absolute inset-0 p-8 flex flex-col justify-between">
                <div>
                  <h3 className="text-white text-3xl font-semibold mb-2">
                    {project.title}
                  </h3>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
      <Grid direction="top-left" size={100} />
    </div>
  );
}

export default DomainSection;
