import { useEffect } from "react";
import EditorJS, { ToolConstructable } from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Quote from "@editorjs/quote";
import Table from "@editorjs/table";
import CodeTool from "@editorjs/code";
import CustomImageTool from "../../tools/custom-image-tool";

function ViewEditor({ data, editorRef }: { data: any; editorRef: any }) {
  useEffect(() => {
    if (!editorRef.current) {
      editorRef.current = new EditorJS({
        holder: "editorjs",
        autofocus: true,
        readOnly: true,
        placeholder: "Select option by typing / or start writing blog...",
        tools: {
          header: {
            class: Header as unknown as ToolConstructable,
            inlineToolbar: true,
            config: {
              placeholder: "Enter a heading",
              levels: [1, 2, 3, 4, 5, 6],
              defaultLevel: 2,
            },
          },
          list: {
            class: List as unknown as ToolConstructable,
            inlineToolbar: true,
          },
          quote: {
            class: Quote as unknown as ToolConstructable,
            inlineToolbar: true,
          },
          code: {
            class: CodeTool as unknown as ToolConstructable,
            inlineToolbar: true,
            config: {
              placeholder: "Write your code.",
            },
          },
          table: {
            class: Table as unknown as ToolConstructable,
            inlineToolbar: true,
            config: {
              rows: 2,
              cols: 3,
              maxRows: 5,
              maxCols: 5,
            },
          },
          simpleImage: {
            class: CustomImageTool as unknown as ToolConstructable,
            inlineToolbar: false,
          },
        },
        data: data && Array.isArray(data) ? { blocks: data } : { blocks: [] },
      });
    }

    return () => {
      if (editorRef.current?.destroy) {
        editorRef.current.destroy();
      }
      editorRef.current = null;
    };
  }, [data]);
  return (
    <div
      id="editorjs"
      className="rounded-xl border p-3 w-full min-h-[200px]"
    ></div>
  );
}

export default ViewEditor;
