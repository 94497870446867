import Grid from "../Grid/grid";
import Title from "../title";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-device-frameset/styles/marvel-devices.min.css";
import { ChevronLeft, ChevronRight } from "lucide-react";

function ImgSection({ work }: { work: any }) {
  if (!work?.main_image_url) return null;
  const Images = [work?.main_image_url, ...work?.other_images_urls];

  return (
    <section className="relative flex flex-col min-h-screen px-4 sm:px-6 md:px-10 lg:px-16 py-10 overflow-hidden">
      <Grid size={100} direction="top-center" />
      <div className="z-10 flex justify-start items-start mb-12 w-full">
        <Title title="Images" />
      </div>

      <div className="w-full flex items-center justify-center relative">
        <Swiper
          pagination={{ clickable: true }}
          navigation={{
            nextEl: ".custom-swiper-next",
            prevEl: ".custom-swiper-prev",
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[Pagination, Navigation, Autoplay]}
          className="relative w-full max-w-6xl"
        >
          {Images.map((data: string, index: number) => (
            <SwiperSlide
              key={index}
              className=" flex items-center justify-center bg-white"
            >
              <img
                className="w-full max-w-6xl h-[500px] md:h-[400px] object-contain rounded-xl border shadow-md"
                src={data}
                alt={`Project Image ${index + 1}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <button className="custom-swiper-prev absolute left-4 top-1/2 transform -translate-y-1/2 bg-primary/80 hover:bg-primary text-white p-3 rounded-full shadow-lg transition-all z-20">
          <ChevronLeft className="w-6 h-6" />
        </button>

        <button className="custom-swiper-next absolute right-4 top-1/2 transform -translate-y-1/2 bg-primary/80 hover:bg-primary text-white p-3 rounded-full shadow-lg transition-all z-20">
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>
    </section>
  );
}

export default ImgSection;
