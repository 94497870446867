import { Clipboard, Clock, Users } from "lucide-react";
import HeadSection from "../Components/hire-a-developer/head-section-hire-a-developer";
import HiringModal from "../Components/hire-a-developer/hiring-modal";
import MainLayout from "../layout/main-layout";
import WhichModel from "../Components/hire-a-developer/which-model";
import WhyUs from "../Components/hire-a-developer/why-us";
import CTASection from "../Components/common-section/cta-section";
import TechStack from "../Components/common-section/tech-stack";

function HireADeveloper() {
  const hiringModels = [
    {
      title: "Fixed Cost Project Model",
      description: "Predictable Budget, Defined Scope",
      icon: <Clipboard />,
      benefits: [
        "Predictable Budget",
        "Clear Scope & Deliverables",
        "Timeline Certainty",
        "Minimal Management Overhead",
      ],
      idealFor: [
        "Projects with clear, well-defined requirements",
        "Small to medium-sized applications or feature development",
        "Projects that require strict timelines and budget constraints",
      ],
      color: "text-violet-600",
      accentColor: "border-violet-200 bg-violet-50",
      iconBg: "bg-violet-100",
    },
    {
      title: "Time & Material Model",
      description: "Flexible & Scalable Solutions",
      icon: <Clock />,
      benefits: [
        "Flexibility to Adjust Scope",
        "Pay for What You Use",
        "Scalability",
        "Faster Iteration",
      ],
      idealFor: [
        "Projects with evolving or unclear requirements",
        "Long-term development or maintenance projects",
        "Agile development and continuous improvement",
      ],
      color: "text-blue-600",
      accentColor: "border-blue-200 bg-blue-50",
      iconBg: "bg-blue-100",
    },
    {
      title: "Staff Augmentation Model",
      description: "Augment Your Team with Specialized Skills",
      icon: <Users />,
      benefits: [
        "Access to Specialized Talent",
        "Flexible & Scalable",
        "Seamless Integration",
        "Focus on Core Tasks",
      ],
      idealFor: [
        "Projects that need additional resources for specific expertise",
        "Short-term or long-term staffing needs without full-time commitment",
        "Teams requiring extra capacity during peak workloads",
      ],
      color: "text-emerald-600",
      accentColor: "border-emerald-200 bg-emerald-50",
      iconBg: "bg-emerald-100",
    },
  ];
  return (
    <MainLayout>
      <HeadSection />
      <HiringModal hiringModels={hiringModels} />
      <WhichModel />
      <WhyUs />
      <TechStack />
      <CTASection />
    </MainLayout>
  );
}

export default HireADeveloper;
