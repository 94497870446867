import { ErrorMessage, Field } from "formik";
import React from "react";
export interface InputInterface {
  name: string;
  data: any[];
  label?: String;
  className?: string;
  value?: string;
  labelProperty?: string;
  selectedOption?: string;
}
function Select({
  name,
  data,
  label,
  className,
  value = "name",
  labelProperty = "name",
  selectedOption,
}: InputInterface) {
  return (
    <div className="mb-4 w-full">
      <label
        htmlFor={name}
        className="block text-sm text-gray-950 mb-1 capitalize"
      >
        {label}
      </label>
      <Field
        as="select"
        name={name}
        className={`block w-full px-3 py-2 mt-2 text-gray-700 placeholder-gray-400 placeholder:capitalize bg-white border border-gray-200 rounded-md  focus:border-primary  focus:ring-primary focus:outline-none focus:ring focus:ring-opacity-40 ${className}`}
      >
        <option selected value="" disabled className="capitalize">
          {selectedOption}
        </option>
        {data?.map((option: any, index: number) => (
          <option key={index} value={option[value]}>
            {option[labelProperty]}
          </option>
        ))}
      </Field>
      <ErrorMessage
        component={"div"}
        name={name}
        className="text-sm text-red-600 mt-1"
      />
    </div>
  );
}

export default Select;
