import { Brush, ClipboardList, Code, Rocket, ShieldCheck } from "lucide-react";

const webProcess = [
  {
    step: 1,
    title: "Define Your Project Scope",
    description:
      "The foundation of a successful website starts with a clear vision. Identify your goals, key features, and target audience. Understanding your project scope helps streamline development and ensures your website meets business and user expectations.",
    icon: <ClipboardList />,
  },
  {
    step: 2,
    title: "Choose the Right Tech Stack",
    description:
      "Selecting the right technologies is crucial for performance and scalability. Whether it's React, Next.js, WordPress, or a custom CMS, we ensure your website is built using the most suitable frameworks, ensuring speed, security, and future growth.",
    icon: <Code />,
  },
  {
    step: 3,
    title: "Design & Develop",
    description:
      "Bringing ideas to life through stunning UI/UX design and powerful development. We create intuitive, responsive, and visually compelling web pages while ensuring seamless functionality across all devices and platforms.",
    icon: <Brush />,
  },
  {
    step: 4,
    title: "Test & Optimize",
    description:
      "A flawless website goes through extensive testing. We analyze performance, security, and user experience, fixing any issues, improving load times, and ensuring smooth navigation across different browsers and screen sizes.",
    icon: <ShieldCheck />,
  },
  {
    step: 5,
    title: "Launch & Maintain",
    description:
      "Your website is ready to go live! We handle deployment, monitor performance, and provide ongoing maintenance, updates, and security enhancements to keep your website running smoothly and efficiently over time.",
    icon: <Rocket />,
  },
];
export default webProcess;
