import { useEffect, useRef, useState } from "react";

function Process({
  data,
  img,
  title,
  description,
}: {
  data: any[];
  img: string;
  title: string;
  description?: string;
}) {
  const [active, setActive] = useState(0);
  const stepsRef = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const handleScroll = () => {
      stepsRef.current.forEach((step, index) => {
        if (step) {
          const rect = step.getBoundingClientRect();
          if (
            rect.top < window.innerHeight / 2 &&
            rect.bottom > window.innerHeight / 2
          ) {
            setActive(index);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className="flex flex-col-reverse md:flex-row-reverse gap-12 w-full">
      <div className="flex flex-col items-center justify-center gap-10 md:w-1/2">
        {data.map(({ step, title, icon, description }, index) => (
          <div
            key={step}
            ref={(el) => (stepsRef.current[index] = el)}
            className="relative flex flex-col gap-4"
          >
            <div className="flex items-center gap-4">
              <span
                className={`size-12 flex items-center justify-center rounded-full transition-all duration-300 text-white shadow-md ${
                  active >= index ? "bg-primary" : "bg-gray-500"
                }`}
              >
                {icon}
              </span>
              <div>
                <h4 className="text-xs uppercase text-gray-500">Step {step}</h4>
                <h3 className="text-lg font-medium text-gray-900 text-pretty max-w-xs">{title}</h3>
              </div>
            </div>

            <p className="text-gray-600 text-sm leading-relaxed max-w-sm">
              {description}
            </p>

            {index < data.length - 1 && (
              <span
                className="mx-5 h-20 w-1 bg-primary rounded-full transition-all duration-300"
                style={{
                  opacity: active > index ? 1 : 0.5,
                }}
              ></span>
            )}
          </div>
        ))}
      </div>
      <div className="flex justify-start items-start h-full md:w-1/2">
        <div className="w-full h-full relative">
          <img
            src={img}
            className="w-full h-full object-cover rounded-xl shadow-md"
            alt="Process"
          />
          <div className="absolute top-0 left-0 w-full h-full flex gap-3 justify-start items-start bg-gradient-to-b from-black/60 to-transparent rounded-xl p-4">
            <div className="flex flex-col justify-end gap-3">
              <h2 className="text-4xl text-white font-light tracking-wide">
                {title}
              </h2>
              <p className="text-white text-lg text-pretty font-light">
                {description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Process;
