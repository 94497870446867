import {
    Brush,
    Users,
    Eye,
    CheckCircle,
    TrendingUp,
    LifeBuoy,
    Rocket,
  } from "lucide-react";
  
  const whyChooseUsForUIUX = [
    {
      id: 1,
      title: "User-Centric Design",
      description:
        "We prioritize user needs, creating intuitive and engaging experiences that enhance usability and satisfaction.",
      icon: <Users />,
    },
    {
      id: 2,
      title: "Creative & Modern UI",
      description:
        "Our team designs visually stunning, modern interfaces that align with your brand identity and captivate users.",
      icon: <Brush />,
    },
    {
      id: 3,
      title: "Seamless User Experience",
      description:
        "We craft seamless navigation and interactions to ensure users enjoy a smooth, frustration-free digital experience.",
      icon: <Eye />,
    },
    {
      id: 4,
      title: "Data-Driven Design Decisions",
      description:
        "We leverage analytics, A/B testing, and user feedback to refine designs for maximum engagement and effectiveness.",
      icon: <CheckCircle />,
    },
    {
      id: 5,
      title: "Scalable & Future-Ready Designs",
      description:
        "Our UI/UX solutions are built to scale, ensuring adaptability as your business and user base grow.",
      icon: <TrendingUp />,
    },
    {
      id: 6,
      title: "Ongoing Support & Enhancements",
      description:
        "We provide post-launch support, usability improvements, and continuous updates to keep your design ahead of trends.",
      icon: <LifeBuoy />,
    },
    {
      id: 7,
      title: "Rapid Prototyping & Iteration",
      description:
        "We create wireframes and interactive prototypes to validate ideas quickly, ensuring efficient design iterations.",
      icon: <Rocket />,
    },
  ];
  
  export default whyChooseUsForUIUX;
  