import {
  Brush,
  CheckCircle,
  Code,
  Layers,
  LifeBuoy,
  Rocket,
  Search,
} from "lucide-react";

const csdProcessData = [
  {
    step: 1,
    title: "Understanding Your Vision",
    description:
      "We begin by diving deep into your business needs, challenges, and goals. Through strategic discussions and research, we define the project scope to ensure the final product aligns perfectly with your vision.",
    icon: <Search />,
  },
  {
    step: 2,
    title: "Strategic Planning & Architecture",
    description:
      "Our experts craft a detailed blueprint for your software, focusing on scalability, security, and long-term efficiency. Clear timelines and structured milestones ensure smooth execution from start to finish.",
    icon: <Layers />,
  },
  {
    step: 3,
    title: "Designing an Engaging User Experience",
    description:
      "Our UI/UX designers create intuitive and visually stunning interfaces, ensuring seamless interactions and a frictionless user journey tailored to your audience.",
    icon: <Brush />,
  },
  {
    step: 4,
    title: "Agile Development & Iteration",
    description:
      "Our developers bring your vision to life, writing clean, scalable, and high-performance code. We follow agile methodologies, keeping you involved with regular updates and iterations.",
    icon: <Code />,
  },
  {
    step: 5,
    title: "Comprehensive Testing & Quality Assurance",
    description:
      "Rigorous testing is performed at every stage to eliminate bugs, optimize performance, and ensure a flawless experience across all devices and platforms.",
    icon: <CheckCircle />,
  },
  {
    step: 6,
    title: "Seamless Deployment & Go-Live",
    description:
      "We handle all deployment complexities, ensuring a smooth and secure launch. From server setup to database configuration, we take care of everything for a stress-free go-live.",
    icon: <Rocket />,
  },
  {
    step: 7,
    title: "Ongoing Support & Future Enhancements",
    description:
      "Our partnership doesn’t end at launch. We provide continuous monitoring, updates, and feature enhancements to keep your software performing at its best.",
    icon: <LifeBuoy />,
  },
];

export default csdProcessData;
