import { CheckCircle, Code, LifeBuoy, Rocket, Shield, TrendingUp, Users } from "lucide-react";

const whyChooseCsdData =[
    {
      id: 1,
      title: "Experienced Software Engineers",
      description:
        "Our team of skilled developers specializes in crafting robust, scalable, and high-performing software solutions tailored to your unique business needs.",
      icon: <Code />,
    },
    {
      id: 2,
      title: "Client-Focused Solutions",
      description:
        "We prioritize your business goals, ensuring that every software solution is designed to enhance efficiency, streamline operations, and drive growth.",
      icon: <Users />,
    },
    {
      id: 3,
      title: "Industry-Proven Expertise",
      description:
        "With a history of delivering successful projects across multiple industries, we bring deep expertise in building secure and innovative software applications.",
      icon: <CheckCircle />,
    },
    {
      id: 4,
      title: "Quality-Driven Development",
      description:
        "We follow industry best practices, writing clean, maintainable, and well-documented code to ensure long-term reliability and easy scalability.",
      icon: <Shield />,
    },
    {
      id: 5,
      title: "Scalable & Future-Proof Software",
      description:
        "Our software solutions are built with scalability in mind, allowing your business to grow seamlessly without performance bottlenecks.",
      icon: <TrendingUp />,
    },
    {
      id: 6,
      title: "Continuous Support & Maintenance",
      description:
        "We offer ongoing support, updates, and performance enhancements to keep your software secure, efficient, and up-to-date with the latest technologies.",
      icon: <LifeBuoy />,
    },
    {
      id: 7,
      title: "Efficient Development & Deployment",
      description:
        "Using modern development methodologies, we ensure rapid software delivery without compromising on quality, helping you stay ahead in a competitive market.",
      icon: <Rocket />,
    },
  ]


  export default whyChooseCsdData