import {
  ArrowRight,
  ChevronRight,
  Clock,
  GraduationCap,
  MapPin,
  Minus,
  Plus,
  Users,
} from "lucide-react";
import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ApplyForm from "./apply-form";
import axios from "axios";
import { toast } from "sonner";

function JobOpeningList({ data }: { data: any }) {
  const [openIndex, setOpenIndex] = useState(null);
  const [selectedJob, setSelectedJob] = useState<any>({});
  const [apply, setApply] = useState(false);

  const applyForJob = (job: any) => {
    setApply(true);
    setSelectedJob(job);
  };

  const toggleAccordion = (index: any) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const onSubmit = (value: any) => {
    const loading = toast.loading("Applying ...");

    const formData = new FormData();

    formData.append("job_opening_id", selectedJob.id);
    formData.append("job_application[first_name]", value.first_name);
    formData.append("job_application[last_name]", value.last_name);
    formData.append("job_application[job_email]", value.email);
    formData.append("job_application[mobile_no]", value.mobile_no);
    formData.append("job_application[linkedin_url]", value.linkedin_url);
    formData.append(
      "job_application[current_expected_salary]",
      value.current_expected_salary
    );
    formData.append("job_application[gender]", value.gender);
    formData.append(
      "job_application[open_for_relocation]",
      value.open_for_relocation
    );
    formData.append("job_application[notice_period]", value.notice_period);
    formData.append("job_application[upload_cv]", value.upload_cv);
    formData.append("job_application[cover_letter]", value.cover_letter);

    axios
      .post(`job_applications`, formData)
      .then((res) => {
        if (res.status === 201) {
          toast.dismiss(loading);
          toast.success("Application submitted successfully");
          setApply(false);
          setSelectedJob({});
        }
      })
      .catch((err) => {
        toast.dismiss(loading);
        toast.error(err.response.data.message || err.message);
      });
  };

  useEffect(() => {
    if (apply) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [apply]);
  return (
    <section className="w-full min-h-auto overflow-hidden relative flex  flex-col gap-10 lg:p-16 p-8">
      {data.map((job: any, index: number) => (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          className="border-2 border-black overflow-hidden w-full bg-white relative shadow-[6px_6px_0_#000] hover:shadow-[8px_8px_0_#000] transition-all duration-200"
        >
          <button
            className="w-full p-5 flex justify-between items-center outline-none  transition-colors"
            onClick={() => toggleAccordion(index)}
          >
            <div className="flex flex-col items-start justify-start gap-3">
              <h3 className="text-lg font-semibold">{job.job_title}</h3>
              <span className="px-3 py-1 text-sm border-2 rounded-full border-black font-semibold">
                {job.job_position}
              </span>
            </div>
            <div className="h-10 w-10 flex items-center justify-center border-2 border-black rounded-full">
              {openIndex === index ? (
                <Minus className="size-5" />
              ) : (
                <Plus className="size-5" />
              )}
            </div>
          </button>
          <AnimatePresence>
            {openIndex === index && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="overflow-hidden"
              >
                <div className="p-5 border-t-2 border-black flex flex-col items-start gap-5">
                  <div className="flex gap-2 flex-wrap">
                    <span className="flex items-center gap-1 px-3 py-1 text-sm border-2 rounded-full border-black font-semibold">
                      <Users className="size-4" />
                      No of Openings: {job.no_of_opening}
                    </span>
                    <span className="flex items-center gap-1 px-3 py-1 text-sm border-2 rounded-full border-black font-semibold">
                      <Clock className="size-4" />
                      {job.job_type}
                    </span>
                    <span className="flex items-center gap-1 px-3 py-1 text-sm border-2 rounded-full border-black font-semibold">
                      <MapPin className="size-4" />
                      {job.location}
                    </span>
                  </div>
                  <div className="flex items-center gap-1 border-2 rounded-full border-black px-3 py-1 font-semibold">
                    <GraduationCap />
                    <h2>{job.qualification}</h2>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-full">
                    <div className="w-full p-4 border-2 border-black gap-3 flex flex-col shadow-[4px_4px_0_#000] hover:shadow-[6px_6px_0_#000] transition-all bg-amber-50">
                      <h2 className="font-bold text-lg border-b-2 border-black pb-2">
                        What We Offer
                      </h2>
                      <div className="flex flex-col gap-3 pt-2">
                        {job?.what_we_offer?.map(
                          (data: string, idx: number) => (
                            <motion.div
                              key={idx}
                              initial={{ opacity: 0, x: -10 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{ delay: idx * 0.1 }}
                              className="flex flex-row items-center gap-3 text-sm"
                            >
                              <ChevronRight className="size-4 text-amber-600" />
                              <span className="font-medium">{data}</span>
                            </motion.div>
                          )
                        )}
                      </div>
                    </div>

                    <div className="w-full p-4 border-2 border-black gap-3 flex flex-col shadow-[4px_4px_0_#000] hover:shadow-[6px_6px_0_#000] transition-all bg-blue-50">
                      <h2 className="font-bold text-lg border-b-2 border-black pb-2">
                        Key Responsibilities
                      </h2>
                      <div className="flex flex-col gap-3 pt-2">
                        {job?.key_responsibilities?.map(
                          (data: string, idx: number) => (
                            <motion.div
                              key={idx}
                              initial={{ opacity: 0, x: -10 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{ delay: idx * 0.1 }}
                              className="flex flex-row items-center gap-3 text-sm"
                            >
                              <ChevronRight className="size-4 text-blue-600" />
                              <span className="font-medium">{data}</span>
                            </motion.div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => applyForJob(job)}
                    className="bg-green-400 hover:bg-green-500  shadow-[6px_6px_0_#000] hover:shadow-[6px_6px_0_#000] p-3 border-2 flex gap-2 border-black font-semibold outline-none"
                  >
                    Apply Now <ArrowRight className="-rotate-45" />
                  </button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      ))}
      {apply && (
        <ApplyForm onClose={() => setApply(false)} onSubmit={onSubmit} />
      )}
    </section>
  );
}

export default JobOpeningList;
