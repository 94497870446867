import React from "react";
import Grid from "../Grid/grid";
import Title from "../title";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowRight } from "lucide-react";

function OtherCases({
  cases,
  heading,
  handleNavigate,
  buttonTitle
}: {
  cases: any;
  heading: string;
  handleNavigate: (data: any) => void;
  buttonTitle: string
}) {
  const navigate = useNavigate();
  const { id, title } = useParams<{ id: any; title: string }>();

  const filteredCases = cases.filter((item: any) => item.id !== parseInt(id));

  const randomCases = filteredCases.sort(() => 0.5 - Math.random()).slice(0, 4);
  return (
    <section className="relative flex flex-col h-auto px-4 sm:px-6 md:px-10 lg:px-16 py-10">
      <Grid direction="bottom-center" size={100} />
      <div className="z-10 flex justify-start items-start mb-12 w-full">
        <Title title={heading} />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-6">
        {randomCases.map((data: any) => (
          <div
            key={data.id}
            className="group h-full flex flex-col overflow-hidden rounded-xl bg-white border border-gray-100 shadow-sm hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
          >
            <div className="relative aspect-video overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent z-10" />
              <img
                src={data.main_image_url}
                alt={data.title}
                className="h-full w-full object-cover transition-transform duration-500 group-hover:scale-110"
              />
              <div className="absolute top-4 left-4 z-20">
                <span className="inline-flex items-center px-3 py-1 text-xs font-medium rounded-full bg-white/90 backdrop-blur-sm text-primary shadow-sm border border-primary/10">
                  {data.domain}
                </span>
              </div>
            </div>
            <div className="p-5 pb-0">
              <h2 className="text-lg font-semibold text-gray-900 line-clamp-2 group-hover:text-primary transition-colors">
                {data.title}
              </h2>
            </div>
            <div className="p-5 pt-3 flex-grow">
              <p className="text-sm text-gray-500 line-clamp-3">{data.brief}</p>
            </div>
            <div className="p-5 pt-0 flex justify-between items-center">
              <button
                onClick={() => handleNavigate(data)}
                className="text-sm font-medium text-primary hover:underline flex items-center gap-1 group-hover:gap-2 transition-all outline-none"
              >
                {buttonTitle} <ArrowRight />
              </button>
              <div className="h-2 w-2 rounded-full bg-primary/20 group-hover:bg-primary transition-colors" />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default OtherCases;
