import {
  AlignRight,
  Bell,
  BriefcaseBusiness,
  Clipboard,
  FileQuestion,
  Handshake,
  LogOut,
  NotebookPen,
  Users,
} from "lucide-react";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function AdminLayout({ children }: { children: React.ReactNode }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [view, setView] = useState(false);

  const routes = [
    {
      id: 1,
      name: "Our Work",
      path: "/admin/our-work",
      icon: <Clipboard size={18} />,
    },
    {
      id: 2,
      name: "Testimonial",
      path: "/admin/testimonial",
      icon: <Users size={18} />,
    },
    {
      id: 3,
      name: "Blogs",
      path: "/admin/blog",
      icon: <NotebookPen size={18} />,
    },
    {
      id: 4,
      name: "Client Logos",
      path: "/admin/client-logos",
      icon: <Handshake size={18} />,
    },
    {
      id: 5,
      name: "Job Openings",
      path: "/admin/job-openings",
      icon: <BriefcaseBusiness size={18} />,
    },
    {
      id: 6,
      name: "Visitor Queries",
      path: "/admin/visitor-queries",
      icon: <FileQuestion size={18} />,
    },
  ];

  const handleLogOut = () => {
    localStorage.clear();
    navigate("/admin");
  };

  return (
    <div className="bg-primary h-screen flex flex-col md:flex-row p-2 overflow-hidden">
      {/* big screen navbar */}
      <div
        className={`${
          view ? "w-full md:w-80" : "w-20"
        } pr-0 md:pr-2 mb-2 md:mb-0 hidden md:flex flex-col justify-between ease-in duration-300`}
      >
        <div
          className="w-full p-4 rounded-[30px] flex flex-col items-center justify-center gap-5"
          style={{
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05))",
            backdropFilter: "blur(12px)",
            WebkitBackdropFilter: "blur(12px)",
            boxShadow: "inset 0 0 20px rgba(255, 255, 255, 0.1)",
          }}
        >
          <div
            className={`flex items-center ${
              view ? "justify-between" : "justify-center"
            } w-full`}
          >
            {view && (
              <h2 className="text-white capitalize text-sm font-semibold">
                Softcoding Solutions
              </h2>
            )}
            <button
              onClick={() => setView(!view)}
              className="text-white outline-none"
            >
              <AlignRight />
            </button>
          </div>
          <div className="flex flex-col items-center justify-center w-full gap-2">
            {routes.map((data, index) => (
              <div
                key={index}
                onClick={() => navigate(data.path)}
                className={`cursor-pointer flex flex-row items-center justify-start gap-3 rounded-full ${
                  view ? "w-full" : ""
                }  ${
                  location.pathname.includes(data.path) &&
                  "bg-gradient-to-r from-white/20 via-white/10 to-white/5"
                } p-1`}
              >
                <span className="bg-white/30 p-2 rounded-full text-white">
                  {data.icon}
                </span>
                {view && (
                  <span className="text-white font-semibold text-sm">
                    {data.name}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="w-full flex items-center justify-center p-4">
          <button
            onClick={handleLogOut}
            className={`${
              view ? "w-full" : ""
            } flex flex-row items-center bg-gradient-to-r from-white/20 via-white/10 to-white/5 p-1 rounded-full gap-2 outline-none`}
          >
            <span className="bg-white/30 p-2 rounded-full text-white">
              <LogOut />
            </span>
            {view && (
              <span className="text-white font-semibold text-lg">Logout</span>
            )}
          </button>
        </div>
      </div>
      {/* small screen navbar */}
      <div
        className={`${
          view ? "h-[90vh]" : "h-14"
        } w-full overflow-hidden mb-2 md:hidden flex transition-all duration-300 ease-in-out`}
      >
        <div
          className="w-full p-4 h-full rounded-[30px] flex flex-col items-center gap-5 relative"
          style={{
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05))",
            backdropFilter: "blur(12px)",
            WebkitBackdropFilter: "blur(12px)",
            boxShadow: "inset 0 0 20px rgba(255, 255, 255, 0.1)",
          }}
        >
          <div className="flex items-center justify-between w-full">
            <h2 className="text-white capitalize text-sm font-semibold">
              Softcoding Solutions
            </h2>

            <button
              onClick={() => setView(!view)}
              className="text-white outline-none"
            >
              <AlignRight />
            </button>
          </div>
          <div className="flex flex-col items-center justify-start w-full flex-grow">
            {routes.map((data, index) => (
              <div
                key={index}
                onClick={() => navigate(data.path)}
                className={`cursor-pointer flex flex-row items-center justify-start gap-3 rounded-full w-full ${
                  location.pathname.includes(data.path) &&
                  "bg-gradient-to-r from-white/20 via-white/10 to-white/5"
                } p-1`}
              >
                <span className="bg-white/30 p-2 rounded-full text-white">
                  {data.icon}
                </span>

                <span className="text-white font-semibold text-sm">
                  {data.name}
                </span>
              </div>
            ))}
          </div>
          <div className="flex items-center justify-between w-full mt-auto p-2">
            <button className="text-white p-2 rounded-full bg-white/20 outline-none">
              <Bell />
            </button>
            <button
              onClick={handleLogOut}
              className="text-white p-2 rounded-full bg-white/20 outline-none"
            >
              <LogOut />
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-2xl rounded-[30px] w-full h-screen md:h-auto overflow-y-auto flex-grow">
        {children}
      </div>
    </div>
  );
}

export default AdminLayout;
