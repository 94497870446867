import { ChevronLeft, ChevronRight, Pencil, Trash, X } from "lucide-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import usePagination from "../../Pagination/use-pagination";
import Pagination from "../../Pagination/pagination";

function JobDetail({
  job,
  setView,
  setSelectedJob,
  onDeleteView,
  applicants,
}: {
  job: any;
  setView: React.Dispatch<React.SetStateAction<"list" | "detailed">>;
  setSelectedJob: React.Dispatch<React.SetStateAction<any>>;
  onDeleteView: (data: any) => void;
  applicants: any[];
}) {
  const navigate = useNavigate();
  const [tab, setTab] = useState("Job Brief");
  const [selectedApplicant, setSelectedApplicant] = useState<any>({});
  const [open, setOpen] = useState(false);
  const tabs = [
    { id: 1, name: "Job Brief" },
    {
      id: 2,
      name: "Applicants",
    },
  ];

  const filteredApplicants = applicants.filter(
    (data) => data.job_opening_id === job.id
  );

  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(filteredApplicants, 10);

  const handleViewCoverLetter = (applicant: any) => {
    setOpen(true);
    setSelectedApplicant(applicant);
  };

  return (
    <div className="w-full h-auto md:rounded-2xl shadow-md flex flex-col md:border overflow-y-auto">
      <div className="w-full border-b p-3 flex flex-wrap justify-between items-center gap-3">
        <div className="flex gap-2 items-center justify-center">
          <button
            onClick={() => {
              setSelectedJob({});
              setView("list");
            }}
            className="outline-none border-2 p-2 rounded-full"
          >
            <ChevronLeft />
          </button>
          <h2 className="text-base md:text-lg font-bold">{job?.title}</h2>
        </div>
        <div className="flex gap-2">
          <button
            onClick={() =>
              navigate(`/admin/job-openings/update-job-opening/${job.id}`)
            }
            className="border-2 border-green-200 text-green-400 p-2 rounded-xl outline-none"
          >
            <Pencil />
          </button>
          <button
            onClick={() => onDeleteView(job)}
            className="border-2 border-red-200 text-red-400 p-2 rounded-xl outline-none"
          >
            <Trash />
          </button>
        </div>
      </div>
      <div className="m-3">
        <div className="rounded-t-2xl border flex items-start w-[200px] border-b-0">
          {tabs.map((data, index) => (
            <div
              key={index}
              onClick={() => setTab(data.name)}
              className={`${
                tab === data.name
                  ? "bg-primary text-white"
                  : "bg-transparent text-gray-900"
              } p-3 cursor-pointer ease-in duration-300 md:text-base text-xs rounded-t-xl text-center flex-1 md:flex-none`}
            >
              {data.name}
            </div>
          ))}
        </div>
        <div className="border flex flex-col rounded-2xl rounded-tl-none">
          {tab === "Job Brief" && (
            <div className="p-3 flex flex-col w-full gap-3">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                <div className="flex flex-col items-start justify-start gap-3 border p-3 rounded-2xl">
                  <h2 className="font-medium text-gray-600">Job Title</h2>
                  <p className="font-medium text-pretty leading-relaxed">
                    {job?.job_title}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-start gap-3 border p-3 rounded-2xl">
                  <h2 className="font-medium text-gray-600">Job Position</h2>
                  <p className="font-medium text-pretty leading-relaxed">
                    {job?.job_position}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-start gap-3 border p-3 rounded-2xl">
                  <h2 className="font-medium text-gray-600">No Of Openings</h2>
                  <p className="font-medium text-pretty leading-relaxed">
                    {job?.no_of_opening}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div className="flex flex-col items-start justify-start gap-3 border p-3 rounded-2xl">
                  <h2 className="font-medium text-gray-600">Location</h2>
                  <p className="font-medium text-pretty leading-relaxed">
                    {job?.location}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-start gap-3 border p-3 rounded-2xl">
                  <h2 className="font-medium text-gray-600">Job Type</h2>
                  <p className="font-medium text-pretty leading-relaxed">
                    {job?.job_type}
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-3 border p-3 rounded-2xl">
                <h2 className="font-medium text-gray-600">Qualification</h2>
                <p className="font-medium text-pretty leading-relaxed">
                  {job?.qualification}
                </p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div className="w-full p-3 border rounded-2xl gap-3 flex flex-col">
                  <h2 className="font-medium text-gray-600">What We Offer</h2>
                  <div className="flex flex-col gap-3">
                    {job?.what_we_offer?.map((data: string, index: number) => (
                      <div
                        key={index}
                        className="flex flex-row items-center gap-3 text-sm"
                      >
                        <ChevronRight className="size-4" />
                        {data}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-full p-3 border rounded-2xl gap-3 flex flex-col">
                  <h2 className="font-medium text-gray-600">
                    Key Responsiblities
                  </h2>
                  <div className="flex flex-col gap-3">
                    {job?.key_responsibilities?.map(
                      (data: string, index: number) => (
                        <div
                          key={index}
                          className="flex flex-row items-center gap-3 text-sm"
                        >
                          <ChevronRight className="size-4" />
                          {data}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {tab === "Applicants" && (
            <div className="p-3 flex flex-col w-full gap-3">
              <div className="rounded-2xl border mt-3 w-full">
                <div className="w-full overflow-x-auto">
                  <table className="w-full rounded-2xl">
                    <thead className="border-b uppercase">
                      <tr>
                        <th className="py-3 px-2 font-medium text-xs text-left">
                          Sr.no
                        </th>
                        <th className="py-3 px-2 font-medium text-xs text-left">
                          Applicant Name
                        </th>
                        <th className="py-3 px-2 font-medium text-xs text-left">
                          Email
                        </th>
                        <th className="py-3 px-2 font-medium text-xs text-left">
                          Contact Number
                        </th>
                        <th className="py-3 px-2 font-medium text-xs text-left">
                          Applied On
                        </th>
                        <th className="py-3 px-2 font-medium text-xs text-left">
                          Notice Period
                        </th>
                        <th className="py-3 px-2 font-medium text-xs text-left">
                          Relocation
                        </th>
                        <th className="py-3 px-2 font-medium text-xs text-left">
                          Linkedin
                        </th>
                        <th className="py-3 px-2 font-medium text-xs text-left">
                          Cover Letter
                        </th>
                        <th className="py-3 px-2 font-medium text-xs text-left">
                          CV
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {currentItems.map((applicant, index) => {
                        return (
                          <tr
                            key={index}
                            className={`cursor-pointer bg-gray-50`}
                          >
                            <td className="py-3 px-2 text-sm">{index + 1}</td>
                            <td className="py-3 px-2 text-sm">
                              {applicant?.first_name} {applicant?.last_name}
                            </td>
                            <td className="py-3 px-2 text-sm">
                              {applicant?.job_email}
                            </td>
                            <td className="py-3 px-2 text-sm">
                              {applicant?.mobile_no}
                            </td>
                            <td className="py-3 px-2 text-sm">
                              {new Date(
                                applicant?.created_at
                              ).toLocaleDateString()}
                            </td>
                            <td className="py-3 px-2 text-sm">
                              {applicant?.notice_period}
                            </td>
                            <td className="py-3 px-2 text-sm">
                              {applicant?.open_for_relocation}
                            </td>
                            <td className="py-3 px-2 text-sm">
                              <button
                                onClick={() =>
                                  window.open(applicant.linkedin_url)
                                }
                                disabled={!applicant.linkedin_url}
                                className="text-blue-600 hover:underline disabled:cursor-not-allowed disabled:bg-gray-600 outline-none"
                              >
                                View
                              </button>
                            </td>
                            <td className="py-3 px-2 text-sm">
                              <button
                                onClick={() => handleViewCoverLetter(applicant)}
                                disabled={!applicant.cover_letter}
                                className="text-blue-600 hover:underline disabled:cursor-not-allowed disabled:bg-gray-600 outline-none"
                              >
                                View
                              </button>
                            </td>
                            <td className="py-3 px-2 text-sm">
                              <button
                                onClick={() =>
                                  window.open(applicant.upload_cv_url)
                                }
                                disabled={!applicant.upload_cv_url}
                                className="text-blue-600 hover:underline disabled:cursor-not-allowed disabled:bg-gray-600 outline-none"
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className=" p-3">
                  <Pagination
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    totalPages={totalPages}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {open && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 no-scrollbar overflow-scroll z-50 p-4">
          <div className="bg-white rounded-2xl w-full sm:min-w-[30rem] md:max-w-3xl h-auto p-8 my-auto">
            <div className="flex items-center justify-between gap-6 mb-2 sm:mb-4">
              <h2 className="text-lg sm:text-2xl font-semibold text-[#1d5b79]">
                {selectedApplicant.first_name} {selectedApplicant.last_name}'s
                Cover Letter
              </h2>
              <div className="flex items-end justify-end">
                <button
                  className="outline-none bg-red-100 border border-red-400 text-red-400 p-2 rounded-full duration-300 ease-in transition-all hover:bg-red-400 hover:text-white"
                  onClick={() => setOpen(false)}
                >
                  <X />
                </button>
              </div>
            </div>
            <h2 className="text-gray-500 text-pretty w-full break-words">
              {selectedApplicant.cover_letter}
            </h2>
          </div>
        </div>
      )}
    </div>
  );
}

export default JobDetail;
