import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import SecureLS from "secure-ls";
import { Toaster } from "sonner";
import { UserProvider } from "./Components/provider/user-id-provider";

const ls = new SecureLS();
axios.defaults.baseURL = "https://webapi.softcodingsolutions.com/api/v1";
// axios.defaults.baseURL = "https://new-rattler-initially.ngrok-free.app/api/v1";

axios.defaults.headers.common["ngrok-skip-browser-warning"] = true;

axios.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${ls.get("access_token")}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <UserProvider>
      <App />
    </UserProvider>
    <Toaster position="top-right" expand={false} />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
