import { Form, Formik } from "formik";
import Input from "../../input/Input";
import File from "../../input/File";
import Editor from "../../Editor/editor";
import { useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import IconButton from "../../input/icon-button";
import { ArrowRight, Plus } from "lucide-react";
import { updateBlogValidationSchema } from "../validation-schema/validation-schema-update-form";

function UpdateBlogForm({
  blog,
  onSubmit,
}: {
  blog: any;
  onSubmit: (values: any) => void;
}) {
  const editorRef = useRef<EditorJS | null>(null);
  return (
    <div className="p-5">
      <Formik
        initialValues={{
          title: blog.title || "",
          brief: blog.brief || "",
          poster_img: "",
          date: blog.date || new Date(),
          written_by: blog.written_by || "",
          content: blog.content || "{}",
        }}
        validationSchema={updateBlogValidationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-4">
              <Input
                name="title"
                type="text"
                placeholder="Enter title"
                label="Title"
              />
              <Input
                name="written_by"
                type="text"
                placeholder="Written by"
                label="Written By"
              />
              <div className="md:col-span-2">
                <Input
                  name="brief"
                  type="text"
                  placeholder="Enter Brief"
                  label="Brief"
                />
              </div>
            </div>
            <File
              name="poster_img"
              label="Poster Image"
              onChange={(event: any) => {
                const file = event.currentTarget.files[0];
                setFieldValue("poster_img", file);
              }}
              accept="image/*"
            />
            <div className="space-y-2">
              <label className="block text-sm text-gray-950 mb-1 capitalize">
                Content
              </label>
              <Editor
                editorRef={editorRef}
                data={values.content}
                onChange={(updatedData) => {
                  setFieldValue("content", updatedData);
                }}
              />
            </div>
            <div className="flex items-start justify-start">
              <IconButton icon={<ArrowRight />} title="Update Blog" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default UpdateBlogForm;
