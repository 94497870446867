import { ErrorMessage } from "formik";
import { UploadCloud } from "lucide-react";
import React, { useState } from "react";

function File({
  name,
  onChange,
  accept,
  multiple = false,
  readOnly,
  label
}: {
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  multiple?: boolean;
  readOnly?: boolean;
  label?: string;
}) {
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (readOnly) return;
    const files = event.target.files;
    if (files) {
      const fileNames = Array.from(files).map((file) => file.name);
      setSelectedFiles(fileNames);
    }
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div>
      <label
        htmlFor={name}
        className="block text-sm text-gray-950 mb-2 capitalize"
      >
        {label}
      </label>
      <label
        htmlFor={name}
        className="flex flex-col items-center justify-center p-6 border-2 border-dashed  rounded-lg cursor-pointer hover:bg-gray-100 ease-in duration-300"
      >
        <UploadCloud className="w-8 h-8 text-primary" />
        {selectedFiles.length > 0 ? (
          <div className="mt-2 text-primary text-center break-words w-52 md:w-full">
            {multiple ? (
              <span>{selectedFiles.length} File(s) selected</span>
            ) : (
              <span>{selectedFiles[0]}</span>
            )}
          </div>
        ) : (
          <span className="text-primary mt-2">
            Click to upload or drag files here
          </span>
        )}
        <input
          id={name}
          name={name}
          type="file"
          className="hidden"
          accept={accept}
          multiple={multiple}
          readOnly={readOnly}
          onChange={handleFileChange}
        />
      </label>
      <ErrorMessage
        component={"div"}
        name={name}
        className="mt-1 text-sm text-red-600"
      />
    </div>
  );
}

export default File;
