const reactBenefits = [
  {
    id: 1,
    title: "Faster Development with Reusable Components",
    description:
      "React’s component-based architecture enables code reuse, reducing redundancy and speeding up development.",
  },
  {
    id: 2,
    title: "Improved Performance with Virtual DOM",
    description:
      "React’s Virtual DOM updates only changed components instead of re-rendering the entire UI, ensuring smooth performance.",
  },
  {
    id: 3,
    title: "Scalable for Large Applications",
    description:
      "React’s modularity and efficient rendering make it ideal for scaling complex applications while maintaining performance.",
  },
  {
    id: 4,
    title: "SEO-Friendly",
    description:
      "With server-side rendering (SSR), React improves SEO by allowing search engines to crawl and index content efficiently.",
  },
  {
    id: 5,
    title: "Strong Developer Community & Ecosystem",
    description:
      "React has an active developer community and extensive libraries, ensuring continuous updates and best practices.",
  },
  {
    id: 6,
    title: "Rich User Interfaces",
    description:
      "React’s declarative approach enables the creation of dynamic, interactive UIs with seamless animations and live updates.",
  },
  {
    id: 7,
    title: "Cross-Platform Development with React Native",
    description:
      "React shares core concepts with React Native, enabling efficient web and mobile development with a unified codebase.",
  },
];

export default reactBenefits;
