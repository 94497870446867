import { motion } from "framer-motion";
import Grid from "../Grid/grid";
import img1 from "../../Assets/image/image (1).png";
import img2 from "../../Assets/image/clutch.png";
import img3 from "../../Assets/image/image (3).png";
import img4 from "../../Assets/image/image (4).png";
import banner from "../../Assets/image/Collab-bro.png";
import { Link } from "react-router-dom";

function HeroSection() {
  const logos = [
    { img: img1, url: "https://softcodingsolutions.com/" },
    {
      img: img2,
      url: "https://clutch.co/profile/softcoding-solutions?_gl=1*15eqwr6*_gcl_au*MzE0NjAzNzAxLjE3NDA5ODU4Nzg.*_ga*MTMwMjY3NTkwMi4xNzQwOTg1ODc4*_ga_D0WFGX8X3V*MTc0MDk4NTg3Ny4xLjAuMTc0MDk4NTg4NS41Mi4wLjEzMDY0NTk5MzQ.#reviews",
    },
    { img: img3, url: "https://www.goodfirms.co/" },
    { img: img4, url: "https://businessfirms.co/company/softcoding-solutions" },
  ];
  return (
    <section className="min-h-screen flex flex-col-reverse lg:flex-row items-center justify-center px-6 sm:px-10 py-16 relative overflow-hidden">
      <Grid direction="top-center" size={100} />
      <div className="absolute -bottom-52 -right-52 w-[500px] h-[500px] md:w-[600px] md:h-[600px] bg-gradient-to-br from-white/80 to-primary/60 blur-3xl -z-10" />
      {/* <div className="absolute -bottom-52 -left-52 w-[500px] h-[500px] md:w-[600px] md:h-[600px] bg-gradient-to-br from-white/80 to-secondary/60 blur-3xl -z-10" /> */}

      <motion.div
        className="flex flex-col items-center text-center lg:text-left gap-4 p-4 lg:w-1/2"
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <motion.h1
          className="text-base sm:text-lg md:text-2xl tracking-widest font-light"
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          Let's
        </motion.h1>

        <motion.h1
          className="text-3xl sm:text-5xl md:text-7xl font-semibold"
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          Connect <span className="text-primary font-bold">&</span>
        </motion.h1>

        <motion.h1
          className="text-3xl sm:text-5xl md:text-7xl font-semibold"
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
        >
          Create
          <motion.span
            className="text-lg sm:text-xl md:text-2xl text-primary font-semibold"
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.8 }}
          >
            {" "}
            something legendary.
          </motion.span>
        </motion.h1>

        <div className="flex flex-wrap justify-center lg:justify-start items-center gap-3 sm:gap-6 mt-6">
          {logos.map((data, index) => (
            <Link to={data.url} target="_blank" key={index}>
              <img
                src={data.img}
                alt="Logo"
                className="h-20 w-20 object-contain grayscale hover:grayscale-0"
              />
            </Link>
          ))}
        </div>
      </motion.div>

      <motion.div
        className="flex justify-center lg:justify-end items-center p-4 lg:w-1/2"
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <img
          alt="banner"
          src={banner}
          className="w-[90%] sm:w-[80%] md:w-[70%] lg:w-full max-h-[400px] sm:max-h-[500px] md:max-h-[600px] object-contain"
        />
      </motion.div>
    </section>
  );
}

export default HeroSection;
