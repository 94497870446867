import HeadSection from "../Components/CMS/head-section";
import CmsBenefits from "../Components/CMS/cms-benefits";
import CmsProcess from "../Components/CMS/cms-process";
import MainLayout from "../layout/main-layout";
import CmsContactSection from "../Components/CMS/cms-contact-section";

function ContentManagementSystem() {
  return (
    <div>
      <MainLayout>
        <HeadSection />
        <CmsBenefits />
        <CmsProcess />
        <CmsContactSection />
      </MainLayout>
    </div>
  );
}

export default ContentManagementSystem;
