import { Code, Settings, Activity, TrendingUp, Layers, Expand, Link } from "lucide-react";

const whyChooseReact = [
  {
    id: 1,
    title: "Expert React Developers",
    description:
      "Our team consists of experienced React.js developers who stay on top of the latest trends and best practices, ensuring that your application is built with cutting-edge technology.",
    icon: <Code />,
  },
  {
    id: 2,
    title: "Custom Solutions",
    description:
      "We create React.js applications that are specifically tailored to your business objectives, user needs, and long-term goals.",
    icon: <Settings />,
  },
  {
    id: 3,
    title: "Agile Development Process",
    description:
      "We follow an agile approach, breaking your project into manageable sprints and ensuring transparency and collaboration throughout the development cycle.",
    icon: <Activity />,
  },
  {
    id: 4,
    title: "High-Performance Web Applications",
    description:
      "Our focus on performance and optimization ensures that your application runs smoothly, even under heavy loads, providing users with an exceptional experience.",
    icon: <TrendingUp />,
  },
  {
    id: 5,
    title: "End-to-End React Services",
    description:
      "From consulting and design to development and post-launch support, we offer comprehensive React.js development services to meet all your needs.",
    icon: <Layers />,
  },
  {
    id: 6,
    title: "Scalability & Maintainability",
    description:
      "We build scalable React applications with modular architecture and maintainable code, ensuring long-term flexibility and growth for your business.",
    icon: <Expand />,
  },
  {
    id: 7,
    title: "Seamless API & Third-Party Integrations",
    description:
      "Our React solutions seamlessly integrate with RESTful APIs, GraphQL, cloud services, and third-party tools to enhance functionality and performance.",
    icon: <Link />,
  }
]
;

export default whyChooseReact;
