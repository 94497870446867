import Grid from "../Grid/grid";
import { AnimatePresence, motion } from "framer-motion";
import techStack from "./data/tech-stack-data";
import { useState } from "react";
import Title from "../title";

function TechStack() {
  const [active, setActive] = useState(0);
  return (
    <section className="w-full h-auto overflow-hidden relative flex flex-col lg:p-16 p-8">
      <Grid size={100} direction="top-center" />
      <div className="z-10 flex justify-between items-start">
        <Title title="Tech Stack " />
      </div>
      <div className="w-full flex flex-wrap items-center justify-center md:gap-10 gap-3">
        {techStack.map((tech, index) => (
          <motion.button
            key={tech.name}
            onClick={() => setActive(index)}
            className={`relative px-4 py-2 text-xs md:text-lg font-medium transition-colors duration-300 rounded-md focus:outline-none
            ${active === index ? "text-primary" : "text-gray-700"}
            hover:text-primary`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            aria-current={active === index ? "page" : undefined}
          >
            <span className="relative z-10">{tech.name}</span>
            <AnimatePresence>
              {active === index && (
                <motion.div
                  className="absolute inset-0 bg-primary bg-opacity-10 rounded-md"
                  layoutId="activeBg"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ type: "spring", stiffness: 500, damping: 30 }}
                />
              )}
            </AnimatePresence>
          </motion.button>
        ))}
      </div>
      <div className="w-full flex flex-wrap gap-12 items-center justify-center p-8">
        <AnimatePresence mode="wait">
          {techStack[active]?.techs?.map((tech, index) => (
            <motion.div
              key={tech.name}
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -5 }}
              className="flex flex-col items-center justify-center gap-3 group"
            >
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="flex items-center justify-center"
              >
                <img
                  src={tech.logo || "/placeholder.svg"}
                  alt={tech.name}
                  className="md:w-40 md:h-40 w-16 h-16 object-contain bg-white rounded-xl shadow-md group-hover:shadow-lg transition-shadow duration-300 sm:p-5 p-2"
                />
              </motion.div>
              <span className="text-sm font-medium text-gray-600 group-hover:text-primary transition-colors duration-300">
                {tech.name}
              </span>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </section>
  );
}

export default TechStack;
