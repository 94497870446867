import Title from "../title";
import map from "../../Assets/image/world-map.svg"
// const WorldMap = lazy(() => import("../../Components/AboutUs/world-map"));

function AccrossGlobe() {
  return (
    <section className="relative md:min-h-screen h-auto w-full flex flex-col overflow-hidden lg:p-16 p-8">
      <div className="z-10 flex justify-start items-start mb-12">
        <Title title="Global Reach" />
      </div>
      <div className="flex flex-col items-center justify-center w-full h-full">
        <div className="z-10 flex flex-col items-center justify-center mb-16 w-full h-full">
          <h2 className="text-4xl font-semibold mb-3">Global Reach</h2>
          <p className="text-xl text-gray-600 max-w-3xl text-center">
            Helping clients worldwide with exceptional service.
          </p>
          <img alt="map" src={map} className="w-full h-full mt-10 select-none" loading="lazy"  />
          {/*Will be used in future to download image /*}
          {/* <Suspense
            fallback={<div className="text-center py-10">Loading ...</div>}
          >
            <WorldMap />
          </Suspense> */}
        </div>
      </div>
    </section>
  );
}

export default AccrossGlobe;
