import { LayoutGrid, PiggyBank, Rocket, Wrench } from "lucide-react";

const crossDevelopmentData = [
  {
    id: 1,
    title: "Cost-Effective Development",
    description:
      "By using a shared codebase, you save both time and money as you only need to build and maintain one app instead of two, reducing development costs.",
    icon: <PiggyBank />,
  },
  {
    id: 2,
    title: "Faster Time-to-Market",
    description:
      "With a single codebase for both platforms, cross-platform development speeds up development and launch times, giving you a competitive edge.",
    icon: <Rocket />,
  },
  {
    id: 3,
    title: "Consistency Across Platforms",
    description:
      "Cross-platform frameworks ensure a uniform user experience on both iOS and Android, enhancing brand recognition and usability.",
    icon: <LayoutGrid />,
  },
  {
    id: 4,
    title: "Easier Maintenance & Updates",
    description:
      "Maintaining a single codebase makes updates, bug fixes, and feature rollouts more efficient across both platforms.",
    icon: <Wrench />,
  },
];

export default crossDevelopmentData;
