import type React from "react";

interface GridProps {
  direction?:
    | "top-right"
    | "top-center"
    | "top-left"
    | "bottom-right"
    | "bottom-center"
    | "bottom-left"
    | "full";
  size?: number;
}

const Grid: React.FC<GridProps> = ({ direction = "full", size = 50 }) => {
  const getMaskSize = () => {
    switch (direction) {
      case "top-center":
      case "bottom-center":
        return "100% 50%";
      case "full":
        return "0% 0%"; // No mask for full
      default:
        return "50% 50%";
    }
  };

  const getMaskPosition = () => {
    switch (direction) {
      case "top-right":
        return "100% 0%";
      case "top-center":
        return "50% 0%";
      case "bottom-right":
        return "100% 100%";
      case "bottom-center":
        return "50% 100%";
      case "bottom-left":
        return "0% 100%";
      case "top-left":
        return "0% 0%";
      case "full":
        return "0% 0%"; // Full grid doesn't require a mask
      default:
        return "0% 0%";
    }
  };

  return (
    <div
      className="absolute inset-0 -z-10"
      style={{
        backgroundImage:
          "linear-gradient(#e5e7eb 1px, transparent 1px), linear-gradient(90deg, #e5e7eb 1px, transparent 1px)",
        backgroundSize: `${size}px ${size}px`,
        opacity: 1,
        // Only apply mask for non-full directions
        maskImage:
          direction === "full"
            ? "none"
            : `radial-gradient(${getMaskSize()} at ${getMaskPosition()}, black 70%, transparent 100%)`,
        WebkitMaskImage:
          direction === "full"
            ? "none"
            : `radial-gradient(${getMaskSize()} at ${getMaskPosition()}, black 70%, transparent 100%)`,
      }}
    />
  );
};

export default Grid;
