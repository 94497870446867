import { motion } from "framer-motion";
import Grid from "../Grid/grid";
import Title from "../title";

function LifeAtScs({ careersData }: { careersData: any }) {
  return (
    <section className="w-full min-h-screen overflow-hidden relative flex flex-col px-4 sm:px-6 md:px-10 lg:px-16 py-12 lg:py-16">
      <Grid direction="top-left" size={100} />
      <div className="z-10 flex flex-col justify-center items-start mb-12">
        <Title title="Life At Softcoding solutions" />
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center gap-8 md:gap-12">
    <div className="relative w-full max-w-lg sm:max-w-xl md:max-w-none p-4 flex items-center justify-center">
      <img
        alt="Happy people"
        className="rounded-3xl h-[350px] sm:h-[450px] md:h-[550px] lg:h-[600px] w-full object-cover shadow-2xl transition-transform duration-300 hover:scale-105 hover:shadow-[0px_30px_50px_rgba(0,0,0,0.6)]"
        src="https://images.unsplash.com/photo-1603195827187-459ab02554a0?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      />
    </div>

    <div className="w-full max-w-2xl grid grid-cols-1 sm:grid-cols-2 gap-6">
      {careersData.benefits.map((data: any, index: number) => (
        <motion.div
          key={index}
          className="p-6 rounded-3xl bg-white/10 backdrop-blur-lg border shadow-lg hover:shadow-xl w-full flex flex-col items-center justify-start transition-all duration-300 hover:scale-105"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: index * 0.1 }}
        >
          <span className="bg-primary/10 text-primary p-4 rounded-full flex items-center justify-center text-2xl shadow-md">
            {data.icon}
          </span>
          <h2 className="text-lg sm:text-xl font-medium text-center text-gray-800 mt-3 leading-relaxed">
            {data.text}
          </h2>
        </motion.div>
      ))}
    </div>
  </div>
    </section>
  );
}

export default LifeAtScs;
