const webBenefits = [
  {
    title: "Custom Web Development",
    description:
      "We build high-quality, custom websites and web applications designed to meet your unique business needs. Whether you need a simple website or a complex platform, our solutions ensure scalability, top performance, and seamless functionality.",
  },
  {
    title: "Responsive & Adaptive Design",
    description:
      "Your website will look stunning and function perfectly on any device. We craft fluid, responsive designs that provide an exceptional user experience, whether viewed on a desktop, tablet, or smartphone.",
  },
  {
    title: "Front-End & Back-End Development",
    description:
      "From visually engaging front-end interfaces to powerful and secure back-end architectures, we handle everything to ensure a smooth, interactive, and feature-rich web experience.",
  },
  {
    title: "SEO & Performance Optimization",
    description:
      "We enhance your website’s visibility with proven SEO strategies and performance optimizations, ensuring fast load times, improved search engine rankings, and an excellent user experience that keeps visitors engaged.",
  },
  {
    title: "E-Commerce Solutions",
    description:
      "Turn visitors into loyal customers with our high-performance e-commerce solutions. We build secure, scalable online stores with seamless payment integrations, intuitive navigation, and engaging shopping experiences.",
  },
  {
    title: "Web Application Security",
    description:
      "Security is our priority. We implement robust security measures, including encryption, firewalls, and vulnerability assessments, to protect your data and ensure a safe browsing experience for your users.",
  },
  {
    title: "CMS Development & Integration",
    description:
      "Easily manage your website’s content with a custom CMS solution. We work with WordPress, Shopify, Webflow, and other platforms to give you full control over your site’s updates and scalability.",
  },
  {
    title: "Third-Party API & SaaS Integrations",
    description:
      "Supercharge your website with seamless integrations! Whether you need payment gateways, CRM systems, cloud services, or other SaaS tools, we ensure smooth and reliable connectivity for enhanced functionality.",
  },
  {
    title: "Ongoing Maintenance & Support",
    description:
      "Your website’s performance matters long after launch. We offer ongoing maintenance, security updates, and technical support to keep your site running smoothly and efficiently, so you can focus on growing your business.",
  },
];
export default webBenefits;
