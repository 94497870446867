import * as Yup from "yup";
import { Form, Formik } from "formik";
import MainLayout from "../layout/main-layout";
import Input from "../Components/input/Input";
import TextArea from "../Components/input/text-area";
import Button from "../Components/input/Button";
import Grid from "../Components/Grid/grid";
import { Globe, Mail, MapPin, Search } from "lucide-react";
import { motion } from "framer-motion";
import { toast } from "sonner";
import Select from "../Components/input/Select";
import axios from "axios";

function ContactUs() {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Name must be at least 2 characters")
      .required("Name is required"),
    contact_number: Yup.string()
      .matches(/^\+?\d{7,15}$/, "Enter a valid phone number")
      .required("Contact number is required"),
    purpose: Yup.string().required("Select Purpose for contact."),
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    message: Yup.string()
      .min(10, "Message must be at least 10 characters")
      .required("Message is required"),
  });

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const purposes = [
    { id: 4, name: "Hire Dedicated Resources" },
    { id: 5, name: "Custom Software Development" },
    { id: 6, name: "IT Consulting" },
    { id: 7, name: "Website Design & Development" },
    { id: 8, name: "Mobile Application Development" },
    { id: 9, name: "Other" },
  ];

  const sendQuery = (values: any, { resetForm }: any) => {
    axios
      .post("contact_us", {
        contact_us: {
          name: values.name,
          email: values.email,
          contact_no: values.contact_number,
          company_name: values.company_name,
          purpose: values.purpose,
          message: values.message,
        },
      })
      .then((res) => {
        toast.success("Query sent successfully");
        resetForm();
      })
      .catch((err) => {
        toast.error(err.response.data.message || err.message);
        console.log(err);
      });
  };

  return (
    <MainLayout>
      <section className="min-h-screen flex flex-col items-center justify-center md:flex-row p-4 md:p-10 mt-20 gap-5">
        <Grid direction="top-right" size={100} />

        <motion.div
          className="relative w-full md:w-1/2 h-[400px] md:h-[500px] lg:h-[700px] rounded-2xl overflow-hidden shadow-xl"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ duration: 0.5 }}
        >
          <img
            src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1471&q=80"
            alt="Creative professionals collaborating in a design studio"
            className="object-cover w-full h-full"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-black/20 flex flex-col justify-between p-4 md:p-8">
            <div className="bg-black/60 text-white p-3 rounded-xl self-end backdrop-blur-md w-fit border border-white/10 shadow-lg">
              <div className="font-medium">Softcoding Solutions</div>
            </div>
            <div className="space-y-4 md:space-y-6">
              <h2 className="text-white text-2xl md:text-3xl lg:text-4xl font-serif italic">
                Empowering innovation with top-tier global tech talent.
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <motion.div
                  className="bg-white/30 backdrop-blur-lg rounded-xl p-4 flex items-center border border-white/20 shadow-lg"
                  whileHover={{ scale: 1.03 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                  <MapPin className="w-8 h-8 text-white mr-3 shrink-0" />
                  <div className="text-white text-sm">
                    518 Solaris Business Hub, Nr Bhuyangdev Cross Road,
                    Ahmedabad, Gujarat 380061
                  </div>
                </motion.div>
                <motion.div
                  className="bg-white/30 backdrop-blur-lg rounded-xl p-4 flex items-center border border-white/20 shadow-lg "
                  whileHover={{ scale: 1.03 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                >
                  <Mail className="w-8 h-8 text-white mr-3 shrink-0" />
                  <div className="text-white text-sm max-w-40 sm:max-w-sm w-full break-words">
                    support@softcodingsolutions.com
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          className="w-full md:w-1/2 md:px-6 lg:px-16"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <div className="max-w-2xl mx-auto my-auto shadow-[6px_6px_0_#000] border-2 p-5 border-black bg-white">
            <div className="mb-6 md:mb-8">
              <h3 className="text-2xl md:text-3xl font-bold tracking-tight">
                Get in touch
              </h3>
              <p className="text-muted-foreground mt-2">
                Fill out the form below and our team will get back to you within
                24 hours.
              </p>
            </div>
            <Formik
              initialValues={{
                name: "",
                contact_number: "",
                email: "",
                purpose: "",
                company_name: "",
                message: "",
              }}
              validationSchema={validationSchema}
              onSubmit={sendQuery}
            >
              {() => (
                <Form>
                  <div className="grid gap-4">
                    <Input
                      name="name"
                      type="text"
                      placeholder="Your Name"
                      label="Name"
                    />
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <Input
                        name="contact_number"
                        type="number"
                        placeholder="63******20"
                        label="Contact number"
                      />
                      <Input
                        name="email"
                        type="email"
                        placeholder="Email"
                        label="Email"
                      />
                    </div>
                    <Select
                      name="purpose"
                      data={purposes}
                      selectedOption="Purpose"
                      label="Purpose"
                    />
                    <Input
                      name="company_name"
                      label="Company Name"
                      placeholder="Company Name"
                      type="text"
                    />
                    <TextArea
                      name="message"
                      placeholder="Your Message"
                      label="Message"
                    />
                  </div>
                  <Button text="Submit" type="submit" />
                </Form>
              )}
            </Formik>
          </div>
        </motion.div>
      </section>
    </MainLayout>
  );
}

export default ContactUs;
