import { ErrorMessage, Field, Form, Formik } from "formik";
import { X } from "lucide-react";
import Input from "../input/Input";
import Select from "../input/Select";
import File from "../input/File";
import TextArea from "../input/text-area";
import { validationSchema } from "./validation/job-validation-schema";
import Button from "../input/Button";

function ApplyForm({
  onClose,
  onSubmit,
}: {
  onClose: () => void;
  onSubmit: (value: any) => void;
}) {
  const noticePeriods = [
    { value: "Immediate", label: "Immediate" },
    { value: "1 Week", label: "1 Week" },
    { value: "2 Weeks", label: "2 Weeks" },
    { value: "1 Month", label: "1 Month" },
    { value: "2 Months", label: "2 Months" },
    { value: "3 Months", label: "3 Months" },
  ];

  return (
    <div className="inset-0 fixed bg-black/50 flex items-center justify-center z-50 w-full overflow-y-auto p-5">
      <div className="flex items-center justify-center p-5 w-full my-auto">
        <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-7xl">
          <div className="flex items-center justify-between gap-4 mb-5">
            <h2 className="text-2xl font-bold">Job Application</h2>
            <button
              onClick={onClose}
              className="outline-none bg-red-100 border border-red-400 text-red-400 p-2 rounded-full duration-300 ease-in transition-all hover:bg-red-400 hover:text-white"
            >
              <X />
            </button>
          </div>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              mobile_no: "",
              email: "",
              linkedin_url: "",
              current_expected_salary: "",
              gender: "",
              open_for_relocation: "",
              notice_period: "",
              upload_cv: null,
              cover_letter: "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, errors }) => (
              <Form className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6">
                  <Input
                    name="first_name"
                    label={"First Name"}
                    placeholder="John"
                    type="text"
                  />
                  <Input
                    name="last_name"
                    label={"Last Name"}
                    placeholder="Doe"
                    type="text"
                  />
                </div>
                <Input
                  name="mobile_no"
                  label={"Contact No."}
                  placeholder="991*******"
                  type="tel"
                />
                <Input
                  name="email"
                  label={"Email"}
                  placeholder="johndoe@example.com"
                  type="email"
                />
                <Input
                  name="linkedin_url"
                  label={"Linkedin Profile"}
                  placeholder="Linkedin Profile"
                  type="text"
                />
                <Input
                  name="current_expected_salary"
                  label={"Expected Salary"}
                  placeholder="Expected Salary"
                  type="number"
                />

                <div className="flex flex-col gap-4">
                  <label className="text-sm font-medium text-gray-900">
                    Gender
                  </label>
                  <div className="flex gap-x-4">
                    {["male", "female"].map((option) => (
                      <label
                        key={option}
                        className="flex items-center gap-2 font-semibold"
                      >
                        <span className="capitalize">{option}</span>
                        <Field
                          name="gender"
                          type="radio"
                          className="accent-primary size-4"
                          value={option}
                        />
                      </label>
                    ))}
                  </div>
                  <ErrorMessage
                    component={"div"}
                    name={"gender"}
                    className="mt-1 text-sm text-red-600"
                  />
                </div>

                <div className="flex flex-col gap-4">
                  <label className="text-sm text-gray-950">
                    Open for Relocation?
                  </label>
                  <div className="flex gap-x-4">
                    {["yes", "no"].map((option) => (
                      <label
                        key={option}
                        className="flex items-center gap-2 font-semibold"
                      >
                        <span className="capitalize">{option}</span>
                        <Field
                          name="open_for_relocation"
                          type="radio"
                          className="accent-primary size-4"
                          value={option}
                        />
                      </label>
                    ))}
                  </div>
                  <ErrorMessage
                    component={"div"}
                    name={"open_for_relocation"}
                    className="mt-1 text-sm text-red-600"
                  />
                </div>

                <Select
                  label="Notice Period"
                  name="notice_period"
                  data={noticePeriods}
                  value="value"
                  labelProperty="label"
                  selectedOption="Notice Period"
                />

                <File
                  name="upload_cv"
                  onChange={(event: any) => {
                    const file = event.currentTarget.files[0];
                    setFieldValue("upload_cv", file);
                  }}
                  accept="image/* , .pdf , .doc , "
                  label={"CV"}
                />
                <TextArea name="cover_letter" label={"Cover Letter"} />
                <Button text="Apply" type="submit" />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ApplyForm;
