import React from "react";
import AdminLayout from "../../../layout/admin-layout";
import Header from "../../../Components/blog-admin/admin-blog-header";
import AddBlogForm from "../../../Components/blog-admin/add-form/add-blog-form";

function AddBlog() {
  return (
    <AdminLayout>
      <Header title="Add Blog" />
      <AddBlogForm />
    </AdminLayout>
  );
}

export default AddBlog;
