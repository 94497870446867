import {
  Zap,
  Brush,
  RefreshCw,
  Smartphone,
  Copy,
  Cpu,
  Package,
  Users,
} from "lucide-react";

const madTechData = [
  {
    name: "Flutter",
    description:
      "Flutter, developed by Google, is an open-source framework for building natively compiled apps for mobile, web, and desktop from a single codebase.",
    benefits: [
      {
        title: "High Performance",
        description:
          "Flutter compiles directly to native code, ensuring smooth animations and fast performance.",
        icon: <Zap />,
      },
      {
        title: "Beautiful UI",
        description:
          "Flutter’s rich set of widgets enables stunning, responsive, and native-like interfaces.",
        icon: <Brush />,
      },
      {
        title: "Quick Development",
        description:
          "The hot reload feature allows real-time updates, speeding up development and testing.",
        icon: <RefreshCw />,
      },
      {
        title: "Cross-Platform Support",
        description:
          "With one codebase, Flutter apps run on iOS, Android, and even the web.",
        icon: <Smartphone />,
      },
    ],
  },
  {
    name: "React Native",
    description:
      "React Native, developed by Facebook, allows mobile app development using JavaScript and React with near-native performance.",
    benefits: [
      {
        title: "Code Reusability",
        description:
          "Reuse a large portion of code across iOS and Android, making development faster and more cost-effective.",
        icon: <Copy />,
      },
      {
        title: "Native-Like Performance",
        description:
          "Uses native components to deliver near-native performance and a seamless user experience.",
        icon: <Cpu />,
      },
      {
        title: "Rich Ecosystem",
        description:
          "Leverage a vast library of tools and packages from the React ecosystem to enhance app functionality.",
        icon: <Package />,
      },
      {
        title: "Strong Community Support",
        description:
          "An active community ensures continuous updates and best practices for long-term success.",
        icon: <Users />,
      },
    ],
  },
];

export default madTechData;
