import React from "react";
import Grid from "../Grid/grid";
import Title from "../title";
import { Clipboard, Clock, Users } from "lucide-react";

function WhichModel() {
  const hiringModels = [
    {
      name: "Fixed Cost Project Model",
      icon: <Clipboard />,
      description:
        "Best for businesses looking for a fixed-price development model with clear requirements, specific timelines, and a set budget. Ideal for software development, mobile apps, and web projects.",
    },
    {
      name: "Time & Material Model",
      icon: <Clock />,
      description:
        "Flexible hiring model for software development projects with evolving requirements. Perfect for startups, agile development, and businesses needing scalability and cost control.",
    },
    {
      name: "Staff Augmentation Model",
      icon: <Users />,
      description:
        "Hire dedicated developers to scale your team quickly. Ideal for IT outsourcing, remote teams, and companies needing specialized expertise for long-term or short-term projects.",
    },
  ];

  return (
    <section className="w-full h-auto overflow-hidden relative flex flex-col lg:p-16 p-8">
      <Grid direction="top-left" size={100} />
      <div className="z-10 flex flex-col items-start mb-12">
        <Title title="Best Hiring Model Guide" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        {hiringModels.map((data, index) => (
          <div
            key={index}
            className="w-full last:md:col-span-2 flex flex-col  gap-4"
          >
            <div className="flex flex-row items-center justify-start gap-4">
              <span className="bg-secondary/40 text-secondary text-4xl p-2 rounded-full">
                {data.icon}
              </span>
              <h2 className="text-3xl font-light ">{data.name}</h2>
            </div>
            <p className="text-lg text-gray-600">{data.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default WhichModel;
