import React from "react";
import MainLayout from "../layout/main-layout";
import RORSection from "../Components/ROR/ror-section";
import RORBenefits from "../Components/ROR/ror-benefits";
import RORProcess from "../Components/ROR/ror-process";
import WhyChooseROR from "../Components/ROR/why-choose-ror";
import RORContactSection from "../Components/ROR/ror-contact-section";

function RubyOnRails() {
  return (
    <div>
      <MainLayout>
        <RORSection />
        <RORBenefits />
        <RORProcess />
        <WhyChooseROR />
        <RORContactSection />
      </MainLayout>
    </div>
  );
}

export default RubyOnRails;
