import { Map, Layout, Settings, Rocket, LifeBuoy } from "lucide-react";

const cmsProcess = [
  {
    step: 1,
    title: "Discovery & Planning",
    description:
      "We begin by understanding your business, content needs, and goals. Based on that, we develop a roadmap and feature set that aligns perfectly with your vision.",
    icon: <Map />,
  },
  {
    step: 2,
    title: "Custom Design & Development",
    description:
      "Our experienced developers create a tailored CMS solution with the functionality and user interface you need, while keeping flexibility and future growth in mind.",
    icon: <Layout />,
  },
  {
    step: 3,
    title: "Testing & Optimization",
    description:
      "Before launching, we rigorously test the CMS to ensure it’s stable, secure, and optimized for performance across all devices and platforms.",
    icon: <Settings />,
  },
  {
    step: 4,
    title: "Launch & Training",
    description:
      "Once your CMS is ready, we provide training for your team and ensure a smooth transition. We’ll be with you every step of the way to ensure success.",
    icon: <Rocket />,
  },
  {
    step: 5,
    title: "Ongoing Support",
    description:
      "After launch, we offer continuous support and maintenance to keep your CMS up to date and running efficiently.",
    icon: <LifeBuoy />,
  },
];

export default cmsProcess;
