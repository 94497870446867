import * as Yup from "yup";

export const validationSchema = Yup.object({
  job_title: Yup.string().required("Job title is required"),
  job_position: Yup.string().required("Job position is required"),
  no_of_opening: Yup.number()
    .typeError("Must be a number")
    .positive("Must be positive")
    .integer("Must be an integer")
    .required("Number of openings is required"),
  location: Yup.string().required("Location is required"),
  job_type: Yup.string().required("Job type is required"),
  qualification: Yup.string().required("Qualification is required"),
  what_we_offer: Yup.array()
  .of(Yup.string().required("This field cannot be empty"))
  .min(1, "At least one detail is required"),

key_responsibilities: Yup.array()
  .of(Yup.string().required("This field cannot be empty"))
  .min(1, "At least one detail is required"),

});
