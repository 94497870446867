import React from "react";
import Grid from "../Grid/grid";
import Title from "../title";
import Process from "../process";
import csdProcessData from "./data/csd-process-data";

function CsdProcess() {
  return (
    <section className="w-full min-h-screen h-full relative flex flex-col overflow-hidden p-2 lg:p-16">
      <Grid size={100} direction="top-left" />
      <div className="z-10 flex justify-start items-start mb-12 w-full">
        <Title title="our process" />
      </div>
      <Process
        data={csdProcessData}
        img="https://images.unsplash.com/photo-1532370937975-93e5c4482146?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        title="Custom Software Development"
        description="Crafting Powerful & Scalable Software Solutions to Drive Your Business Forward."
      />
    </section>
  );
}

export default CsdProcess;
