import React from "react";

function IconButton({
  icon,
  title,
  disabled,
  onClick,
  type,
  bgColor = "bg-primary",
  rounded = "rounded-full",
}: {
  icon: React.ReactNode;
  title?: string;
  disabled?: boolean;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  rounded?:
    | "rounded"
    | "rounded-sm"
    | "rounded-lg"
    | "rounded-xl"
    | "rounded-2xl"
    | "rounded-3xl"
    | "rounded-full";
  bgColor?: string;
}) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={`${bgColor} p-2 ${rounded} flex flex-row items-center justify-center text-white font-semibold outline-none group transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed shadow-md`}
    >
      <span className="bg-white/30 rounded-full p-1 group-hover:rotate-[360deg] transition-transform duration-300">
        {icon}
      </span>
      {title && (
        <span className="md:text-lg capitalize tracking-wide drop-shadow-md ml-1">
          {title}
        </span>
      )}
    </button>
  );
}

export default IconButton;
