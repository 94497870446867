import { TrashIcon } from "lucide-react";
import React from "react";

function DeleteModal({
  customMessage,
  onCancel,
  onDelete,
}: {
  customMessage?: string;
  onCancel: () => void;
  onDelete: () => void;
}) {
  return (
    <div className="inset-0 bg-black bg-opacity-50 h-screen fixed flex items-center justify-center overflow-y-auto z-50">
      <div className="flex items-center justify-center my-auto w-full overflow-y-auto py-5 px-5">
        <div className="bg-white rounded-3xl shadow-md p-6 w-full max-w-sm my-auto mx-auto md:h-auto h-full overflow-y-auto">
          <div className="flex flex-col items-center justify-center relative w-full space-y-4">
            <div className="flex items-center justify-center bg-red-50 p-4 rounded-full my-4">
              <TrashIcon className="md:size-20 size-16 text-red-500 " />
            </div>
            <div className="flex items-center justify-center text-center flex-col mx-4">
              <h3 className="font-semibold text-gray-950 text-base text-pretty">
                {customMessage}
              </h3>
            </div>

            <div className="flex items-center justify-center space-x-4 py-4">
              <button
                onClick={onCancel}
                className="bg-gray-100 hover:bg-gray-300 text-gray-700 rounded-full py-3 px-6 outline-none font-medium"
              >
                No,Keep
              </button>
              <button
                onClick={onDelete}
                className="bg-red-500 hover:bg-red-600 text-white rounded-full py-3 px-6 outline-none font-medium"
              >
                Yes,Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
