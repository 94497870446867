import Grid from "../Grid/grid";
import Process from "../process";
import Title from "../title";
import cmsProcess from "./data/cms-process-data";

function CmsProcess() {
  return (
    <section className="w-full min-h-screen h-full relative flex flex-col overflow-hidden p-2 lg:p-16">
      <Grid size={100} direction="top-left" />
      <div className="z-10 flex justify-start items-start mb-12 w-full">
        <Title title="our process" />
      </div>
      <Process
        data={cmsProcess}
        img="https://images.unsplash.com/photo-1518218475781-e68795a570d4?q=80&w=1974&auto=format&fit=crop"
        title="Content Management System"
        description="Crafting Digital Excellence - Custom Development Solutions that Drive Your Online Success."
      />
    </section>
  );
}

export default CmsProcess;
