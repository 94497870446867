import Grid from "../Grid/grid";

function HeadSection() {
  return (
    <section className="w-full min-h-screen  relative flex flex-col items-start justify-center lg:p-16 p-8 mt-16">
      <Grid size={100} direction="top-center" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="flex flex-col">
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 mb-8">
            You Think We Create
          </h1>

          <div className="rounded-2xl overflow-hidden mb-8">
            <img
              src="https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80"
              alt="Team working on laptops"
              className="w-full h-[400px] object-cover"
            />
          </div>

          <p className="text-gray-600 text-sm md:text-base">
            Our smart and effective solutions simplify everyday life for our
            customers, offering convenience and efficiency in every interaction.
            We are dedicated to delivering innovative, user-friendly experiences
            that seamlessly integrate into daily routines. By leveraging
            cutting-edge technology and a customer-centric approach, we ensure
            that our solutions not only meet but exceed expectations. Our
            commitment to continuous improvement drives us to refine and enhance
            our services, making life easier, more efficient, and more enjoyable
            for those we serve.
          </p>
        </div>

        <div className="flex flex-col">
          <div className="rounded-2xl overflow-hidden mb-8">
            <img
              src="https://images.unsplash.com/photo-1543269865-cbf427effbad?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80"
              alt="Two women looking at laptop"
              className="w-full h-[400px] object-cover"
            />
          </div>

          <div className="grid grid-cols-3 gap-4">
            <div className="bg-gray-100 rounded-lg p-4 flex flex-col items-center">
              <span className="text-primary text-2xl md:text-3xl font-bold">
                100 +
              </span>
              <span className="text-primary text-xs md:text-sm">
                Happy Clients
              </span>
            </div>
            <div className="bg-gray-100 rounded-lg p-4 flex flex-col items-center">
              <span className="text-primary text-2xl md:text-3xl font-bold">
                5 +
              </span>
              <span className="text-primary text-xs md:text-sm">
                Year of Experience
              </span>
            </div>
            <div className="bg-gray-100 rounded-lg p-4 flex flex-col items-center">
              <span className="text-primary text-2xl md:text-3xl font-bold">
                150 +
              </span>
              <span className="text-primary text-xs md:text-sm">
                Completed Project
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeadSection;
