import { Check } from "lucide-react";
import Grid from "../Grid/grid";
import Title from "../title";

function HiringModal({ hiringModels }: { hiringModels: any }) {
  return (
    <section className="w-full min-h-screen overflow-hidden relative flex flex-col px-6 py-12 lg:p-16">
      <Grid direction="top-right" size={100} />
      <div className="z-10 flex flex-col items-start mb-8">
        <Title title="Our Hiring Model" />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
        {hiringModels.map((model: any, index: number) => (
          <div
            key={index}
            className="relative rounded-2xl overflow-hidden group transition-all duration-300 hover:translate-y-[-5px] hover:shadow-lg bg-white border shadow-sm p-6"
          >
            <div
              className="absolute top-0 right-0 w-24 h-24 sm:w-32 sm:h-32 -mr-12 -mt-12 rounded-full opacity-10"
              style={{
                background: `radial-gradient(circle, ${model.color.replace(
                  "text-",
                  "var(--"
                )}, transparent)`,
              }}
            ></div>
            <div className="relative flex flex-col h-full">
              <div className="flex items-center mb-4">
                <div
                  className={`p-3 ${model.iconBg} ${model.color} rounded-xl mr-3`}
                >
                  {model.icon}
                </div>
                <div>
                  <h3 className="text-lg sm:text-xl font-bold text-gray-900">
                    {model.title}
                  </h3>
                  <p className="text-gray-600 text-sm sm:text-base">
                    {model.description}
                  </p>
                </div>
              </div>

              <div className="mb-4">
                <h4 className="text-md sm:text-lg font-semibold mb-2 text-gray-800">
                  Key Benefits
                </h4>
                <ul className="space-y-1 sm:space-y-2">
                  {model.benefits.map((benefit: any, i: number) => (
                    <li key={i} className="flex items-start">
                      <Check
                        className={`w-4 h-4 sm:w-5 sm:h-5 mr-2 flex-shrink-0 mt-0.5 ${model.color}`}
                      />
                      <span className="text-gray-700 text-sm sm:text-base">
                        {benefit}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="mt-auto">
                <h4 className="text-md sm:text-lg font-semibold mb-2 text-gray-800">
                  Ideal For
                </h4>
                <ul className="space-y-1 sm:space-y-2 text-gray-700">
                  {model.idealFor.map((item: any, i: number) => (
                    <li
                      key={i}
                      className={`pl-3 sm:pl-4 border-l-2 ${model.color.replace(
                        "text",
                        "border"
                      )}`}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default HiringModal;
