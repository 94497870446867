import * as Yup from "yup";

export const updateBlogValidationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .min(3, "Title must be at least 3 characters")
    .max(100, "Title can't exceed 100 characters")
    .required("Title is required"),
  brief: Yup.string()
    .trim()
    .min(10, "Brief must be at least 10 characters")
    .max(150, "Brief can't exceed 300 characters")
    .required("Brief is required"),
  written_by: Yup.string()
    .trim()
    .min(3, "Author name must be at least 3 characters")
    .max(50, "Author name can't exceed 50 characters")
    .required("Written by is required"),
});
