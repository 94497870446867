"use client";

import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronDown, Menu, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/logo company.png";
import cinemapro from "../../Assets/image/cinemapro.png";
import cinestore from "../../Assets/image/cinestore.png";
import doctor from "../../Assets/image/Doctor.png";

interface SubTab {
  id: number;
  name: string;
  product_id?: number;
  path?: string;
  img?: string;
  brief?: string;
}

interface Tab {
  id: number;
  name: string;
  path?: string;
  subTabs?: SubTab[];
}

function Navbar() {
  const [services, setServices] = useState(0);
  const [hoveredTab, setHoveredTab] = useState<number | null>(null);
  const [menuView, setMenuView] = useState<boolean>(false);
  const [mobileSubmenu, setMobileSubmenu] = useState<number | null>(null);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  // Add scroll event listener to detect when page is scrolled
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  const tabs: Tab[] = [
    {
      id: 1,
      name: "Company",
      subTabs: [
        { id: 1.1, name: "About Us", path: "/about" },
        {
          id: 1.2,
          name: "Our Work",
          path: "/our-work",
        },
        // {
        //   id: 1.3,
        //   name: "Blogs",
        //   path: "/blog",
        // },
        {
          id: 1.4,
          name: "Careers",
          path: "/careers",
        }
      ],
    },
    {
      id: 2,
      name: "Services",
      subTabs: [
        {
          id: 2.1,
          name: "Ruby on Rails Development",
          path: "/ruby-on-rails-development-company",
          brief:
            "Unlock the power of Ruby on Rails to build robust, secure, and high-performance web applications. Our expert developers ensure rapid development, seamless scalability, and cost-effective solutions tailored to your business needs.",
        },
        {
          id: 2.2,
          name: "Web Development",
          path: "/web-development-company",
          brief:
            "Transform your digital presence with cutting-edge web development. We craft responsive, high-speed, and conversion-driven websites designed to maximize engagement, enhance user experience, and drive business growth.",
        },
        {
          id: 2.3,
          name: "Custom Software Development",
          path: "/custom-software-development-company",
          brief:
            "Empower your business with tailor-made software solutions that streamline operations, boost efficiency, and scale effortlessly. We build intelligent, future-proof software that adapts to your unique business requirements.",
        },
        {
          id: 2.4,
          name: "React JS Development",
          path: "/react-js-development-company",
          brief:
            "Deliver fast, interactive, and seamless web experiences with React JS. Our expert developers create dynamic user interfaces that enhance performance, scalability, and engagement across all devices.",
        },
        {
          id: 2.5,
          name: "Mobile App Development",
          path: "/mobile-app-development-company",
          brief:
            "Turn your app idea into a reality with high-performance, feature-rich mobile applications for iOS and Android. We focus on user-centric design, scalability, and advanced functionality to ensure a seamless mobile experience.",
        },
        {
          id: 2.6,
          name: "Content Management System",
          path: "/content-management-system",
          brief:
            "Simplify content creation, editing, and publishing with a powerful and intuitive CMS. Manage workflows efficiently, enhance collaboration, and maintain brand consistency with a system built for your business needs.",
        },
        {
          id: 2.7,
          name: "UI/UX Design",
          path: "/ui-ux-design-company",
          brief:
            "Make a lasting impression with stunning, user-friendly designs that elevate engagement and drive conversions. Our UI/UX experts craft intuitive digital experiences that enhance usability, accessibility, and brand identity.",
        },
      ],
    },
    {
      id: 3,
      name: "Products",
      subTabs: [
        {
          id: 3.1,
          product_id: 1,
          name: "CinemaPro",
          img: cinemapro,
          brief:
            "CinemaPro is a premium platform designed to help film industry professionals manage their projects seamlessly, offering tools for collaboration, scheduling, and production tracking.",
        },
        {
          id: 3.2,
          name: "MediCare Pro",
          product_id: 2,
          img: doctor,
          brief:
            "Doctor is a comprehensive clinic management platform that streamlines patient care, appointment scheduling, billing, and medical records management, improving the overall healthcare experience.",
        },
        {
          id: 3.3,
          name: "CineStore",
          product_id: 3,
          img: cinestore,
          brief:
            "CineStore is an innovative online marketplace that connects the film industry with vendors, crew, artists, and equipment rental services, offering a vast network for all production needs.",
        },
      ],
    },
    {id: 4, name: "Hire A Developer", path: "/hire-a-developer"},
    { id: 5, name: "Contact Us", path: "/contact-us" },
  ];

  const handleTabClick = (tab: Tab | SubTab) => {
    if (tab.path) {
      navigate(tab.path);
      setMenuView(false);
    } else if ("product_id" in tab && tab.product_id) {
      navigate(`/product/${tab.name}/${tab.product_id}`);
      setMenuView(false);
    }
    setHoveredTab(null);
  };

  const transition = {
    type: "spring",
    stiffness: 150,
    damping: 12,
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setHoveredTab(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="fixed top-2 left-4 right-4 z-50">
      <div className="mx-auto px-4">
        <div
          className={`flex items-center justify-between p-3 rounded-2xl shadow-md border transition-all duration-300 bg-white`}
        >
          <img
            src={logo || "/placeholder.svg"}
            alt="Company Logo"
            className="md:w-7 w-5 cursor-pointer"
            onClick={() => navigate("/")}
          />

          <div className="hidden md:flex items-center gap-8 relative">
            {tabs.map((tab, index) => (
              <div key={tab.id} className="">
                <div
                  className={`relative cursor-pointer font-medium transition-colors duration-300 text-primary hover:text-primary/80
                `}
                  onMouseEnter={() => setHoveredTab(index)}
                >
                  <div
                    onClick={() => handleTabClick(tab)}
                    className="flex items-center"
                  >
                    {tab.name}
                    {tab.subTabs && <ChevronDown size={16} className="ml-1" />}
                  </div>
                  {hoveredTab === index && (
                    <motion.div
                      layoutId="underline"
                      className="absolute -bottom-1 left-0 right-0 h-0.5 bg-primary rounded-full"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.3 }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>

          <button
            className={`md:hidden p-2 rounded-full text-primary`}
            onClick={() => setMenuView(true)}
          >
            <Menu size={24} />
          </button>

          <AnimatePresence mode="wait">
            {hoveredTab !== null && tabs[hoveredTab]?.subTabs && (
              <motion.div
                key={hoveredTab}
                ref={dropdownRef}
                onMouseEnter={() => setHoveredTab(hoveredTab)}
                onMouseLeave={() => setHoveredTab(null)}
                initial={{ opacity: 0, y: 10, height: 0 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  height: "auto",
                  transition: {
                    height: { duration: 0.3 },
                    opacity: { duration: 0.2, delay: 0.1 },
                  },
                }}
                exit={{
                  opacity: 0,
                  y: 10,
                  height: 0,
                  transition: {
                    height: { duration: 0.2, delay: 0.1 },
                    opacity: { duration: 0.1 },
                  },
                }}
                className="absolute top-full left-0 right-0 z-40 hidden md:block overflow-hidden bg-white rounded-2xl border mx-auto px-4 mt-2"
              >
                {/* Decorative elements */}

                <div className="container mx-auto px-4 py-8 relative z-10">
                  {tabs[hoveredTab].subTabs?.some((sub) => sub.img) ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      {tabs[hoveredTab]?.subTabs?.map((sub) => (
                        <motion.div
                          key={sub.id}
                          className="group flex flex-row items-start gap-4 p-5 rounded-2xl transition duration-300 cursor-pointer border border-gray-100/80 hover:border-primary/30 hover:bg-white/90 overflow-hidden"
                          onClick={() => handleTabClick(sub)}
                          whileHover={{
                            scale: 1.02,
                            boxShadow: "0 10px 30px -5px rgba(0, 0, 0, 0.08)",
                            y: -2,
                          }}
                          whileTap={{ scale: 0.98 }}
                          transition={{
                            type: "spring",
                            stiffness: 200,
                            damping: 20,
                          }}
                        >
                          {sub.img && (
                            <div className="relative overflow-hidden rounded-xl">
                              <motion.div className="absolute inset-0 bg-gradient-to-tr from-primary/20 to-blue-500/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10" />
                              <motion.img
                                src={sub.img || "/placeholder.svg"}
                                alt={sub.name}
                                className="w-80 h-44 object-cover border border-gray-100 group-hover:scale-105 transition-transform duration-700 ease-out"
                                initial={{ opacity: 0, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{
                                  duration: 0.3,
                                  ease: "easeInOut",
                                }}
                              />
                            </div>
                          )}
                          <div className="w-full">
                            <div className="flex items-center mb-2">
                              <h3 className="font-semibold text-primary text-lg group-hover:text-primary/80 transition-colors duration-300">
                                {sub.name}
                              </h3>
                            </div>
                            <p className="text-sm text-gray-600 group-hover:text-gray-800 transition-colors duration-300 line-clamp-3">
                              {sub.brief}
                            </p>
                          </div>
                        </motion.div>
                      ))}
                    </div>
                  ) : tabs[hoveredTab]?.subTabs?.some((tab) => tab.brief) ? (
                    <div className="flex flex-col md:flex-row w-full bg-opacity-80 backdrop-blur-lg overflow-hidden rounded-2xl">
                      <div className="flex flex-col justify-start items-start gap-3 border-r border-gray-100 p-6 w-full md:w-1/3 bg-white/40 backdrop-blur-md">
                        {tabs[hoveredTab]?.subTabs?.map((sub, index) => (
                          <motion.button
                            key={sub.id}
                            onClick={() => handleTabClick(sub)}
                            onMouseEnter={() => setServices(index)}
                            className={`relative text-md font-medium text-gray-700 hover:text-primary transition duration-300 px-5 py-3.5 w-full text-left rounded-xl shadow-sm hover:shadow-md bg-white/80 backdrop-blur-lg border ${
                              services === index
                                ? "border-primary/50"
                                : "border-gray-100/70"
                            }`}
                            whileHover={{
                              scale: 1.02,
                              backgroundColor: "rgba(255, 255, 255, 0.95)",
                            }}
                            whileTap={{ scale: 0.98 }}
                            transition={{
                              type: "spring",
                              stiffness: 300,
                              damping: 20,
                            }}
                          >
                            <span className="relative z-10">{sub.name}</span>
                            {services === index && (
                              <motion.div
                                className="absolute left-0 top-0 bottom-0 w-1 bg-primary rounded-l-lg"
                                layoutId="serviceIndicator"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.2 }}
                              />
                            )}
                          </motion.button>
                        ))}
                      </div>

                      <motion.div
                        key={services}
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        transition={{ duration: 0.4, ease: "easeInOut" }}
                        className="flex flex-col justify-center items-start p-8 w-full md:w-2/3 "
                      >
                        <h3 className="text-xl font-semibold text-primary mb-3">
                          {tabs[hoveredTab]?.subTabs?.[services]?.name}
                        </h3>
                        {tabs[hoveredTab]?.subTabs?.[services]?.brief && (
                          <p className="text-md text-gray-700 font-medium leading-relaxed">
                            {tabs[hoveredTab]?.subTabs?.[services]?.brief}
                          </p>
                        )}
                      </motion.div>
                    </div>
                  ) : (
                    <div className="grid grid-cols-2 gap-4">
                      {tabs[hoveredTab]?.subTabs?.map((sub) => (
                        <motion.button
                          key={sub.id}
                          onClick={() => handleTabClick(sub)}
                          whileHover={{
                            scale: 1.05,
                            boxShadow: "0 10px 25px rgba(0,0,0,0.08)",
                            y: -2,
                          }}
                          whileTap={{ scale: 0.95 }}
                          transition={{
                            type: "spring",
                            stiffness: 300,
                            damping: 20,
                          }}
                          className="group relative text-md font-medium text-gray-700 hover:text-primary transition duration-200 px-5 py-4 w-full text-left rounded-xl shadow-sm hover:shadow-md bg-white/80 backdrop-blur-md border hover:border-primary/30 overflow-hidden"
                        >
                          <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-primary/0 via-primary/30 to-primary/0 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-500 ease-out" />
                          <span className="block">{sub.name}</span>
                         
                        </motion.button>
                      ))}
                    </div>
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <AnimatePresence>
          {menuView && (
            <motion.div
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              className="flex md:hidden fixed top-0 right-0 w-full h-full bg-white shadow-lg p-6 z-50"
            >
              <div className="absolute top-0 left-0 w-full h-full bg-primary/5 backdrop-blur-md -z-10" />

              <button
                className="absolute top-4 right-4 p-2 rounded-full bg-gray-100 text-primary"
                onClick={() => setMenuView(false)}
              >
                <X size={24} />
              </button>

              <div className="mt-16 flex flex-col gap-5 w-full">
                {tabs.map((tab) => (
                  <div key={tab.id} className="border-b border-gray-100 pb-3">
                    <div
                      onClick={() => {
                        if (!tab.subTabs) handleTabClick(tab);
                        setMobileSubmenu(
                          tab.subTabs
                            ? mobileSubmenu === tab.id
                              ? null
                              : tab.id
                            : null
                        );
                      }}
                      className="text-lg font-medium text-primary cursor-pointer flex justify-between items-center py-2"
                    >
                      {tab.name}
                      {tab.subTabs && (
                        <motion.div
                          animate={{
                            rotate: mobileSubmenu === tab.id ? 180 : 0,
                          }}
                          transition={{ duration: 0.3 }}
                        >
                          <ChevronDown size={18} className="ml-2" />
                        </motion.div>
                      )}
                    </div>

                    {tab.subTabs && mobileSubmenu === tab.id && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={transition}
                        className="pl-4 mt-2"
                      >
                        {tab.subTabs.map((sub) => (
                          <motion.div
                            key={sub.id}
                            onClick={() => handleTabClick(sub)}
                            className="py-2.5 text-gray-700 hover:text-primary cursor-pointer"
                            whileHover={{ x: 4 }}
                            transition={{
                              type: "spring",
                              stiffness: 300,
                              damping: 30,
                            }}
                          >
                            {sub.name}
                          </motion.div>
                        ))}
                      </motion.div>
                    )}
                  </div>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
}

export default Navbar;
