import cinempro from "../../../Assets/image/cinemapro.png";
import cinestore from "../../../Assets/image/cinestore.png";
import doctor from "../../../Assets/image/Doctor.png";

const products = [
  {
    id: 1,
    name: "CinemaPro",
    url: "https://cinemapro.in/#/",
    img: cinempro,
    description:
      "CinemaPro is a premium platform designed to help film industry professionals manage their projects seamlessly, offering tools for collaboration, scheduling, and production tracking.",
    color: "bg-[#1D5B79]",
  },
  {
    id: 2,
    name: "MediCare Pro: Comprehensive Clinic Management System",
    url: "https://doc.softcodingsolutions.com/",
    img: doctor,
    description:
      "Doctor is a comprehensive clinic management platform that streamlines patient care, appointment scheduling, billing, and medical records management, improving the overall healthcare experience.",
    color: "bg-[#C8E6C9]",
  },
  {
    id: 4,
    name: "CineStore",
    url: "https://cinemapro.in/#/cinestore",
    img: cinestore,
    description:
      "CineStore is an innovative online marketplace that connects the film industry with vendors, crew, artists, and equipment rental services, offering a vast network for all production needs.",
    color: "bg-[#1D5B79]",
  },
];

export default products;
