import React from "react";
import Header from "../../../Components/job-opening/Head";
import AdminLayout from "../../../layout/admin-layout";
import AddJobOpeningForm from "../../../Components/job-opening/add-form/add-job-opening-form";
import { toast } from "sonner";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../Components/provider/user-id-provider";

function AddJobOpening() {
  const navigate = useNavigate();
   const {userId} = useUser();
  const onSubmit = (values: any) => {
    const loading = toast.loading("Adding ...");

    axios
      .post(`users/${userId}/job_openings`, {
        job_opening: {
          job_title: values.job_title,
          job_position: values.job_position,
          no_of_opening: values.no_of_opening,
          location: values.location,
          job_type: values.job_type,
          qualification: values.qualification,
          what_we_offer: values.what_we_offer,
          key_responsibilities: values.key_responsibilities,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          toast.dismiss(loading);
          toast.success("Job Opening Added Successfully");
          navigate(-1);
        }
      })
      .catch((err) => {
        toast.dismiss(loading);
        toast.error(err.response.data.message || err.message);
      });
  };
  return (
    <AdminLayout>
      <Header title="Add Job Opening" />
      <AddJobOpeningForm onSubmit={onSubmit} />
    </AdminLayout>
  );
}

export default AddJobOpening;
