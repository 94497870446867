import axios from "axios";
import Header from "../../../Components/blog-admin/admin-blog-header";
import AdminLayout from "../../../layout/admin-layout";
import { useEffect, useState } from "react";
import BlogTable from "../../../Components/blog-admin/blog-table.tsx/blog-table";
import Loader from "../../../Components/loader/loader";
import DetailBlogView from "../../../Components/blog-admin/blog-table.tsx/detail-blog-view";
import DeleteModal from "../../../Components/delete-modal/delete-modal";
import { toast } from "sonner";
import { useUser } from "../../../Components/provider/user-id-provider";

function Blog() {
  const [blogs, setBlogs] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState<"list" | "detailed">("list");
  const [Delete, setDelete] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState<any>({});
  const {userId} = useUser()

  const fetchData = () => {
    axios
      .get(`/blogs`)
      .then((res) => {
        setBlogs(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleDeleteBlog = () => {
    const loader = toast.loading("Deleting blog...");
    axios
      .delete(`/users/${userId}/blogs/${selectedBlog.id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loader);
          toast.success("Blog deleted successfully");
          fetchData();
          setDelete(false);
          setView("list");
          setSelectedBlog({});
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.response.data.message || err.message);
        console.log(err);
      });
  };

  const handleView = (blog: any) => {
    setView("detailed");
    setSelectedBlog(blog);
  };

  const handleDeleteView = (blog: any) => {
    setDelete(true);
    setSelectedBlog(blog);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <AdminLayout>
      <Header title="Blogs" />
      {loading ? (
        <Loader />
      ) : (
        <div className="w-full flex flex-row h-full">
          <div
            className={`${
              view === "detailed" ? "w-1/4 hidden md:flex" : "w-full"
            } px-3`}
          >
            <BlogTable
              data={blogs}
              view={view}
              onDeleteView={handleDeleteView}
              onView={handleView}
            />
          </div>
          {view === "detailed" && (
            <div className="w-full md:w-3/4 m-0 md:m-3 ">
              <DetailBlogView
                data={selectedBlog}
                setSelectedBlog={setSelectedBlog}
                setView={setView}
                onDeleteView={handleDeleteView}
              />
            </div>
          )}
        </div>
      )}
      {Delete && (
        <DeleteModal
          customMessage={`Do you want to remove this Blog:${selectedBlog.title} ?`}
          onCancel={() => {
            setDelete(false);
            setSelectedBlog({});
            setView("list");
          }}
          onDelete={handleDeleteBlog}
        />
      )}
    </AdminLayout>
  );
}

export default Blog;
