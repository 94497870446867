import React from "react";
import MainLayout from "../layout/main-layout";
import ReactJsHeadSection from "../Components/react-js/react-js-head-section";
import ReactJsBenefits from "../Components/react-js/react-js-benefits";
import ReactJsProcess from "../Components/react-js/react-js-process";
import WhyChooseReact from "../Components/react-js/why-choose-react";
import ReactContactSection from "../Components/react-js/react-contact-section";

function ReactJs() {
  return (
    <div>
      <MainLayout>
        <ReactJsHeadSection />
        <ReactJsBenefits />
        <ReactJsProcess />
        <WhyChooseReact />
        <ReactContactSection />
      </MainLayout>
    </div>
  );
}

export default ReactJs;
