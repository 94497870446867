import { ChevronLeft, Plus } from "lucide-react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "../input/icon-button";

function Header({ title }: { title: string | undefined }) {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="w-full border-b p-4 flex items-center justify-between">
      <div className="flex flex-row gap-2 items-center justify-center">
        {location.pathname !== "/admin/blog" && (
          <button
            onClick={() => navigate(-1)}
            className="outline-none bg-primary/50 text-white p-1 rounded-full"
          >
            <ChevronLeft />
          </button>
        )}
        <h2 className="text-xl tracking-wide font-medium capitalize">
          {title}
        </h2>
      </div>
      {location.pathname === "/admin/blog" && (
        <IconButton
          icon={<Plus />}
          title="Add Blog"
          onClick={() => navigate("/admin/blog/add-blog")}
        />
      )}
    </div>
  );
}

export default Header;
