import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminLayout from "../../../layout/admin-layout";
import Header from "../../../Components/job-opening/Head";
import UpdateJobForm from "../../../Components/job-opening/update-form/update-job-form";
import axios from "axios";
import { toast } from "sonner";
import Loader from "../../../Components/loader/loader";
import { useUser } from "../../../Components/provider/user-id-provider";

function UpdateJobOpening() {
  const [job, setJob] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();
   const {userId} = useUser();

  const fetchData = () => {
    axios
      .get(`job_openings/${id}`)
      .then((res) => {
        setJob(res.data.job_opening);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = (values: any) => {
    const loading = toast.loading("Updating ...");

    axios
      .put(`users/${userId}/job_openings/${id}`, {
        job_opening: {
          job_title: values.job_title,
          job_position: values.job_position,
          no_of_opening: values.no_of_opening,
          location: values.location,
          job_type: values.job_type,
          qualification: values.qualification,
          what_we_offer: values.what_we_offer,
          key_responsibilities: values.key_responsibilities,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loading);
          toast.success("Job Opening Updated Successfully");
          navigate(-1);
        }
      })
      .catch((err) => {
        toast.dismiss(loading);
        toast.error(err.response.data.message || err.message);
      });
  };

  return (
    <AdminLayout>
      <Header title="Update Job Opening" />
      {loading ? <Loader /> : <UpdateJobForm onSubmit={onSubmit} job={job} />}
    </AdminLayout>
  );
}

export default UpdateJobOpening;
