import React from "react";
import Grid from "../Grid/grid";
import { motion } from "framer-motion";
import { traits } from "./data/team-points";
import Title from "../title";

function TeamSection() {
  return (
    <section className="relative w-full min-h-screen flex flex-col overflow-hidden lg:p-16 p-8">
      <Grid size={100} direction="top-center" />
      <div className="z-10 flex justify-start items-start mb-12">
        <Title title="Team" />
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center">
        <div className="md:w-1/2 flex items-center justify-center">
          <motion.img
            src="https://images.unsplash.com/photo-1521898284481-a5ec348cb555?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            loading="lazy"
            alt="team"
            className="md:h-[700px] h-96 md:w-3/4 rounded-2xl"
          />
        </div>
        <div className="md:w-1/2 gap-8 flex flex-col items-start justify-start p-6">
          <motion.h2 className="text-xl md:text-2xl text-start font-semibold my-5">
            Passionate, Innovative & Driven Team
          </motion.h2>
          <motion.div
            className="flex flex-col gap-5"
            initial="hidden"
            whileInView="visible"
            variants={{
              hidden: { opacity: 0 },
              visible: {
                opacity: 1,
                transition: { staggerChildren: 0.1 },
              },
            }}
          >
            {traits.map((data, index) => (
              <motion.span
                key={index}
                variants={{
                  hidden: { opacity: 0, y: 10 },
                  visible: { opacity: 1, y: 0 },
                }}
                transition={{ duration: 0.4, ease: "easeOut" }}
                className="font-medium text-pretty leading-relaxed md:text-base text-sm"
              >
                {data.description}
              </motion.span>
            ))}
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default TeamSection;
