import { motion } from "framer-motion";
import Grid from "../Grid/grid";
import { ChevronRight } from "lucide-react";
import Title from "../title";

function HeadSection({ product }: { product: any }) {
  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen px-4 sm:px-6 md:px-10 lg:px-16 py-10">
      <Grid size={100} direction="full" />
      <div className="z-10 flex flex-col items-start mt-12 sm:mt-16 md:mt-20 w-full">
        <Title title="Our Product" />
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        className="w-full flex flex-col items-start gap-6 px-4 sm:px-6 md:px-12 lg:px-20 py-6 sm:py-8"
      >
        <motion.h2
          className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold text-gray-900 leading-tight"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.6, ease: "easeOut" }}
        >
          {product?.title}
        </motion.h2>
        <p className="text-sm sm:text-base md:text-lg lg:text-xl text-gray-800 leading-relaxed">
          {product?.description}
        </p>
      </motion.div>

      <div className="mt-8 w-full px-4 sm:px-6 md:px-12 lg:px-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col p-4 sm:p-6">
            <motion.h2
              className="text-lg sm:text-xl font-medium"
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              Challenges
            </motion.h2>
            <div className="flex flex-col gap-3 mt-4">
              {product?.challenges?.map((data: any, index: number) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{
                    delay: index * 0.1,
                    duration: 0.4,
                    ease: "easeOut",
                  }}
                  className="text-sm sm:text-base md:text-lg flex flex-row items-start gap-2"
                >
                  <ChevronRight className="size-4 min-w-4 mt-1" />
                  {data}
                </motion.div>
              ))}
            </div>
          </div>

          <div className="flex flex-col p-4 sm:p-6">
            <motion.h2
              className="text-lg sm:text-xl font-medium"
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              Solutions
            </motion.h2>
            <div className="flex flex-col gap-3 mt-4">
              {product?.solutions?.map((data: any, index: number) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: 20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{
                    delay: index * 0.1,
                    duration: 0.4,
                    ease: "easeOut",
                  }}
                  className="text-sm sm:text-base md:text-lg flex flex-row items-start gap-2"
                >
                  <ChevronRight className="size-4 min-w-4 mt-1" />
                  {data}
                </motion.div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col p-4 sm:p-6 w-full mt-6">
          <motion.h2
            className="text-lg sm:text-xl font-medium"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, ease: "easeOut" }}
          >
            Approach
          </motion.h2>
          <div className="flex flex-col gap-3 mt-4">
            {product?.approach?.map((data: any, index: number) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{
                  delay: index * 0.1,
                  duration: 0.4,
                  ease: "easeOut",
                }}
                className="text-sm sm:text-base md:text-lg flex flex-row items-start gap-2"
              >
                <ChevronRight className="size-4 min-w-4 mt-1" />
                {data}
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeadSection;
