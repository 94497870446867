import { ChevronLeft, ChevronRight } from "lucide-react";
import ReactPaginate from "react-paginate";

function Pagination({
  currentPage,
  totalPages,
  handlePageChange,
}: {
  currentPage: number;
  totalPages: number;
  handlePageChange: ({ selected }: { selected: any }) => void;
}) {
  return (
    <div className="flex flex-row items-center select-none">
      <ReactPaginate
        pageCount={totalPages}
        onPageChange={handlePageChange}
        containerClassName={"flex items-center justify-center gap-4"}
        previousLabel={<ChevronLeft />}
        nextLabel={<ChevronRight />}
        activeClassName={"active"}
        previousClassName={
          "p-2 border rounded-full text-primary hover:bg-primary/30 hover:border-primary ease-in duration-300"
        }
        nextClassName={
          "p-2 border rounded-full text-primary hover:bg-primary/30 hover:border-primary ease-in duration-300"
        }
        pageClassName={"hidden"}
        pageLinkClassName={"hidden"}
        breakClassName={"hidden"}
        renderOnZeroPageCount={null}
        breakLinkClassName={"px-8 py-1 bg-gray-200 text-gray-600 rounded"}
      />
      <div className="mx-2 text-primary">
        Page {currentPage + 1} of {totalPages}
      </div>
    </div>
  );
}

export default Pagination;
