import { Instagram, Linkedin, Mail } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import cinemapro from "../../Assets/image/cinemapro.png";
import cinestore from "../../Assets/image/cinestore.png";
import doctor from "../../Assets/image/Doctor.png";
import img1 from "../../Assets/image/image (1).png";
import img2 from "../../Assets/image/clutch.png";
import img3 from "../../Assets/image/image (3).png";
import img4 from "../../Assets/image/image (4).png";

interface SubTab {
  id: number;
  name: string;
  product_id?: number;
  path?: string;
  img?: string;
  brief?: string;
}

interface Tab {
  id: number;
  name: string;
  path?: string;
  subTabs?: SubTab[];
}

function Footer() {
  const navigate = useNavigate();
  const year = new Date();

  const tabs: Tab[] = [
    {
      id: 1,
      name: "Company",
      subTabs: [
        { id: 1.1, name: "About Us", path: "/about" },
        {
          id: 1.2,
          name: "Our Work",
          path: "/our-work",
        },
        // {
        //   id: 1.3,
        //   name: "Blogs",
        //   path: "/blog",
        // },
        {
          id: 1.4,
          name: "Hire A Developer",
          path: "/hire-a-developer",
        },
        {
          id: 1.5,
          name: "Careers",
          path: "/careers",
        },
        {
          id: 1.6,
          name: "Contact US",
          path: "/contact-us",
        },
      ],
    },
    {
      id: 2,
      name: "Services",
      subTabs: [
        {
          id: 2.1,
          name: "Ruby on Rails Development",
          path: "/ruby-on-rails-development-company",
          brief:
            "Unlock the power of Ruby on Rails to build robust, secure, and high-performance web applications. Our expert developers ensure rapid development, seamless scalability, and cost-effective solutions tailored to your business needs.",
        },
        {
          id: 2.2,
          name: "Web Development",
          path: "/web-development-company",
          brief:
            "Transform your digital presence with cutting-edge web development. We craft responsive, high-speed, and conversion-driven websites designed to maximize engagement, enhance user experience, and drive business growth.",
        },
        {
          id: 2.3,
          name: "Custom Software Development",
          path: "/custom-software-development-company",
          brief:
            "Empower your business with tailor-made software solutions that streamline operations, boost efficiency, and scale effortlessly. We build intelligent, future-proof software that adapts to your unique business requirements.",
        },
        {
          id: 2.4,
          name: "React JS Development",
          path: "/react-js-development-company",
          brief:
            "Deliver fast, interactive, and seamless web experiences with React JS. Our expert developers create dynamic user interfaces that enhance performance, scalability, and engagement across all devices.",
        },
        {
          id: 2.5,
          name: "Mobile App Development",
          path: "/mobile-app-development-company",
          brief:
            "Turn your app idea into a reality with high-performance, feature-rich mobile applications for iOS and Android. We focus on user-centric design, scalability, and advanced functionality to ensure a seamless mobile experience.",
        },
        {
          id: 2.6,
          name: "Content Management System",
          path: "/content-management-system",
          brief:
            "Simplify content creation, editing, and publishing with a powerful and intuitive CMS. Manage workflows efficiently, enhance collaboration, and maintain brand consistency with a system built for your business needs.",
        },
        {
          id: 2.7,
          name: "UI/UX Design",
          path: "/ui-ux-design-company",
          brief:
            "Make a lasting impression with stunning, user-friendly designs that elevate engagement and drive conversions. Our UI/UX experts craft intuitive digital experiences that enhance usability, accessibility, and brand identity.",
        },
      ],
    },
    {
      id: 3,
      name: "Products",
      subTabs: [
        {
          id: 3.1,
          product_id: 1,
          name: "CinemaPro",
          img: cinemapro,
          brief:
            "CinemaPro is a premium platform designed to help film industry professionals manage their projects seamlessly, offering tools for collaboration, scheduling, and production tracking.",
        },
        {
          id: 3.2,
          name: "MediCare Pro",
          product_id: 2,
          img: doctor,
          brief:
            "Doctor is a comprehensive clinic management platform that streamlines patient care, appointment scheduling, billing, and medical records management, improving the overall healthcare experience.",
        },
        {
          id: 3.3,
          name: "CineStore",
          product_id: 3,
          img: cinestore,
          brief:
            "CineStore is an innovative online marketplace that connects the film industry with vendors, crew, artists, and equipment rental services, offering a vast network for all production needs.",
        },
      ],
    },
  ];

  const logos = [
    { img: img1, url: "https://softcodingsolutions.com/" },
    {
      img: img2,
      url: "https://clutch.co/profile/softcoding-solutions?_gl=1*15eqwr6*_gcl_au*MzE0NjAzNzAxLjE3NDA5ODU4Nzg.*_ga*MTMwMjY3NTkwMi4xNzQwOTg1ODc4*_ga_D0WFGX8X3V*MTc0MDk4NTg3Ny4xLjAuMTc0MDk4NTg4NS41Mi4wLjEzMDY0NTk5MzQ.#reviews",
    },
    { img: img3, url: "https://www.goodfirms.co/" },
    { img: img4, url: "https://businessfirms.co/company/softcoding-solutions" },
  ];

  const handleTabClick = (tab: Tab | SubTab) => {
    if (tab.path) {
      navigate(tab.path);
    } else if ("product_id" in tab && tab.product_id) {
      navigate(`/product/${tab.name}/${tab.product_id}`);
    }
  };

  return (
    <footer className="w-full border-t relative overflow-hidden text-[#e2e8f0]">
      <div className="w-full relative">
        <div className="w-full h-full bg-gradient-to-bl from-primary via-primary/80 to-primary/40 absolute -z-10" />
        <div className="max-w-[1400px] mx-auto px-6 py-12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 relative">
          {tabs.map((tab) => (
            <div key={tab.id}>
              <h3 className="font-bold uppercase text-lg text-gray-200">
                {tab.name}
              </h3>
              <ul className="space-y-2 mt-2">
                {tab.subTabs?.map((subTab) => (
                  <li onClick={() => handleTabClick(subTab)} key={subTab.id}>
                    <div className="text-[#161616] hover:text-gray-200 transition duration-300 cursor-pointer font-medium">
                      {subTab.name}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}

          <div>
            <h3 className="font-bold text-lg text-gray-200">FOLLOW US</h3>
            <ul className="flex space-x-4 mt-2">
              <li>
                <Link
                  to="https://www.instagram.com/softcodingsolution?igsh=MTYwNHduYnl1ZGZ0"
                  target="_blank"
                  className="text-[#161616] hover:text-gray-200"
                >
                  <Instagram size={20} />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.linkedin.com/company/softcoding-solutions/"
                  target="_blank"
                  className="text-[#161616] hover:text-gray-200"
                >
                  <Linkedin size={20} />
                </Link>
              </li>
            </ul>
            <div className="flex flex-col gap-3 mt-4">
              <h3 className="font-bold text-lg text-gray-200 flex gap-2 items-center">
                <Mail size={20} /> EMAIL
              </h3>
              <p className="text-[#161616] hover:text-gray-200 font-medium text-xs md:text-base flex items-center gap-2">
                Sales: <span>sales@softcodingsolutions.com</span>
              </p>
              <p className="text-[#161616] hover:text-gray-200 font-medium text-xs md:text-base flex items-center gap-2">
                General: <span>support@softcodingsolutions.com</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6 py-8">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0 text-center md:text-left">
          <div className="space-y-2">
            <p className="text-sm font-semibold text-[#161616]">
              &copy;{year.getFullYear()} Softcoding Solutions. All rights
              reserved.
            </p>
            <p className="text-sm text-[#161616] max-w-md">
              Softcoding Solutions is an IT company dedicated to empowering
              businesses with innovative technology.
            </p>
          </div>

          <div className="flex flex-wrap justify-center md:justify-start items-center gap-4">
            {logos.map((data, index) => (
              <Link to={data.url} target="_blank" key={index}>
                <img
                  src={data.img}
                  alt="Logo"
                  className="object-contain h-16 w-16 bg-white rounded-full"
                />
              </Link>
            ))}
          </div>

          <div className="flex space-x-4">
            <Link to="#" className="text-sm text-[#161616] hover:underline">
              Terms
            </Link>
            <Link to="#" className="text-sm text-[#161616] hover:underline">
              Privacy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
