import { ChevronLeft, Pencil, Trash } from "lucide-react";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import EditorJS from "@editorjs/editorjs";
import ViewEditor from "../../Editor/view-editor";

function DetailBlogView({
  data,
  setSelectedBlog,
  setView,
  onDeleteView,
}: {
  data: any;
  setSelectedBlog: React.Dispatch<any>;
  setView: React.Dispatch<React.SetStateAction<"list" | "detailed">>;
  onDeleteView: (blog: any) => void;
}) {
  const editorRef = useRef<EditorJS | null>(null);
  const navigate = useNavigate();

  return (
    <div className="w-full h-auto md:rounded-2xl shadow-md flex flex-col md:border overflow-y-auto">
      <div className="w-full border-b p-3 flex flex-wrap justify-between items-center gap-3">
        <div className="flex gap-2 items-center justify-center">
          <button
            onClick={() => {
              setSelectedBlog({});
              setView("list");
            }}
            className="outline-none border-2 p-2 rounded-full"
          >
            <ChevronLeft />
          </button>
          <h2 className="text-base md:text-lg font-bold">{data?.title}</h2>
        </div>
        <div className="flex gap-2">
          <button
            onClick={() =>
              navigate(`/admin/blog/update-blog/${data.id}/${data.title}`)
            }
            className="border-2 border-green-200 text-green-400 p-2 rounded-xl outline-none"
          >
            <Pencil />
          </button>
          <button
            onClick={() => onDeleteView(data)}
            className="border-2 border-red-200 text-red-400 p-2 rounded-xl outline-none"
          >
            <Trash />
          </button>
        </div>
      </div>
      <div className="p-5 flex flex-col w-full gap-3">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div className="flex flex-col items-start justify-start gap-3 border p-3 rounded-2xl">
            <h2 className="font-medium text-gray-600">Brief</h2>
            <p className="text-sm text-pretty leading-relaxed">{data?.brief}</p>
          </div>
          <div className="flex flex-col items-start justify-start gap-3 border p-3 rounded-2xl">
            <h2 className="font-medium text-gray-600">Poster Image</h2>
            <img
              className="w-full aspect-square rounded-xl h-80"
              src={`${data?.poster_img_url}`}
              alt="blog-img
              "
            />
          </div>
        </div>
      </div>
      <div className="px-5">
        <ViewEditor editorRef={editorRef} data={data?.content} />
      </div>
    </div>
  );
}

export default DetailBlogView;
