import React from "react";
import Grid from "../Grid/grid";
import { motion } from "framer-motion";

function ImgSection({ product }: { product: any }) {
  return (
    <div className="relative flex flex-col items-center justify-center md:h-[70vh] overflow-hidden">
      <Grid size={100} direction="top-center" />
      <motion.img
        initial={{ scale: 3 }}
        whileInView={{ scale: 1 }}
        exit={{ scale: 3 }}
        transition={{
          duration: 1,
          ease: "easeIn",
          stiffness: 100,
          damping: 20,
        }}
        src={product?.main_img}
        alt="Product"
        className="h-full w-full"
      />
      <div className="absolute h-full w-full bg-black/5" />
    </div>
  );
}

export default ImgSection;
