import React from "react";
import Grid from "../Grid/grid";
import Title from "../title";
import webBenefits from "./data/web-benefits";

function WebDevelopmentBenefits() {
  return (
    <section className="w-full min-h-screen h-full relative flex flex-col overflow-hidden">
      <Grid size={100} direction="top-center" />
      <div className="p-2 lg:p-16 flex flex-col w-full items-center justify-center">
        <div className="z-10 flex justify-start items-start mb-12 w-full">
          <Title title="Web Development, Tailored for You" />
        </div>
        <h2 className="md:text-4xl text-xl font-medium md:font-light max-w-4xl text-center">
          Create Innovative & High-Quality Products That Suit Your Business
          Needs.
        </h2>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div
          className="w-full bg-cover block mt-5"
          style={{
            backgroundImage: `url(https://images.unsplash.com/photo-1597848480737-4747cf6dc9b5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
          }}
        >
          <div className="grid md:grid-cols-3 bg-black/60">
            {webBenefits.map((data, index) => (
              <div className="p-12 border border-white flex flex-col gap-8">
                <div className="flex flex-row items-center gap-4">
                  <p className="font-bold text-6xl text-white">0{index + 1}</p>
                  <h2 className="text-white text-2xl">{data.title}</h2>
                </div>
                <div>
                  <p className="text-white text-lg">{data.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default WebDevelopmentBenefits;
