import React from "react";
import Grid from "../Grid/grid";
import Title from "../title";
import ContactForm from "../contact-form/contact-form";

function CmsContactSection() {
  return (
    <section className="w-full min-h-screen h-full relative flex flex-col overflow-hidden p-2 lg:p-16">
      <Grid size={100} direction="top-center" />
      <div className="z-10 flex justify-start items-start mb-12 w-full">
        <Title title="Get in touch" />
      </div>
      <div className="flex md:flex-row flex-col justify-center">
        <div className="flex flex-col items-start justify-center gap-5 md:w-1/2">
          <h2 className="md:text-4xl text-xl font-medium max-w-4xl">
            Let's build CMS that works for you.
          </h2>
          <p className="text-gray-500">
            Contact us today to discuss your needs, and let’s create a powerful,
            custom CMS solution tailored specifically to your business.
          </p>
        </div>
        <div className="mt-5 flex items-center justify-center md:w-1/2">
          <ContactForm title="Hire CMS Developer" purpose="CMS Development" />
        </div>
      </div>
    </section>
  );
}

export default CmsContactSection;
