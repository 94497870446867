import React, { useState } from "react";
import usePagination from "../Pagination/use-pagination";
import Pagination from "../Pagination/pagination";
import { X } from "lucide-react";

function VisiorQueryTable({ data }: { data: any[] }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVisitor, setSelectedVisitor] = useState<any | null | Object>(
    null
  );
  const { currentItems, currentPage, handlePageChange, totalPages } =
    usePagination(data, 10);

  const openModal = (visitor: any) => {
    setSelectedVisitor(visitor);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVisitor(null);
  };

  return (
    <div className="rounded-2xl shadow-md border mt-3 w-full">
      <div className="w-full overflow-x-auto">
        <table className="w-full rounded-2xl">
          <thead className="border-b uppercase">
            <tr>
              <th className="py-3 px-2 font-medium text-xs md:text-sm text-center">
                Sr.no
              </th>
              <th className="py-3 px-2 font-medium text-xs md:text-sm text-left">
                Name
              </th>
              <th className="py-3 px-2 font-medium text-xs md:text-sm text-left">
                Contact Number
              </th>
              <th className="py-3 px-2 font-medium text-xs md:text-sm text-left">
                Email
              </th>
              <th className="py-3 px-2 font-medium text-xs md:text-sm text-left">
                Purpose
              </th>
              <th className="py-3 px-2 font-medium text-xs md:text-sm text-left">
                Company Name
              </th>
              <th className="py-3 px-2 font-medium text-xs md:text-sm text-left">
                Message
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {currentItems.map((data, index) => (
              <tr key={index} className={`cursor-pointer bg-gray-50`}>
                <td className="py-3 px-2 text-sm text-center">{index + 1}</td>
                <td className="py-3 px-2 text-sm text-left">{data.name}</td>
                <td className="py-3 px-2 text-sm text-left">
                  {data.contact_no || "-"}
                </td>
                <td className="py-3 px-2 text-sm text-left">
                  {data.email || "-"}
                </td>
                <td className="py-3 px-2 text-sm text-left">
                  {data.purpose || "-"}
                </td>
                <td className="py-3 px-2 text-sm text-left">
                  {data.company_name || "-"}
                </td>
                <td className="py-3 px-2 text-sm text-left">
                  <button
                    onClick={() => openModal(data)}
                    disabled={!data.message}
                    className="font-semibold hover:underline text-blue-500 disabled:text-slate-500 disabled:cursor-not-allowed outline-none"
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className=" p-3">
        <Pagination
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalPages={totalPages}
        />
      </div>

      {isModalOpen && selectedVisitor && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50"
          onClick={closeModal}
        >
          <div
            className="bg-white rounded-lg shadow-lg max-w-md w-full max-h-[90vh] overflow-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex items-center justify-between p-4 border-b">
              <h3 className="text-lg font-semibold">{selectedVisitor.name}</h3>
              <button
                onClick={closeModal}
                className="p-1 rounded-full hover:bg-gray-100 transition-colors"
              >
                <X className="h-5 w-5" />
                <span className="sr-only">Close</span>
              </button>
            </div>
            <div className="p-4">
              <div className="prose max-w-none">
                <p className="whitespace-pre-wrap">{selectedVisitor.message}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VisiorQueryTable;
