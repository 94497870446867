import React from "react";
import MainLayout from "../layout/main-layout";
import UiUxHeadSection from "../Components/UI-UX/ui-ux-head-section";
import UiUxBenefits from "../Components/UI-UX/ui-ux-benefits";
import UiUxDesignProcess from "../Components/UI-UX/ui-ux-design-process";
import WhyChooseUiUx from "../Components/UI-UX/why-choose-us-ui-ux";
import UiUxContact from "../Components/UI-UX/ui-ux-contact";

function UiUxDesign() {
  return (
    <div>
      <MainLayout>
        <UiUxHeadSection />
        <UiUxBenefits />
        <UiUxDesignProcess />
        <WhyChooseUiUx />
        <UiUxContact />
      </MainLayout>
    </div>
  );
}

export default UiUxDesign;
