import AdminLayout from "../../../layout/admin-layout";
import Header from "../../../Components/our-work-admin/our-work-admin-header";
import AddWorkForm from "../../../Components/our-work-admin/add-form/add-work-form";
import axios from "axios";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../Components/provider/user-id-provider";

function AddWork() {
  const navigate = useNavigate();
  const { userId } = useUser();

  const handleSubmit = (values: any) => {
    const loading = toast.loading("Adding ...");

    const is_case_study: boolean | null | any = values.is_case_study
      ? true
      : false;

    const formData = new FormData();
    formData.append("case_study[title]", values.title);
    formData.append("case_study[domain]", values.domain);
    formData.append("case_study[is_case_studies]", is_case_study);
    formData.append("case_study[brief]", values.brief);
    formData.append("case_study[description]", values.description);
    formData.append("case_study[problem_statement]", values.problem_statement);
    formData.append("case_study[main_image]", values.main_image);
    values.other_images.forEach((data: any, index: any) => {
      formData.append("case_study[other_images][]", data);
    });
    formData.append("case_study[frontend_tech]", values.frontend_tech);
    formData.append("case_study[backend_tech]", values.backend_tech);
    formData.append("case_study[database_tech]", values.database_tech);
    values.challenges.forEach((data: any, index: any) => {
      formData.append("case_study[challenges][]", data);
    });
    values.solutions.forEach((data: any, index: any) => {
      formData.append("case_study[solutions][]", data);
    });
    values.approach.forEach((data: any, index: any) => {
      formData.append("case_study[approach][]", data);
    });
    values.key_features.forEach((data: any, index: any) => {
      formData.append(
        `case_study[key_features][${index}][feature]`,
        data.feature
      );
      formData.append(
        `case_study[key_features][${index}][description]`,
        data.description
      );
    });

    axios
      .post(`users/${userId}/case_studies`, formData)
      .then((res) => {
        if (res.status === 201) {
          toast.dismiss(loading);
          toast.success("Work Added Successfully");
          navigate(-1);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(loading);
        toast.error(err.response.data.message || err.message);
      });
  };

  return (
    <AdminLayout>
      <Header title="add work" />
      <AddWorkForm onSubmit={handleSubmit} />
    </AdminLayout>
  );
}

export default AddWork;
