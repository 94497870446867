import React, { useEffect, useState } from "react";
import AdminLayout from "../../../layout/admin-layout";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../Components/our-work-admin/our-work-admin-header";
import axios from "axios";
import Loader from "../../../Components/loader/loader";
import UpdateWorkForm from "../../../Components/our-work-admin/update-form/update-work-form";
import { toast } from "sonner";
import { useUser } from "../../../Components/provider/user-id-provider";

function UpdateWork() {
  const [loading, setLoading] = useState(true);
  const [work, setWork] = useState();
  const { id, title } = useParams();
  const navigate = useNavigate();
   const {userId} = useUser();

  const fetchWork = () => {
    axios
      .get(`users/${userId}/case_studies/${id}`)
      .then((res) => {
        setWork(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchWork();
  }, []);

  const updateWork = (values: any) => {
    const loading = toast.loading("Updating ...");

    const formData = new FormData();
    formData.append("case_study[title]", values.title);
    formData.append("case_study[brief]", values.brief);
    formData.append("case_study[domain]", values.domain);
    formData.append("case_study[is_case_studies]", values.is_case_study);
    formData.append("case_study[description]", values.description);
    formData.append("case_study[problem_statement]", values.problem_statement);
    if (values.main_image) {
      formData.append("case_study[main_image]", values.main_image);
    }
    if (values.other_images) {
      values.other_images.forEach((data: any, index: any) => {
        formData.append("case_study[other_images][]", data);
      });
    }
    formData.append("case_study[frontend_tech]", values.frontend_tech);
    formData.append("case_study[backend_tech]", values.backend_tech);
    formData.append("case_study[database_tech]", values.database_tech);
    values.challenges.forEach((data: any, index: any) => {
      formData.append("case_study[challenges][]", data);
    });
    values.solutions.forEach((data: any, index: any) => {
      formData.append("case_study[solutions][]", data);
    });
    values.approach.forEach((data: any, index: any) => {
      formData.append("case_study[approach][]", data);
    });
    values.key_features.forEach((data: any, index: any) => {
      formData.append(
        `case_study[key_features][${index}][feature]`,
        data.feature
      );
      formData.append(
        `case_study[key_features][${index}][description]`,
        data.description
      );
    });

    axios
      .put(`users/${userId}/case_studies/${id}`, formData)
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loading);
          toast.success("Work Updated Successfully");
          navigate(-1);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(loading);
        toast.error(err.response.data.message || err.message);
      });
  };

  return (
    <AdminLayout>
      <Header title={title} />
      {loading ? (
        <Loader />
      ) : (
        <div className="w-full">
          <UpdateWorkForm work={work} onSubmit={updateWork} />
        </div>
      )}
    </AdminLayout>
  );
}

export default UpdateWork;
