import {
  ClipboardList,
  Layout,
  Code,
  ShieldCheck,
  Rocket,
  LifeBuoy,
} from "lucide-react";

const mobileAppDevelopmentProcess = [
  {
    step: 1,
    title: "Consultation & Requirement Gathering",
    icon: <ClipboardList />,
    description:
      "We begin by understanding your business goals, target audience, and the key features your app should have. This ensures we create a solution that aligns with your vision.",
  },
  {
    step: 2,
    title: "UI/UX Design",
    icon: <Layout />,
    description:
      "Our design team creates intuitive, user-centric designs that prioritize user experience and interface simplicity. We focus on creating wireframes, prototypes, and high-fidelity designs to ensure the app is functional and visually appealing.",
  },
  {
    step: 3,
    title: "Development",
    icon: <Code />,
    description:
      "Our expert developers use Flutter or React Native to build your app, ensuring that it runs smoothly on iOS and Android devices. We emphasize high-quality code, performance, and scalability to create a proof solution.",
  },
  {
    step: 4,
    title: "Testing & Quality Assurance",
    icon: <ShieldCheck />,
    description:
      "We perform rigorous testing across multiple devices to ensure the app functions as expected. Our QA conducts tests for usability, performance, security, and compatibility to ensure a bug-free experience.",
  },
  {
    step: 5,
    title: "Deployment & Launch",
    icon: <Rocket />,
    description:
      "Once your app is fully developed and tested, we handle the deployment process, submitting the app to App Store and Google Play for approval and ensuring everything is set for a smooth launch.",
  },
  {
    step: 6,
    title: "Post-Launch Support & Maintenance",
    icon: <LifeBuoy />,
    description:
      "We provide ongoing support and maintenance to ensure your app stays updated with new features, bug fixes, and platform updates. Our team ensures that your app evolves with your business and user needs.",
  },
];

export default mobileAppDevelopmentProcess;
