import { motion } from "framer-motion";

function Title({ title }: { title: string }) {
  return (
    <motion.h2
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      className="inline-flex items-center capitalize justify-center px-6 py-2 text-xs md:text-lg font-semibold text-black bg-white border-black border-2 rounded-full mb-8"
    >
      {title}
    </motion.h2>
  );
}

export default Title;
