import { motion } from "framer-motion";

function PartnerCard({
  icon: Icon,
  title,
  description,
  number,
}: {
  icon: any;
  title: string;
  description: string;
  number: number;
}) {
  return (
    <motion.div
      className="bg-white/90 cursor-pointer backdrop-blur-lg rounded-2xl p-6 md:p-8 shadow-xl hover:shadow-2xl transition-all duration-300 ease-in-out transform hover:scale-105 border border-gray-200 hover:border-gray-300"
      initial={{ opacity: 0, x: -30 }}
      whileInView={{
        opacity: 1,
        x: 0,
      }}
      transition={{
        duration: 0.2,
        delay: 0.1 * number,
      }}
      key={number}
    >
      <div className="flex items-center space-x-5 mb-5">
        <div className="bg-primary/10 p-4 rounded-xl">
          <Icon className="w-8 h-8 text-primary" />
        </div>
        <span className="text-primary text-2xl font-semibold">{title}</span>
      </div>
      <p className="text-gray-700 text-base">{description}</p>
    </motion.div>
  );
}

export default PartnerCard;
