import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminLayout from "../../../layout/admin-layout";
import Header from "../../../Components/blog-admin/admin-blog-header";
import Loader from "../../../Components/loader/loader";
import UpdateBlogForm from "../../../Components/blog-admin/update-form/update-blog-form";
import { toast } from "sonner";
import { useUser } from "../../../Components/provider/user-id-provider";

function UpdateBlog() {
  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState();
  const { id, title } = useParams();
  const navigate = useNavigate();
   const {userId} = useUser();

  const fetchBlog = () => {
    axios
      .get(`blogs/${id}`)
      .then((res) => {
        setBlog(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchBlog();
  }, []);

  const handleUpdateBlog = (values: any) => {
    const loader = toast.loading("Updating blog...");

    const formData = new FormData();
    formData.append("blog[title]", values.title);
    formData.append("blog[brief]", values.brief);
    if (values.poster_img) {
      formData.append("blog[poster_img]", values.poster_img);
    }
    formData.append("blog[date]", values.date);
    formData.append("blog[written_by]", values.written_by);
    formData.append("blog[content]", values.content);

    axios
      .put(`users/${userId}/blogs/${id}`, formData)
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loader);
          toast.success("Blog Updated successfully");
          navigate(-1);
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.response.data.message || err.message);
        console.log(err);
      });
  };

  return (
    <AdminLayout>
      <Header title={title} />
      {loading ? (
        <Loader />
      ) : (
        <UpdateBlogForm blog={blog} onSubmit={handleUpdateBlog} />
      )}
    </AdminLayout>
  );
}

export default UpdateBlog;
