const rubyOnRailsBenefits = [
    {
      id: 1,
      title: "Faster Development & Time-to-Market",
      description:
        "RoR’s Convention over Configuration (CoC) and Don't Repeat Yourself (DRY) principles enable developers to write less code, speeding up development and reducing time-to-market. Pre-built modules and libraries allow rapid prototyping, letting you focus on core business logic instead of reinventing the wheel.",
    },
    {
      id: 2,
      title: "Scalability & Performance",
      description:
        "Ruby on Rails is built to handle high traffic loads and can easily scale with growing application demands. Features like caching, background jobs, and optimized database queries ensure smooth performance even under heavy user traffic.",
    },
    {
      id: 3,
      title: "Cost-Effective Development",
      description:
        "The abundance of open-source gems (plugins) and Rails' rapid development capabilities significantly reduce costs. With a strong developer community contributing reusable tools, building robust, feature-rich applications is more efficient and budget-friendly.",
    },
    {
      id: 4,
      title: "Security-First Approach",
      description:
        "Ruby on Rails includes built-in security measures like SQL injection protection, cross-site scripting (XSS), and cross-site request forgery (CSRF) protection. Regular security updates from the Rails community keep applications safeguarded against vulnerabilities.",
    },
    {
      id: 5,
      title: "Active & Supportive Community",
      description:
        "With a large, active developer community, Ruby on Rails offers continuous improvements, extensive documentation, and a wealth of tutorials and libraries. Proven solutions and best practices accelerate development and ensure reliable implementations.",
    },
    {
      id: 6,
      title: "Flexibility for Diverse Applications",
      description:
        "From CMS and e-commerce platforms to social networks and custom SaaS applications, Ruby on Rails is highly versatile. Its compatibility with various databases, web servers, and third-party services makes it adaptable to diverse project requirements.",
    },
  ];
  
  export default rubyOnRailsBenefits;
  