const UiUxBenefitsData = [
  {
    id: 1,
    title: "User-Centered Design Approach",
    description:
      "Our UI/UX design process focuses on user behavior and preferences to create intuitive, engaging, and seamless experiences. We prioritize usability, accessibility, and aesthetics to maximize user satisfaction and retention.",
  },
  {
    id: 2,
    title: "Enhanced Brand Identity",
    description:
      "Strong visuals and cohesive design elements help establish a compelling brand identity. Our UI/UX designs create memorable digital experiences that resonate with your audience and strengthen brand recognition.",
  },
  {
    id: 3,
    title: "Optimized for Conversions",
    description:
      "We craft user-friendly interfaces that guide users effortlessly through the journey, leading to higher engagement and conversion rates. Every design decision is backed by research to enhance usability and drive business growth.",
  },
  {
    id: 4,
    title: "Seamless Cross-Platform Experience",
    description:
      "We ensure that your designs are responsive and work flawlessly across all devices, from desktops to mobile apps. Consistent design elements and smooth navigation create a cohesive experience across platforms.",
  },
  {
    id: 5,
    title: "Wireframes & Prototyping",
    description:
      "We provide interactive wireframes and prototypes to visualize the user journey before development begins. This helps refine the design, gather feedback early, and ensure an efficient development process.",
  },
  {
    id: 6,
    title: "Data-Driven UX Enhancements",
    description:
      "Using analytics, A/B testing, and user feedback, we continuously refine the user experience. Our data-driven approach ensures that your UI/UX evolves with user needs and industry trends.",
  },
];

export default UiUxBenefitsData;
