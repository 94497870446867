import React from "react";

const Switch = ({
  isOn,
  handleToggle,
  activeColor = "bg-green-500",
  inactiveColor = "bg-gray-300",
  size = "medium",
}: {
  isOn: boolean;
  handleToggle: () => void;
  activeColor?: string;
  inactiveColor?: string;
  size?: "small" | "medium" | "large";
}) => {
  const sizeClasses: any = {
    small: { width: 28, height: 18, handle: 12, labelSize: 12, gap: 4 },
    medium: { width: 48, height: 28, handle: 20, labelSize: 16, gap: 6 },
    large: { width: 64, height: 36, handle: 28, labelSize: 20, gap: 10 },
  };

  const selectedSize = sizeClasses[size] || sizeClasses.medium;

  return (
    <div style={{ gap: selectedSize.gap }} className="flex items-center">
      <div
        className={`flex items-center rounded-full cursor-pointer relative ${
          isOn ? activeColor : inactiveColor
        }`}
        onClick={handleToggle}
        role="button"
        aria-pressed={isOn}
        style={{
          width: selectedSize.width,
          height: selectedSize.height,
        }}
      >
        <div
          className="bg-white rounded-full shadow ease-in duration-200"
          style={{
            width: selectedSize.handle,
            height: selectedSize.handle,
            transform: `translateX(${
              isOn ? selectedSize.width - selectedSize.handle - 3 : 3
            }px)`,
            transition: "transform 0.2s ease-in-out",
          }}
        />
      </div>
    </div>
  );
};

export default Switch;
