import { motion } from "framer-motion";
import Grid from "../Grid/grid";
import Title from "../title";
import clientLogos from "./data/client-image";

function HappyClient() {
  const logoWidth = 150;
  const totalWidth = clientLogos.length * logoWidth;

  return (
    <section className="relative h-auto w-full flex flex-col overflow-hidden px-4 sm:px-6 md:px-10 lg:px-16 py-8 sm:py-12">
      <Grid size={100} direction="top-center" />
      <div className="z-10 flex justify-start items-start mb-8 sm:mb-12">
        <Title title="Happy Clients" />
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="z-10 flex flex-col items-center justify-center mb-10 sm:mb-16">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold mb-2 sm:mb-3">Happy Clients</h2>
          <p className="text-base sm:text-lg md:text-xl text-gray-600 max-w-2xl text-center">
            At the core of our success are happy clients, who trust us to deliver exceptional results and outstanding service every time.
          </p>
        </div>
        <motion.div
          className="flex"
          animate={{ x: [0, -totalWidth] }}
          transition={{
            x: {
              repeat: Number.POSITIVE_INFINITY,
              repeatType: "loop",
              duration: 20,
              ease: "linear",
            },
          }}
        >
          {[...clientLogos, ...clientLogos].map((data, index) => (
            <img
              src={data.img || "/placeholder.svg"}
              key={`${data.id}-${index}`}
              alt={`Client logo ${index + 1}`}
              className="duration-300 select-none ease-in cursor-pointer"
              style={{ width: `${logoWidth}px`, flexShrink: 0 }}
            />
          ))}
        </motion.div>
      </div>
    </section>
  );
}

export default HappyClient;
