import MadBenefits from "../Components/MAD/mad-benefits";
import MadContact from "../Components/MAD/mad-contact";
import MadCrossDevelopment from "../Components/MAD/mad-cross-platform";
import MadHeadSection from "../Components/MAD/mad-head-section";
import MadProcess from "../Components/MAD/mad-process";
import MadTechSection from "../Components/MAD/mad-tech-section";
import WhyChooseMad from "../Components/MAD/why-choose-mad";
import MainLayout from "../layout/main-layout";

function MobileAppDevelopment() {
  return (
    <div>
      <MainLayout>
        <MadHeadSection />
        <MadBenefits />
        <MadCrossDevelopment />
        <MadTechSection />
        <MadProcess />
        <WhyChooseMad />
        <MadContact />
      </MainLayout>
    </div>
  );
}

export default MobileAppDevelopment;
