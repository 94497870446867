import { FieldArray, Form, Formik } from "formik";
import Input from "../../input/Input";
import TextArea from "../../input/text-area";
import File from "../../input/File";
import IconButton from "../../input/icon-button";
import { Plus, Trash } from "lucide-react";
import addWorkValidationSchema from "../work-validations/add-work-validation-schema";
import Switch from "../../input/Switch";

function AddWorkForm({ onSubmit }: { onSubmit: (values: any) => void }) {
  return (
    <div className="w-full p-5">
      <Formik
        initialValues={{
          title: "",
          brief: "",
          domain: "",
          is_case_study: false,
          description: "",
          problem_statement: "",
          main_image: "",
          frontend_tech: "",
          backend_tech: "",
          database_tech: "",
          other_images: [],
          challenges: [""],
          solutions: [""],
          approach: [""],
          key_features: [
            {
              feature: "",
              description: "",
            },
          ],
        }}
        // validationSchema={addWorkValidationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, setFieldValue }) => (
          <Form className="space-y-6">
            <div className="flex flex-col gap-4">
              <h2 className="text-2xl font-medium">Basic details</h2>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <Input
                  name="title"
                  type="text"
                  placeholder="Title"
                  label={"Title"}
                />
                <Input
                  name="brief"
                  type="text"
                  placeholder="Brief"
                  label={"Brief"}
                />
                <Input
                  name="domain"
                  type="text"
                  placeholder="Domain"
                  label={"Domain"}
                />

                <div className="mb-4 w-full">
                  <label className="block text-sm text-gray-950  capitalize mb-3">
                    Is it case study?
                  </label>
                  <Switch
                    isOn={values.is_case_study}
                    handleToggle={() =>
                      setFieldValue("is_case_study", !values.is_case_study)
                    }
                  />
                </div>
              </div>
              <TextArea
                name="description"
                placeholder="Description"
                label={"Description"}
              />
              <TextArea
                name="problem_statement"
                placeholder="Problem Statement"
                label={"Problem Statement"}
              />
            </div>
            <div className="flex flex-col gap-4">
              <h2 className="text-2xl font-medium">Technical details</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <Input
                  name="frontend_tech"
                  type="text"
                  placeholder="Frontend Tech"
                  label={"Frontend Tech"}
                />
                <Input
                  name="backend_tech"
                  type="text"
                  placeholder="Backend Tech"
                  label={"Backend Tech"}
                />
                <Input
                  name="database_tech"
                  type="text"
                  placeholder="Database Tech"
                  label={"Database Tech"}
                />
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <h2 className="text-2xl font-medium">Images</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <File
                  name="main_image"
                  onChange={(event: any) => {
                    const file = event.currentTarget.files[0];
                    setFieldValue("main_image", file);
                  }}
                  accept="image/*"
                  label={"Main Image"}
                />
                <File
                  name="other_images"
                  multiple={true}
                  onChange={(event: any) => {
                    const files = Array.from(event.currentTarget.files);
                    setFieldValue("other_images", files);
                  }}
                  accept="image/*"
                  label={"Other Images"}
                />
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <h2 className="text-2xl font-medium">Project Details</h2>
              <FieldArray name="challenges">
                {({ push, remove }) => (
                  <div className="w-full border p-3 rounded-xl shadow-sm">
                    <div className="flex items-center justify-between">
                      <h2 className="text-xl font-semibold">Challenges</h2>
                      <IconButton
                        type="button"
                        icon={<Plus />}
                        rounded="rounded-xl"
                        onClick={() => push("")}
                      />
                    </div>
                    <div className="flex flex-col items-center justify-center mt-5 w-full">
                      {values.challenges.map((_, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-2 w-full"
                        >
                          <Input
                            name={`challenges.${index}`}
                            type="text"
                            className="w-full"
                            placeholder="Challenge"
                            label={"Challenge"}
                          />
                          <IconButton
                            icon={<Trash />}
                            bgColor="bg-red-600"
                            rounded="rounded-xl"
                            onClick={() => remove(index)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </FieldArray>
              <FieldArray name="solutions">
                {({ push, remove }) => (
                  <div className="w-full border p-3 rounded-xl shadow-sm">
                    <div className="flex items-center justify-between">
                      <h2 className="text-xl font-semibold">Solutions</h2>
                      <IconButton
                        type="button"
                        icon={<Plus />}
                        rounded="rounded-xl"
                        onClick={() => push("")}
                      />
                    </div>
                    <div className="flex flex-col items-center justify-center mt-5 w-full">
                      {values.solutions.map((_, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-2 w-full"
                        >
                          <Input
                            name={`solutions.${index}`}
                            type="text"
                            className="w-full"
                            placeholder="Solution"
                            label={"Solution"}
                          />
                          <IconButton
                            icon={<Trash />}
                            bgColor="bg-red-600"
                            rounded="rounded-xl"
                            onClick={() => remove(index)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </FieldArray>
              <FieldArray name="approach">
                {({ push, remove }) => (
                  <div className="w-full border p-3 rounded-xl shadow-sm">
                    <div className="flex items-center justify-between">
                      <h2 className="text-xl font-semibold">Approach</h2>
                      <IconButton
                        type="button"
                        icon={<Plus />}
                        rounded="rounded-xl"
                        onClick={() => push("")}
                      />
                    </div>
                    <div className="flex flex-col items-center justify-center mt-5 w-full">
                      {values.approach.map((_, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-2 w-full"
                        >
                          <Input
                            name={`approach.${index}`}
                            type="text"
                            className="w-full"
                            placeholder="approach"
                            label={"Approach"}
                          />
                          <IconButton
                            icon={<Trash />}
                            bgColor="bg-red-600"
                            rounded="rounded-xl"
                            onClick={() => remove(index)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </FieldArray>
              <FieldArray name="key_features">
                {({ push, remove }) => (
                  <div className="w-full border p-3 rounded-xl shadow-sm">
                    <div className="flex items-center justify-between">
                      <h2 className="text-xl font-semibold">Key Features</h2>
                      <IconButton
                        type="button"
                        icon={<Plus />}
                        rounded="rounded-xl"
                        onClick={() => push({ feature: "", description: "" })}
                      />
                    </div>
                    <div className="flex flex-col items-center justify-center mt-5 w-full">
                      {values.key_features.map((_, index) => (
                        <div
                          key={index}
                          className="flex flex-col md:flex-row items-center gap-2 w-full"
                        >
                          <div className="w-full md:w-1/3">
                            <Input
                              name={`key_features.${index}.feature`}
                              type="text"
                              className="w-full"
                              placeholder="feature"
                              label={"Feature"}
                            />
                          </div>
                          <div className="flex items-center gap-2 w-full">
                            <Input
                              name={`key_features.${index}.description`}
                              type="text"
                              className="w-full"
                              placeholder="Description"
                              label={"Description"}
                            />
                            <IconButton
                              icon={<Trash />}
                              bgColor="bg-red-600"
                              rounded="rounded-xl"
                              onClick={() => remove(index)}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </FieldArray>
              <div className="flex items-start justify-start">
                <IconButton icon={<Plus />} title="Add Work" type="submit" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddWorkForm;
