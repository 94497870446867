const engineeringCulture = [
  {
    title:
      "Our Engineering Culture: A Commitment to Problem Solving and Impact",
    description:
      "We are problem solvers, not just task executors. At the heart of our engineering culture is a commitment to trust, autonomy, and making a meaningful impact. We don’t rely on rigid processes or micromanagement. Instead, we empower teams to focus on solving real-world problems.",
  },
  {
    title: "Mission-Driven Teams",
    description:
      "Each team is mission-driven, provided with clear context, and backed by the necessary research to create valuable solutions.",
  },
  {
    title: "Continuous Delivery and Simplicity",
    description:
      "We embrace continuous delivery, ensuring that our work is always progressing and delivering value. We prioritize simplicity in everything we do—from our processes to the software we build.",
  },
  {
    title: "User-Centered Solutions",
    description:
      "Our goal is to create software that truly benefits our users—whether they are customers, employees, or other stakeholders. We're dedicated to delivering impactful, user-centered solutions that drive success.",
  },
];

export default engineeringCulture;
