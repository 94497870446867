import React from "react";
import Grid from "../Grid/grid";
import Title from "../title";
import ContactForm from "../contact-form/contact-form";

function ReactContactSection() {
  return (
    <section className="w-full min-h-screen h-full relative flex flex-col overflow-hidden p-2 lg:p-16">
      <Grid size={100} direction="top-center" />
      <div className="z-10 flex justify-start items-start mb-12 w-full">
        <Title title="Get in touch" />
      </div>
      <div className="flex md:flex-row flex-col justify-center">
        <div className="flex flex-col items-start justify-center gap-5 md:w-1/2">
          <h2 className="md:text-4xl text-xl font-medium max-w-4xl">
            Ready to Build a Fast, Scalable Web Application with React.js?
          </h2>
          <p className="text-gray-500">
            Get in touch with us today to discuss how we can help you develop a
            React.js solution that enhances your users' experience and drives
            business success.
          </p>
        </div>
        <div className="mt-5 flex items-center justify-center md:w-1/2">
          <ContactForm title="Hire React Developer" purpose="React Development" />
        </div>
      </div>
    </section>
  );
}

export default ReactContactSection;
