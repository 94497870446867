import { Form, Formik } from "formik";
import { Plus, X } from "lucide-react";
import Input from "../../input/Input";
import TextArea from "../../input/text-area";
import IconButton from "../../input/icon-button";
import testimonialValidationSchema from "../validation-schema/testimonial-validation-schema";

function AddTestimonialForm({
  onClose,
  onSubmit,
}: {
  onClose: () => void;
  onSubmit: (values: any) => void;
}) {
  return (
    <div className="inset-0 bg-black bg-opacity-50 h-screen fixed flex items-center justify-center overflow-y-auto z-50">
      <div className="flex items-center justify-center my-auto w-full overflow-y-auto py-5 px-5">
        <div className="bg-white rounded-3xl shadow-md w-full max-w-2xl my-auto mx-auto md:h-auto h-full overflow-y-auto">
          <div className="flex flex-col items-center justify-center w-full">
            <div className="flex items-center justify-between w-full border-b p-4">
              <h2 className="text-xl font-normal text-gray-800">
                Add Testimonial
              </h2>
              <button
                onClick={onClose}
                className="outline-none bg-red-100 border border-red-400 text-red-400 p-2 rounded-full duration-300 ease-in transition-all hover:bg-red-400 hover:text-white"
              >
                <X />
              </button>
            </div>
            <div className="p-4 w-full">
              <Formik
                initialValues={{
                  person_name: "",
                  message: "",
                  company_name: "",
                  position: "",
                }}
                validationSchema={testimonialValidationSchema}
                onSubmit={onSubmit}
              >
                {({ values }) => (
                  <Form className="gap-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4 w-full">
                      <Input
                        name="person_name"
                        type="text"
                        placeholder="John Doe"
                        label={"Name"}
                      />
                      <Input
                        name="position"
                        type="text"
                        placeholder="CEO"
                        label={"Position"}
                      />
                    </div>
                    <Input
                      name="company_name"
                      type="text"
                      placeholder="SCS"
                      label={"Company Name"}
                    />
                    <TextArea
                      label={"Message"}
                      name={"message"}
                      placeholder={"Write a testimonial about our company"}
                    />
                    <div className="flex items-start justify-start">
                      <IconButton icon={<Plus />} type="submit" title="Add" />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddTestimonialForm;
