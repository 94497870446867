import { Database, Layout, Server } from "lucide-react";
import Grid from "../Grid/grid";
import Title from "../title";

function CaseBrief({ work }: { work: any }) {
  return (
    <div className="relative flex flex-col h-auto px-4 sm:px-6 md:px-10 lg:px-16 py-10">
      <Grid direction="top-center" size={100} />
      <div className="z-10 flex justify-start items-start mb-12 w-full">
        <Title title="Technologies we used" />
      </div>
      <div className="flex flex-col items-center justify-center w-full gap-12">
        <div className="flex items-center justify-center md:w-1/2 flex-col">
          <h2 className="text-3xl font-semibold text-gray-800">Technologies</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-10 text-secondary">
            <div className="border-2 bg-white rounded-full py-2 px-3 border-secondary flex flex-row items-center justify-center gap-3 shadow-md transition-transform hover:scale-105">
              <Layout size={28} />
              <h2 className="font-medium">{work?.frontend_tech}</h2>
            </div>
            <div className="border-2 bg-white rounded-full py-2 px-3 border-secondary flex flex-row items-center justify-center gap-3 shadow-md transition-transform hover:scale-105">
              <Server size={28} />
              <h2 className="font-medium">{work?.backend_tech}</h2>
            </div>
            <div className="border-2 bg-white rounded-full py-2 px-3 border-secondary flex flex-row items-center justify-center gap-3 shadow-md transition-transform hover:scale-105">
              <Database size={28} />
              <h2 className="font-medium">{work?.database_tech}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseBrief;
