import React, { useEffect, useState } from "react";
import AdminLayout from "../../../layout/admin-layout";
import Header from "../../../Components/job-opening/Head";
import axios from "axios";
import Loader from "../../../Components/loader/loader";
import JobTable from "../../../Components/job-opening/table/job-table";
import JobDetail from "../../../Components/job-opening/job-detail/job-detail";
import DeleteModal from "../../../Components/delete-modal/delete-modal";
import { toast } from "sonner";
import { useUser } from "../../../Components/provider/user-id-provider";

function JobOpening() {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState<"list" | "detailed">("list");
  const [selectedJob, setSelectedJob] = useState<any>({});
  const [jobApplicants, setJobApplicants] = useState([]);
  const [Delete, setDelete] = useState(false);
   const {userId} = useUser();
  const fetchData = () => {
    axios
      .get(`job_openings`)
      .then((res) => {
        setJobs(res.data.job_openings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchJobApplicants = () => {
    axios
      .get(`job_applications`)
      .then((res) => {

        setJobApplicants(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleView = (job: any) => {
    setView("detailed");
    setSelectedJob(job);
  };

  const handleDeleteView = (job: any) => {
    setDelete(true);
    setSelectedJob(job);
  };
  useEffect(() => {
    fetchData();
    fetchJobApplicants();
  }, []);

  const onDelete = () => {
    const loading = toast.loading("Deleting ...");

    axios
      .delete(`users/${userId}/job_openings/${selectedJob.id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loading);
          toast.success("Job Opening Deleted Successfully");
          fetchData();
          setDelete(false);
          setSelectedJob({});
        }
      })
      .catch((err) => {
        toast.dismiss(loading);
        toast.error(err.response.data.message || err.message);
      });
  };

  return (
    <AdminLayout>
      <Header title="Job Openings" />
      {loading ? (
        <Loader />
      ) : (
        <div className="w-full flex flex-row h-full">
          <div
            className={`${
              view === "detailed" ? "w-1/4 hidden md:flex" : "w-full"
            } px-3`}
          >
            <JobTable
              data={jobs}
              view={view}
              onDeleteView={handleDeleteView}
              onView={handleView}
            />
          </div>
          {view === "detailed" && (
            <div className="w-full md:w-3/4 m-0 md:m-3 ">
              <JobDetail
                job={selectedJob}
                setSelectedJob={setSelectedJob}
                onDeleteView={handleDeleteView}
                setView={setView}
                applicants={jobApplicants}
              />
            </div>
          )}
        </div>
      )}
      {Delete && (
        <DeleteModal
          customMessage={`Do You want to remove job opening ${selectedJob?.job_position} ?`}
          onCancel={() => {
            setSelectedJob({});
            setDelete(false);
          }}
          onDelete={onDelete}
        />
      )}
    </AdminLayout>
  );
}

export default JobOpening;
