import MainLayout from "../layout/main-layout";
import WebDevelopmentHeadSection from "../Components/web-development/web-development-head-section";
import WebDevelopmentBenefits from "../Components/web-development/web-development-benefits";
import WebDevelopmentProcess from "../Components/web-development/web-development-process";
import WhyChooseWebDevelopment from "../Components/web-development/why-choose-web-development";
import WebDevelopmentContact from "../Components/web-development/web-development-contact";

function WebDevelopment() {
  return (
    <div>
      <MainLayout>
        <WebDevelopmentHeadSection />
        <WebDevelopmentBenefits />
        <WebDevelopmentProcess />
        <WhyChooseWebDevelopment />
        <WebDevelopmentContact />
      </MainLayout>
    </div>
  );
}

export default WebDevelopment;
