const CmsBenefitsData = [
  {
    title: "Tailored to Your Needs",
    description:
      "We don’t believe in one-size-fits-all solutions. Our team works closely with you to understand your specific requirements, ensuring the CMS is designed to align with your goals, industry, and workflows.",
  },
  {
    title: "Scalable & Flexible Solutions",
    description:
      "As your business grows, so do your needs. Our custom-built CMS systems are scalable, allowing you to easily add new features and manage increased content without compromising performance or user experience.",
  },
  {
    title: "User-Friendly Interface",
    description:
      "We design intuitive interfaces that are easy for your team to use, regardless of their technical expertise. You’ll have full control over your content, with easy-to-navigate dashboards and tools.",
  },
  {
    title: "Seamless Integrations",
    description:
      "Your CMS will work seamlessly with your existing tools and platforms. From e-commerce solutions to marketing automation, we ensure smooth integration with your tech stack.",
  },
  {
    title: "Enhanced Security",
    description:
      "Security is a top priority for us. Our custom CMS solutions are built with strong security measures to protect your content and sensitive business data from threats.",
  },
  {
    title: "Ongoing Support & Maintenance",
    description:
      "We don’t just build your CMS and walk away. We offer ongoing support to ensure everything runs smoothly and help with updates, enhancements, and troubleshooting as your needs evolve.",
  },
];

export default CmsBenefitsData;
