import { Database, Layout, Server } from "lucide-react";
import Grid from "../Grid/grid";
import Title from "../title";
import react from "../icons/react-svgrepo-com (1).svg";
import ruby from "../icons/rails-svgrepo-com.svg";
import postgres from "../icons/postgresql-svgrepo-com.svg";

function ProductBrief({ product }: { product: any }) {
  return (
    <div className="relative flex flex-col min-h-screen px-4 sm:px-6 md:px-10 lg:px-16 py-10">
      <Grid direction="top-center" size={100} />
      <div className="z-10 flex justify-start items-start mb-8 sm:mb-10 md:mb-12 w-full">
        <Title title="Tech Stack & Color Palette" />
      </div>
      <div className="flex items-center justify-center w-full flex-col gap-8 sm:gap-10 md:gap-12">
        <div className="flex items-center justify-center w-full flex-col">
          <h2 className="text-2xl sm:text-3xl font-semibold text-gray-800">
            Technologies
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-6 sm:mt-8 md:mt-10">
            <div
              className="border-2 bg-white rounded-full py-2 px-3 flex flex-row items-center justify-center gap-3 shadow-md transition-transform hover:scale-105"
              style={{
                color: product?.primary_color,
                borderColor: product?.primary_color,
              }}
            >
              <img src={react} className="w-6 sm:w-8" />
              <h2 className="text-lg sm:text-xl font-medium">
                {product?.frontend}
              </h2>
            </div>
            <div
              className="border-2 bg-white rounded-full py-2 px-3 flex flex-row items-center justify-center gap-3 shadow-md transition-transform hover:scale-105"
              style={{
                color: product?.primary_color,
                borderColor: product?.primary_color,
              }}
            >
              <img src={ruby} className="w-6 sm:w-8" />
              <h2 className="text-lg sm:text-xl font-medium">
                {product?.backend}
              </h2>
            </div>
            <div
              className="border-2 bg-white rounded-full py-2 px-3 flex flex-row items-center justify-center gap-3 shadow-md transition-transform hover:scale-105"
              style={{
                color: product?.primary_color,
                borderColor: product?.primary_color,
              }}
            >
              <img src={postgres} className="w-6 sm:w-8" />
              <h2 className="text-lg sm:text-xl font-medium">
                {product?.database}
              </h2>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col items-center justify-center gap-6 sm:gap-8">
          <h2 className="text-2xl sm:text-3xl font-semibold text-gray-800">
            Color Palette
          </h2>
          <div className="grid grid-cols-2 gap-6 sm:gap-8">
            <div
              className="p-4 w-24 sm:w-32 md:w-40 lg:w-52 h-24 sm:h-32 md:h-40 lg:h-52 rounded-2xl flex items-end justify-end shadow-md transition-transform hover:scale-105"
              style={{
                backgroundColor: product?.primary_color,
                color: product?.secondary_color,
              }}
            >
              <h2 className="text-sm sm:text-lg font-medium">
                {product?.primary_color}
              </h2>
            </div>
            <div
              className="p-4 w-24 sm:w-32 md:w-40 lg:w-52 h-24 sm:h-32 md:h-40 lg:h-52 rounded-2xl flex items-end justify-end shadow-md transition-transform hover:scale-105"
              style={{
                backgroundColor: product?.secondary_color,
                color: product?.primary_color,
              }}
            >
              <h2 className="text-sm sm:text-lg font-medium">
                {product?.secondary_color}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductBrief;
