import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import MainLayout from "../layout/main-layout";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Grid from "../Components/Grid/grid";
import Loader from "../Components/loader/loader";
import { ArrowRight, ChevronLeft } from "lucide-react";
import IconButton from "../Components/input/icon-button";
import CTASection from "../Components/common-section/cta-section";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-device-frameset/styles/marvel-devices.min.css";
import OtherCases from "../Components/case-study/other-cases";

function OurWorkDetail() {
  const { id, title } = useParams();
  const [work, setWork] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [cases, setCases] = useState([]);
  const navigate = useNavigate();

  const fetchCases = () => {
    axios
      .get(`case_studies`)
      .then((res) => {
        if (res.status === 200) {
          const cases = res.data;
          setCases(cases);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchData = () => {
    axios
      .get(`case_studies/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setWork(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleNavigate = (data: any) => {
    navigate(`/our-work/${data.id}/${data.title}`);
  };

  useEffect(() => {
    fetchData();
    fetchCases();
  }, [id]);
  const images = [work?.main_image_url, ...(work?.other_images_urls || [])];

  return (
    <MainLayout>
      {loading ? (
        <section className="w-full min-h-screen h-full relative flex flex-col items-start justify-center lg:p-16 p-8 mt-16">
          <Loader />
        </section>
      ) : (
        <>
          <section className="relative flex items-center justify-center flex-col md:flex-row gap-12 min-h-screen px-4 sm:px-6 md:px-10 lg:px-16 py-24">
            <Grid size={100} direction="full" />
            <div className="flex flex-col items-start gap-6 w-full md:w-1/2">
              <button
                onClick={() => navigate(-1)}
                className="outline-none border-2 border-black bg-white flex gap-3 p-2 font-bold hover:bg-black hover:text-white ease-in duration-300 rounded-full"
              >
                <ChevronLeft /> Back
              </button>
              <div className="w-full max-w-6xl flex items-center justify-center relative">
                <Swiper
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  modules={[Pagination, Navigation, Autoplay]}
                  className="relative w-full max-w-6xl"
                >
                  {images?.map((data: any, index: number) => (
                    <SwiperSlide
                      key={index}
                      className=" flex items-center justify-center bg-white"
                    >
                      <img
                        className="w-full max-w-6xl h-[500px] md:h-[400px] object-contain rounded-xl border shadow-md"
                        src={`${data}`}
                        alt="banner"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start md:w-1/2 gap-6">
              <motion.h2
                className="text-4xl md:text-5xl font-semibold text-gray-900 leading-tight"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.6, ease: "easeOut" }}
                animate={{ y: [0, -5, 0] }}
              >
                {title}
              </motion.h2>
              <h4 className="border-2 bg-white border-primary p-2 text-xl font-semibold text-primary rounded-full">
                {work?.domain}
              </h4>
              <p className="text-gray-800 leading-relaxed">
                {work?.description}
              </p>
              {work?.is_case_studies && (
                <IconButton
                  onClick={() =>
                    navigate(`/case-study/${work.id}/${work.title}`)
                  }
                  icon={<ArrowRight />}
                  title="Read more"
                />
              )}
            </div>
          </section>
        </>
      )}
      <OtherCases
        cases={cases}
        heading="Other Works"
        handleNavigate={handleNavigate}
        buttonTitle="View"
      />
      <CTASection />
    </MainLayout>
  );
}

export default OurWorkDetail;
