
import Grid from "../Grid/grid";
import Title from "../title";

function WhyUs() {
  const serviceFeatures = [
    {
      title: "Tailored Approach",
      description:
        "We understand that every project is unique. Our team works closely with you to determine which model best aligns with your goals, ensuring maximum value and efficiency.",
    },
    {
      title: "Expertise Across Technologies",
      description:
        "We offer skilled professionals with expertise in a wide range of technologies, including web development, mobile app development, AI, cloud services, and more.",
    },
    {
      title: "Seamless Integration",
      description:
        "Whether it’s a fixed-cost project, flexible time-based work, or augmenting your team, we ensure smooth collaboration and integration with your existing team and workflows.",
    },
    {
      title: "Transparent Communication",
      description:
        "We maintain open and transparent communication throughout the project, keeping you informed on progress, timelines, and budgets.",
    },
  ];
  return (
    <section className="w-full h-auto overflow-hidden relative flex flex-col lg:p-16 p-8">
      <Grid direction="top-center" size={100} />
      <div className="z-10 flex flex-col items-start mb-12">
        <Title title="Why Work With Softcoding Solutions?" />
      </div>
      <div className="flex flex-col items-start justify-center gap-10 mb-12">
        {serviceFeatures.map((data, index) => (
          <div
            key={index}
            className="flex flex-col gap-3 items-start justify-start"
          >
            <h2 className="text-3xl sm:text-4xl font-semibold text-gray-900 leading-tight">
              {data.title}
            </h2>
            <p className="text-gray-600 text-lg md:text-xl md:leading-relaxed">
              {data.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default WhyUs;
