import { Form, Formik } from "formik";
import Input from "../../input/Input";
import File from "../../input/File";
import Editor from "../../Editor/editor";
import { useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import IconButton from "../../input/icon-button";
import { Plus } from "lucide-react";
import axios from "axios";
import { blogValidationSchema } from "../validation-schema/validation-schema";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useUser } from "../../provider/user-id-provider";

function AddBlogForm() {
  const editorRef = useRef<EditorJS | null>(null);
  const navigate = useNavigate();
   const {userId} = useUser();

  const handleAddBlog = (values: any) => {
    const loader = toast.loading("Adding blog...");

    const formData = new FormData();
    formData.append("blog[title]", values.title);
    formData.append("blog[brief]", values.brief);
    formData.append("blog[poster_img]", values.poster_img);
    formData.append("blog[date]", values.date);
    formData.append("blog[written_by]", values.written_by);
    formData.append("blog[content]", values.content);

    axios
      .post(`users/${userId}/blogs`, formData)
      .then((res) => {
        if (res.status === 201) {
          toast.dismiss(loader);
          toast.success("Blog added successfully");
          navigate(-1);
        }
      })
      .catch((err) => {
        toast.dismiss(loader);
        toast.error(err.response.data.message || err.message);
        console.log(err);
      });
  };
  return (
    <div className="p-5">
      <Formik
        initialValues={{
          title: "",
          brief: "",
          poster_img: "",
          date: new Date(),
          written_by: "",
          content: "{}",
        }}
        validationSchema={blogValidationSchema}
        onSubmit={handleAddBlog}
      >
        {({ values, setFieldValue }) => (
          <Form className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-4">
              <Input
                name="title"
                type="text"
                placeholder="Enter title"
                label="Title"
              />
              <Input
                name="written_by"
                type="text"
                placeholder="Written by"
                label="Written By"
              />
              <div className="md:col-span-2">
                <Input
                  name="brief"
                  type="text"
                  placeholder="Enter Brief"
                  label="Brief"
                />
              </div>
            </div>
            <File
              name="poster_img"
              accept="image/*"
              label="Poster Image"
              onChange={(event: any) => {
                const file = event.currentTarget.files[0];
                setFieldValue("poster_img", file);
              }}
            />
            <div className="space-y-2">
              <label className="block text-sm text-gray-950 mb-1 capitalize">
                Content
              </label>
              <Editor
                editorRef={editorRef}
                data={
                  values.content && values.content !== "{}"
                    ? { blocks: [JSON.parse(values.content)] }
                    : { blocks: [] }
                }
                onChange={(updatedData) => {
                  setFieldValue("content", updatedData);
                }}
              />
            </div>
            <div className="flex items-start justify-start">
              <IconButton icon={<Plus />} title="Add Blog" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddBlogForm;
