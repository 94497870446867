import React from "react";
import Grid from "../Grid/grid";
import { motion } from "framer-motion";
import Title from "../title";
import engineeringCulture from "./data/engineer-culture";

function EngineerCulture() {
  return (
    <section className="relative  w-full flex flex-col overflow-hidden lg:p-16 p-8">
      <Grid size={100} direction="top-center" />
      <div className="z-10 flex justify-start items-start mb-12">
        <Title title="Our Engineering Culture" />
      </div>
      <div className="flex flex-col items-start justify-center gap-10 mb-12">
        {engineeringCulture.map((data, index) => (
          <motion.div
            initial={{ opacity: 0, x: -30, scale: 0.95 }}
            whileInView={{
              opacity: 1,
              x: 0,
              scale: 1,
            }}
            transition={{
              duration: 0.4,
              delay: 0.1 * index,
              type: "spring",
              stiffness: 80,
            }}
            key={index}
            className="flex flex-col gap-3 items-start justify-start"
          >
            <h2 className="text-3xl sm:text-4xl font-semibold text-gray-900 leading-tight">
              {data.title}
            </h2>
            <p className="text-gray-600 text-lg md:text-xl md:leading-relaxed">
              {data.description}
            </p>
          </motion.div>
        ))}
      </div>
    </section>
  );
}

export default EngineerCulture;
