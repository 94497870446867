import DomainSection from "../Components/home/domain-section";
import HeroSection from "../Components/home/hero-section";
import OurProducts from "../Components/common-section/our-product";
import TechStack from "../Components/common-section/tech-stack";
import Testimonial from "../Components/common-section/testimonial";
import WhatWeDo from "../Components/home/what-we-do";
import HappyClient from "../Components/common-section/happy-client";
import MainLayout from "../layout/main-layout";
import AccrossGlobe from "../Components/AboutUs/across-globe";
import CTASection from "../Components/common-section/cta-section";

function Home() {
  return (
    <div className="overflow-x-hidden">
      <MainLayout>
        <HeroSection />
        <WhatWeDo />
        <OurProducts />
        <TechStack />
        <DomainSection />
        <HappyClient />
        <Testimonial />
        <CTASection />
        <AccrossGlobe />
      </MainLayout>
    </div>
  );
}

export default Home;
