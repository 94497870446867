import { ErrorMessage, Field } from "formik";

export interface InputInterface {
  name: string;
  placeholder?: string;
  length?: number;
  label?: String;
  min?: number;
}

function TextArea({ name, placeholder, label, length, min }: InputInterface) {
  return (
    <div className="mb-4">
      <label
        htmlFor={name}
        className="block text-sm text-gray-950 mb-1 capitalize"
      >
        {label}
      </label>
      <Field
        name={name}
        as="textarea"
        placeholder={placeholder}
        maxLength={length}
        min={min}
        className="block w-full px-3 py-2 mt-2 text-gray-700 placeholder-gray-400 placeholder:capitalize bg-white border border-gray-200 rounded-md  focus:border-primary  focus:ring-primary focus:outline-none focus:ring focus:ring-opacity-40"
      />
      <ErrorMessage
        component={"div"}
        name={name}
        className="text-sm text-red-600 mt-1"
      />
    </div>
  );
}

export default TextArea;
