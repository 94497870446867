const csdBenefitsData = [
  {
    id: 1,
    title: "Tailor-Made Solutions for Your Business",
    description:
      "Your business is unique—your software should be too. We build fully customized solutions that align with your specific needs, ensuring seamless integration with your operations and giving you a competitive edge in your industry.",
  },
  {
    id: 2,
    title: "Scalable & Future-Proof Development",
    description:
      "Stay ahead of the curve with software that grows with your business. Whether you're expanding, adding new features, or adopting the latest technology, our scalable solutions ensure long-term success without costly overhauls.",
  },
  {
    id: 3,
    title: "Boost Efficiency & Automate Workflows",
    description:
      "Eliminate time-consuming manual tasks and streamline operations with intelligent automation. Our custom software enhances productivity, reduces errors, and allows your team to focus on what truly matters—growing your business.",
  },
  {
    id: 4,
    title: "Seamless Integration with Your Existing Tools",
    description:
      "No more juggling between multiple platforms. We ensure your custom software seamlessly integrates with CRM, ERP, cloud services, payment gateways, and other third-party tools, creating a unified and efficient digital ecosystem.",
  },
  {
    id: 5,
    title: "Enterprise-Grade Security & Compliance",
    description:
      "Protect your sensitive data with cutting-edge security measures built into every solution. We implement robust encryption, authentication protocols, and compliance standards to keep your business safe from cyber threats and breaches.",
  },
  {
    id: 6,
    title: "Cost-Effective & High ROI",
    description:
      "Save money in the long run with software designed specifically for you. No recurring licensing fees, no unnecessary features—just a powerful, efficient system that maximizes performance and minimizes operational costs.",
  },
  {
    id: 7,
    title: "Complete Ownership & Dedicated Support",
    description:
      "With custom software, you own the technology, giving you full control over updates, improvements, and features. Plus, our team is always here to provide continuous support, ensuring your software stays up-to-date and runs flawlessly.",
  },
];
export default csdBenefitsData;
