import AdminLayout from "../../../layout/admin-layout";
import Header from "../../../Components/our-work-admin/our-work-admin-header";
import WorkTable from "../../../Components/our-work-admin/work-table/work-table";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../../Components/loader/loader";
import DetailView from "../../../Components/our-work-admin/work-table/detail-view";
import DeleteModal from "../../../Components/delete-modal/delete-modal";
import { toast } from "sonner";
import { useUser } from "../../../Components/provider/user-id-provider";

function OurWork() {
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState<"list" | "detailed">("list");
  const [selectedWork, setSelectedWork] = useState<any>({});
  const [Delete, setDelete] = useState(false);
  const [works, setWorks] = useState([]);
  const {userId} = useUser();

  const fetchData = () => {
    axios
      .get(`case_studies`)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setWorks(res.data);
          
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    };

  useEffect(() => {
    fetchData();
  }, []);

  const handleViewDetails = (data: any) => {
    setSelectedWork(data);
    setView("detailed");
  };
  const handleDeleteView = (data: any) => {
    setDelete(true);
    setSelectedWork(data);
  };

  const handelDeleteData = () => {
    const loader = toast.loading("Deleting data...");
    axios
      .delete(`users/${userId}/case_studies/${selectedWork.id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.dismiss(loader);
          toast.success("Work deleted successfully");
          setDelete(false);
          setView("list");
          setSelectedWork({});
          fetchData();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.dismiss(loader);
        toast.error(err.response.data.message || err.message);
      });
  };

useEffect(() => {
    if (Delete) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [Delete]);
  return (
    <AdminLayout>
      <Header title="our work" />
      {loading ? (
        <Loader />
      ) : (
        <div className="w-full flex flex-row h-full">
          <div
            className={`${
              view === "detailed" ? "w-1/4 hidden md:flex" : "w-full"
            } px-3`}
          >
            <WorkTable
              data={works}
              view={view}
              onView={handleViewDetails}
              selectedWork={selectedWork}
              onDeleteView={handleDeleteView}
            />
          </div>
          {view === "detailed" && (
            <div className="w-full md:w-3/4 m-0 md:m-3 ">
              <DetailView
                work={selectedWork}
                setView={setView}
                setSelectedWork={setSelectedWork}
                onDeleteView={handleDeleteView}
              />
            </div>
          )}
        </div>
      )}
      {Delete && (
        <DeleteModal
          customMessage={`Do you want to remove ${selectedWork?.title} ?`}
          onCancel={() => {
            setDelete(false);
            setSelectedWork({});
            setView("list");
          }}
          onDelete={handelDeleteData}
        />
      )}
    </AdminLayout>
  );
}

export default OurWork;
