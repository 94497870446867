import React, { useEffect, useState } from "react";
import MainLayout from "../layout/main-layout";
import HeadSection from "../Components/case-study/head-section";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../Components/loader/loader";
import ImgSection from "../Components/case-study/img-section";
import KeyFeatures from "../Components/case-study/key-features";
import CaseBrief from "../Components/case-study/case-brief";
import CTASection from "../Components/common-section/cta-section";
import OtherCases from "../Components/case-study/other-cases";

function CaseStudy() {
  const { id, title } = useParams();
  const [work, setWork] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [cases, setCases] = useState([]);
  const navigate = useNavigate();

  const fetchCases = () => {
    axios
      .get(`case_studies`)
      .then((res) => {
        if (res.status === 200) {
          const cases = res.data.filter(
            (data: any) => data.is_case_studies === true
          );
          setCases(cases);
        }
      })
      .catch((err) => {
        console.log(err);
   
      });
  };

  const fetchData = () => {
    axios
      .get(`case_studies/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setWork(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleNavigate = (data: any) => {
    navigate(`/case-study/${data.id}/${data.title}`);
  };

  useEffect(() => {
    fetchData();
    fetchCases();
  }, [id]);
  return (
    <MainLayout>
      {loading ? (
        <section className="w-full min-h-screen h-full relative flex flex-col items-start justify-center lg:p-16 p-8 mt-16">
          <Loader />
        </section>
      ) : (
        <>
          <HeadSection work={work} />
          <ImgSection work={work} />
          <KeyFeatures work={work} />
          <CaseBrief work={work} />
          <OtherCases
            cases={cases}
            heading={"Other Case Studies"}
            handleNavigate={handleNavigate}
            buttonTitle="Read case study"
          />
          <CTASection />
        </>
      )}
    </MainLayout>
  );
}

export default CaseStudy;
