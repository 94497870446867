import { motion } from "framer-motion";
import MainLayout from "../layout/main-layout";
import Grid from "../Components/Grid/grid";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../Components/loader/loader";

function UserBlog() {
  const [blogs, setBlogs] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    axios
      .get(`/blogs`)
      .then((res) => {
        setBlogs(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <MainLayout>
      <section className="min-h-screen h-full w-full p-8 md:p-16 overflow-hidden relative flex flex-col items-center justify-center">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Grid direction="top-center" size={100} />
            <div className="absolute -top-52 -left-52 rounded-full size-[600px] bg-gradient-to-tl from-white/80 to-secondary/60 blur-xl -z-10" />
            <div className="absolute -bottom-52 -right-52 rounded-full size-[600px] bg-gradient-to-tr from-white/80 to-primary/60 blur-xl -z-10" />
            <motion.div
              className="flex flex-col flex-wrap items-center justify-center gap-2 p-4 text-center"
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              <motion.h2
                className="text-6xl md:text-9xl font-semibold"
                initial={{ opacity: 0, y: -50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                Blogs
              </motion.h2>
              <motion.p
                className="mt-4 text-lg text-gray-600 max-w-4xl"
                initial={{ opacity: 0, y: -50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8 }}
              >
                Discover expert insights, industry trends, and practical tips to
                stay ahead in your field. Explore engaging content designed to
                inform, inspire, and empower your journey.
              </motion.p>
            </motion.div>
          </>
        )}
      </section>
    </MainLayout>
  );
}

export default UserBlog;
