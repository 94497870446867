import React, { useEffect, useState } from "react";
import MainLayout from "../layout/main-layout";
import HeadSection from "../Components/job-opening-user/head-section";
import axios from "axios";
import JobOpeningList from "../Components/job-opening-user/job-opening-list";
import Loader from "../Components/loader/loader";
import CTASection from "../Components/common-section/cta-section";

function JobOpeningUser() {
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);

  const fetchData = () => {
    axios
      .get(`job_openings`)
      .then((res) => {
        setJobs(res.data.job_openings);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <MainLayout>
      <HeadSection />
      {loading ? (
        <Loader />
      ) : jobs.length > 0 ? (
        <JobOpeningList data={jobs} />
      ) : (
        <section className="relative flex items-center justify-center flex-col min-h-screen px-4 sm:px-6 md:px-10 lg:px-16 py-10">
          <div className="p-3 border-2 border-black shadow-[6px_6px_0_#000] flex items-center justify-center ">
            <h2 className="text-xl sm:text-2xl font-medium text-gray-800 text-center max-w-3xl">
              We currently have no job openings. Check back soon for new
              opportunities!
            </h2>
          </div>
        </section>
      )}
      <CTASection />
    </MainLayout>
  );
}

export default JobOpeningUser;
