import { Users, Shield, Scale, Clock } from "lucide-react";

const services = [
  {
    title: "Commitment",
    icon: Users,
    description:
      "Our rigorous screening process ensures you get only the best candidates.",
  },
  {
    title: "Quality Assurance",
    icon: Shield,
    description:
      "We offer resources for both fulltime and freelance projects, allowing you to scale your team as needed.",
  },
  {
    title: "Flexibility",
    icon: Scale,
    description: "We provide ongoing support for smooth project execution.",
  },
  {
    title: "Ongoing Support",
    icon: Clock,
    description: "We provide ongoing support for smooth project execution.",
  },
];

export { services };
