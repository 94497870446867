import Title from "../title";
import Grid from "../Grid/grid";
import madTechData from "./data/mad-tech";

function MadTechSection() {
  return (
    <section className="w-full min-h-screen h-full relative flex flex-col overflow-hidden p-2 lg:p-16">
      <Grid size={100} direction="top-right" />
      <div className="z-10 flex justify-start items-start mb-12 w-full">
        <Title title="Technologies We Use" />
      </div>
      <div className="flex items-center justify-center">
        <div className="grid md:grid-cols-2 gap-8">
          {madTechData.map((data, index) => (
            <div
            key={index}
            className="bg-white border p-5 rounded-2xl shadow-lg flex w-full items-center justify-center"
          >
            <div className="flex flex-col items-start justify-start w-full gap-3">
              <h2 className="text-3xl font-medium text-primary">{data.name}</h2>
              <p className="text-sm text-gray-500">{data.description}</p>
              <div className="my-4 flex flex-col gap-10">
                {data.benefits.map((benefit, idx) => (
                  <div key={idx} className="flex flex-row gap-3 items-center">
                    <span className="text-secondary">{benefit.icon && <>{benefit.icon}</>}</span>
                    <div className="flex flex-col items-start justify-center">
                      <h3 className="text-lg font-medium text-primary">{benefit.title}</h3>
                      <p className="text-gray-500 text-sm">{benefit.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          
          ))}
        </div>
      </div>
    </section>
  );
}

export default MadTechSection;
