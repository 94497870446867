import Grid from "../Grid/grid";
import PartnerCard from "./card/partner-card";
import { services } from "./data/services-data";
import Title from "../title";

function PartnerWithUs() {
  return (
    <section className="relative min-h-screen w-full flex flex-col overflow-hidden lg:p-16 p-8">
      <Grid size={100} direction="top-center" />
      <div className="z-10 flex justify-start items-start mb-12">
        <Title title="partner with us" />
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="z-10 flex flex-col items-center justify-center mb-16">
          <h2 className="text-4xl font-semibold mb-3">Why partner with us ?</h2>
          <p className="text-xl text-gray-600 max-w-3xl text-center">
            Unmatched Expertise, Innovation, and Commitment to Your Success. Our
            developers are dedicated to delivering high-quality work on time.
          </p>
        </div>
        <div className="z-10 grid grid-cols-1 md:grid-cols-2 gap-8">
          {services.map((data, index) => (
            <PartnerCard
              key={index}
              description={data.description}
              title={data.title}
              icon={data.icon}
              number={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default PartnerWithUs;
